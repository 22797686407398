import React, { Component } from 'react';

import blockClasses from '../../Block/Block.module.scss';
import inputClasses from '../Inputs.module.scss';

import Select from 'react-select';

class InputCheckboxSelect extends Component {
  state = {
    isChecked: false
  }

  componentDidMount() {
    //console.log(this.props.fields)
  }

  checkboxChanged = (e) => {
    console.log('checkbox event handler')
    const targetChecked = e.target.checked;
    this.props.checkboxChanged(e.target);

    if ( true === targetChecked ) {
      this.setState({ isChecked: true })
    } else {
      this.setState({ isChecked: false })
    }
  }

  selecValueHandler = (selectedOption) => {
    this.props.selectChanged(selectedOption, this.props.fields[1].api_id, this.props.blockId);
    this.setState({ isChecked: true })
    this.props.checkboxChanged(this.inputElement);
  }

  render() {
    let firstFieldKey = 0,
        secondFieldKey = 1;

    //const checkboxInputId = this.props.blockId + '_' + this.props.fields[firstFieldKey].api_id;
    //const selectInputId = this.props.blockId + '_' + this.props.fields[secondFieldKey].api_id;

    const checkboxInputId = this.props.fields[firstFieldKey].id;
    const selectInputId = this.props.fields[secondFieldKey].id;

    let fieldClasses = [
      inputClasses.group_item,
      'group-item'
    ];

    let checkboxLabelBefore,
        checkboxLabelAfter,
        checkboxLabel = <label htmlFor={checkboxInputId}>{this.props.fields[firstFieldKey].label}</label>

    if ( !this.props.fields[firstFieldKey].label ) {
      checkboxLabel = <label className="empty-label"></label>
    } else {
      fieldClasses.push('has-label');
    }

    if ( this.props.fields[firstFieldKey].labelBefore )
      checkboxLabelBefore = this.props.fields[firstFieldKey].labelBefore;
    
    if ( this.props.fields[firstFieldKey].labelAfter )
      checkboxLabelAfter = this.props.fields[firstFieldKey].labelAfter;

    let checkbox_classes = [
      'checkbox-input',
      blockClasses.checkbox_field
    ];

    let selectClasses = [
      'select-input'
    ];
  
    let selectLabel = <label htmlFor={selectInputId}>{this.props.fields[secondFieldKey].label}</label>
    if (!this.props.fields[secondFieldKey].label) {
      selectLabel = ''
      selectClasses.push(inputClasses.full_width)
    }
  
    const options = this.props.fields[secondFieldKey].options.map((option) => {
      return { value: option.value, label: option.value }
    })

    return (
      <div className={fieldClasses.join(' ')}>
        <div className={checkbox_classes.join(' ')} data-test="input-checkbox">
          {checkboxLabelBefore}
          <input 
            type="checkbox" 
            id={checkboxInputId}
            name={this.props.fields[firstFieldKey].name} 
            onChange={this.checkboxChanged} 
            data-test="checkbox-option" 
            disabled={this.props.disabled}
            value={this.props.fields[firstFieldKey].api_id}
            data-block-id={this.props.blockId} 
            data-api-id={this.props.fields[firstFieldKey].api_id}
            checked={this.state.isChecked}
            ref={input => { this.inputElement = input }}
            />
          {checkboxLabel}
          {checkboxLabelAfter}
        </div>

        <div className={selectClasses.join(' ')} data-test="component-select">
          {selectLabel}
          <Select
            id={selectInputId}
            data-test="input-select"
            //value={value}
            isDisabled={this.props.disabled}
            className="select_input"
            options={options}
            onChange={this.selecValueHandler}
          />
        </div>
      </div>
    )
  }
};

export default InputCheckboxSelect;