import React, { useState, useEffect } from 'react';
import TimePicker from 'react-time-picker';

const inputTime = (props) => {
  const [inputTime, setInputTime] = useState(props.value);

  const setTime = (time, blockId, apiId) => {
    setInputTime(time);
    props.eventsChanged( time, blockId, apiId );

    if ( props.events ) {
      if ( props.events.length > 0 ) {
        props.events.forEach( (event) => {
          if ( time ) {
            props.eventTargetHandler(event.targets, 'value_show');
          } else {
            props.eventTargetHandler(event.targets, 'value_hide');
          }
        });
      }
    }
  }

  const inputId = props.id;

  let labelBefore,
      labelAfter,
      label = <label htmlFor={inputId}>{props.label}</label>

  if ( !props.label )
    label = <label className="empty-label"></label>

  if ( props.labelBefore )
    labelBefore = props.labelBefore;
  
  if ( props.labelAfter )
    labelAfter = props.labelAfter;

  let dateClasses = [
    'date-input',
    'input-field',
  ];

  return(
    <div className={dateClasses.join(' ')} data-test="input-time">
      {labelBefore}
      {label}
      <TimePicker
        id={inputId}
        className="input-time-picker"
        clearIcon={null}
        onChange={date => setTime(date, props.blockId, props.apiId)}
        disableClock={true}
        value={inputTime}
        />
      {labelAfter}
    </div>
  )
}

export default inputTime;