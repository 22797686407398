import React, { useEffect, useState } from 'react';
//import Api from '../../../utils/api';
//import * as actions from '../../../store/actions/index';

import PlainTextOutputModal from './PlainTextOutputModal'
import PdfOutputModal from './PdfOutputModal'
import CopyRecoveryCode from './CopyRecoveryCode'

import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap'

const ExportNoteModal = (props) => {
  const[open, setOpen] = useState(false);

  const handleClickOutside = e => {
    const currentModal = document.querySelector('#export-note-modal')
    const downloadPdfModal = document.querySelector('#download-pdf-modal');
    const plainTextModal = document.querySelector('#output-text-modal');

    if (currentModal) {
      if (currentModal.contains(e.target)) {
        return
      }
    }

    if (downloadPdfModal) {
      if (downloadPdfModal.contains(e.target)) {
        return
      }
    }

    if (plainTextModal) {
      if (plainTextModal.contains(e.target)) {
        return
      }
    }

    if ( currentModal.style.display === 'none' ) {
      return // inside click
    }

    setOpen(false) // outside click
  };

  const closeModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [open]);


  return (
    <React.Fragment>
      <div><button className="btn teal-solid-btn" onClick={e => setOpen(!open)}>Finish</button></div>
      <Modal id="export-note-modal" isOpen={open}>
        <Button className="close" onClick={closeModal}><span className="icon-X_CloseForm"></span></Button>
        <ModalHeader>Export your note<span>Choose an output based on your needs.</span></ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <PlainTextOutputModal
                exportHandler={props.export}
                recoveryCode={props.recoveryCode}
                outputData={props.plainTextOutput}
              />
            </Col>
            <Col>
              <div className="btn export-btn">
                <span className="icon-Kroll"></span>
                Push to Kroll
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <PdfOutputModal
                exportHandler={props.export}
                recoveryCode={props.recoveryCode}
              />
            </Col>
            <Col>
              <CopyRecoveryCode
                exportHandler={props.save}
                recoveryCode={props.recoveryCode} />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ExportNoteModal;