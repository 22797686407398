import React, { Component } from 'react';

import { connect } from 'react-redux';
//import * as actions from '../../store/actions/index';

import { ReactComponent as Logo } from '../../images/logo.svg';

import classes from './Mobile.module.scss';

class Mobile extends Component {
  state = {

  }

  render() {
    return (
      <div className={classes.mobile_view}>
        <div className={classes.mobile_content}>
          <div className={classes.logo}><Logo /></div>
          <div className={classes.message}>
            <p>QlinicalRx is not supported on mobile devices.<br/>
            Log in using a desktop or tablet browser to access your Dashboard.</p>
          </div>

          <div>
            <div className="small-text-format small-header">Your Account:</div>
            <div className={classes.pharmacy_name}>{this.props.currentPharmacyName}</div>
            <div className={classes.username}>{this.props.username}</div>
          </div>

          <div className={classes.button_link}>
            <a href="https://account.qlinicalrx.com/" target="_blank" rel="noreferrer noopener" className="btn">View Account Settings</a>
          </div>
        </div>

        <div className={classes.help_link}>
          <a href="https://support.qlinicalrx.com/" target="_blank" rel="noreferrer noopener">
            <span className="icon-Help"></span> Help
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacyName: state.currentPharmacyName,
    username: state.username,
  };
};

export default connect(mapStateToProps)(Mobile);