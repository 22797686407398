import React, { Component } from 'react'

import classes from './Inputs.module.scss'
import blockClasses from '../Block/Block.module.scss'

import InputCheckboxText from './InputGroups/InputCheckboxText';
import InputRadioText from './InputGroups/InputRadioText';
import InputDateSelect from './InputGroups/InputDateSelect';
import InputRadioDate from './InputGroups/InputRadioDate';
import InputCheckboxDate from './InputGroups/InputCheckboxDate';
import InputCheckboxSelect from './InputGroups/InputCheckboxSelect';
import InputRadioTextDate from './InputGroups/InputRadioTextDate';
import InputTextText from './InputGroups/InputTextText';

class InputGroup extends Component {
  state = {
    checkboxChecked: false,
    hasCheckbox: false,
    hasRadio: false,
    radioChecked: false,
    handleChange: false,
    fieldType: '',
  }

  componentDidMount() {
    let fieldType = '';

    for ( let key in this.props.fields ) {
      let field = this.props.fields[key];
      if ( 'input_checkbox' === field.type ) {
        this.setState({  hasCheckbox: true });

        if ( field.checked )
          this.setState({ checkboxChecked: true });
      } else if ( 'input_radio' === field.type ) {
        this.setState({ hasRadio: true });

        if ( field.checked )
          this.setState({ radioChecked: true });
      }

      if (key > 0 ) {
        fieldType += '-';
      }

      if ( 'input_checkbox' === field.type ) {
        fieldType += 'input_checkbox';
      }

      if ( 'input_radio' === field.type ) {
        fieldType += 'input_radio';
      }

      if ( 'input_text' === field.type ) {
        fieldType += 'input_text';
      }

      if ( 'input_select' === field.type ) {
        fieldType += 'input_select';
      }

      if ( 'input_date' === field.type ) {
        fieldType += 'input_date';
      }
    }

    //console.log(fieldType)

    this.setState({ fieldType: fieldType });
  }

  render() {
    let inputGroupClasses = [
      classes.input_group,
      blockClasses.input_group_field,
      'input-group',
      'input-field',
    ]

    let fieldInputs;
    switch ( this.state.fieldType ) {
      case 'input_checkbox-input_text':
        fieldInputs = <InputCheckboxText 
                        blockId={this.props.blockId}
                        disabled={this.props.disabled}
                        fields={this.props.fields}
                        checkboxChanged={this.props.checkboxChanged}
                        removeCheckbox={this.props.removeCheckbox}
                        textChanged={this.props.textChanged}
                      />
        inputGroupClasses.push('input-checkbox-input-text');
        break;
      case 'input_radio-input_text':
        //console.log('input_radio-input_text');
        //console.log(this.props);
        fieldInputs = <InputRadioText 
                        blockId={this.props.blockId}
                        disabled={this.props.disabled}
                        fields={this.props.fields}
                        radioChanged={this.props.radioChanged}
                        textChanged={this.props.textChanged}
                      />
        inputGroupClasses.push('input-radio-input-text');
        break;
      case 'input_radio-input_date':
        fieldInputs = <InputRadioDate 
                        blockId={this.props.blockId}
                        disabled={this.props.disabled}
                        fields={this.props.fields}
                        radioChanged={this.props.radioChanged}
                        dateChanged={this.props.dateChanged}
                      />
        inputGroupClasses.push('input-radio-input-date');
        break;
      case 'input_checkbox-input_select':
          fieldInputs = <InputCheckboxSelect 
                          blockId={this.props.blockId}
                          disabled={this.props.disabled}
                          fields={this.props.fields}
                          checkboxChanged={this.props.checkboxChanged}
                          selectChanged={this.props.selectChanged}
                        />
          inputGroupClasses.push('input-checkbox-input-select');
          break;
      case 'input_date-input_select':
        fieldInputs = <InputDateSelect 
                        blockId={this.props.blockId}
                        disabled={this.props.disabled}
                        fields={this.props.fields}
                        dateChanged={this.props.dateChanged}
                        selectChanged={this.props.selectChanged}
                      />
        inputGroupClasses.push('input-date-input-select');
        break;
      case 'input_checkbox-input_date':
        fieldInputs = <InputCheckboxDate 
                        blockId={this.props.blockId}
                        disabled={this.props.disabled}
                        fields={this.props.fields}
                        checkboxChanged={this.props.checkboxChanged}
                        dateChanged={this.props.dateChanged}
                      />
        inputGroupClasses.push('input-checkbox-input-date');
        break;
      case 'input_radio-input_text-input_date':
        fieldInputs = <InputRadioTextDate
                        blockId={this.props.blockId}
                        disabled={this.props.disabled}
                        fields={this.props.fields}
                        radioChanged={this.props.radioChanged}
                        textChanged={this.props.textChanged}
                        dateChanged={this.props.dateChanged}
                      />
        inputGroupClasses.push('input-radio-input-text-input-date');
        break;
      case 'input_text-input_text':
        fieldInputs = <InputTextText
                        blockId={this.props.blockId}
                        disabled={this.props.disabled}
                        fields={this.props.fields}
                        textChanged={this.props.textChanged}
                      />
        inputGroupClasses.push('input-radio-input-text-input-date');
        break;
        default:
    }
    
    return(
      <div className={inputGroupClasses.join(' ')} data-test="input-group">
        {fieldInputs}
      </div>
    )
  }
}

export default InputGroup;