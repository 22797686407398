import React, { useState, useEffect } from 'react';

import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";


import classes from '../Inputs.module.scss';

const inputDate = (props) => {
  const [startDate, setStartDate] = useState(null);
  const[value, setValue] = useState(null);

  let firstFieldKey = 0,
      secondFieldKey = 1;

  let fieldClasses = [
    classes.group_item,
    'group-item'
  ];


  const setDate = (date, blockId, apiId) => {
    setStartDate(date);
    props.dateChanged( getFormattedDate(date), blockId, apiId );
  }

  const getFormattedDate = (date) => {
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
  }

  const selecValueHandler = (selectedOption) => {
    setValue(selectedOption);
    props.selectChanged(selectedOption, props.fields[1].api_id, props.blockId);
  }

  useEffect(() => {
    setValue(options[0])
  }, [])

  //const dateInputId = props.blockId + '_' + props.fields[0].api_id;
  //const selectInputId = props.blockId + '_' + props.fields[1].api_id;

  const dateInputId = props.fields[firstFieldKey].id;
  const selectInputId = props.fields[secondFieldKey].id;

  let dateLabelBefore,
      dateLabelAfter,
      dateLabel = <label htmlFor={dateInputId}>{props.fields[0].label}</label>

  if ( !props.fields[0].label )
    dateLabel = <label className="empty-label"></label>

  if ( props.fields[0].labelBefore )
    dateLabelBefore = props.fields[0].labelBefore;
  
  if ( props.fields[0].labelAfter )
    dateLabelAfter = props.fields[0].labelAfter;

  
  let selectClasses = [
    classes.select_input
  ];

  let selectLabel = <label htmlFor={selectInputId}>{props.fields[1].label}</label>
  if (!props.fields[1].label) {
    selectLabel = ''
    selectClasses.push(classes.full_width)
  }

  const options = props.fields[1].options.map((option) => {
    return { value: option.value, label: option.value }
  });

  return(
    <div className={fieldClasses.join(' ')}>
      <div className="date-input">
        {dateLabelBefore}
        {dateLabel}
        <DatePicker
          id={dateInputId}
          selected={startDate}
          onChange={date => setDate(date, props.blockId, props.fields[0].api_id)}
          dateFormat="yyyy-MM-dd"
          className="input-date"
          disabled={props.disabled}
          />
        {dateLabelAfter}
      </div>

      <div className={selectClasses.join(' ')} data-test="component-select">
        {selectLabel}
        <Select
          id={selectInputId}
          data-test="input-select"
          value={value}
          className="select_input"
          options={options}
          onChange={selecValueHandler}
          isDisabled={props.disabled}
        />
      </div>
    </div>
  )
}

export default inputDate;