import React from 'react';

import classes from '../ChangeWorkspace.module.scss';

const workspace = (props) => {

  let selected = '';
  let icon_classes = ['icon-Checkmark', 'colour-teal', classes.workspace_icon];
  if ( true === props.selected )
    selected = <span className={icon_classes.join(' ')}></span>

  return (
    <li className={classes.pharmacy_listing} data-pharmacy-id={props.id} data-pharmacy-name={props.name} onClick={props.changeHandler}>
      <div className={classes.selected_div}>{selected}</div>
      <div className={classes.label}>{props.name}</div>
    </li>
  )
}

export default workspace;