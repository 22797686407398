import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Api from '../utils/api';

import { Col } from 'reactstrap';
import Search from './Dashboard/Search/Search';
import CustomTemplatesList from './CustomTemplates/CustomTemplatesList/CustomTemplatesList';

import classes from './Dashboard/Dashboard.module.scss';

class CustomTemplates extends Component {
  state = {
    blocksData: [],
    filtered: false,
    filterTerms: [],
    templates: null,
    searchFilter: null,
    sort: 'r'
  }

  sortHandler = (search, sort) => {
    Api.fetch(`/api/v1/tools/templates/?q=${search}&pharmacy=${this.props.workspace.id}&custom=${true}`).then((response) => {
      console.log(response);
      this.setState({ 
        templates: response,
        sort: sort
      });
    });
  }

  searchHandler = (templates) => {
    this.setState({ templates: templates });
  }

  clearHandler = () => {
    //console.log('clear');
    Api.fetch(`/api/v1/tools/templates/?pharmacy=${this.props.workspace.id}&custom=${true}`).then((response) => {
      console.log(response);
      this.setState({
        filtered: false,
        templates: response,
        searchFilter: null
      });
    });
  }

  saveHandler = () => {
    const dataBlocks = this.buildBlocksHandler();
    const dataInputs = this.buildInputsHandler();
    const adminGroups = this.buildAdminHandler();

    const data = {
      pharmacy: this.props.currentPharmacy,
      recovery_code: this.state.template.recovery_code,
      template_title: this.state.name,
      blocks: dataBlocks,
      inputs: dataInputs,
      admin_groups: adminGroups,
    }

    Api.post('/api/v1/tools/templates/custom/save/', data).then((response) => {
      this.setState({
        saved: true,
        recovery_code: response.recovery_code
      })
    })
  }

  render() {
    let endResults = '',
        templateList = <CustomTemplatesList templates={this.state.templates} />
    if ( window.location.pathname === '/create-note/' ) {
      templateList = <CustomTemplatesList templates={this.state.templates} />
      endResults = <div className={classes.end_results}>
                    <Col xs="12" className={classes.end_results_text}><span>End of Results</span></Col>
                   </div>
    }

    return (
      <div className={classes.dashboard}>
        <div className={classes.dash_row}>
          <header>Manage my Custom Templates</header>
          <NavLink className={['btn', classes.create_note_btn].join(' ')} to={'/new-template/'} exact data-test="component-edit-template">+ New Custom Template</NavLink>
        </div>
        <Col>
          <Search 
            search={this.searchHandler} 
            sort={this.sortHandler}
            searchFilter={this.state.searchFilter}
          />
        </Col>
        <div className={[classes.dash_row, classes.templates_row].join(' ')}>
          <Col>{templateList}</Col>
        </div>
        {endResults}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    workspace: state.workspace,
  };
};

export default connect(mapStateToProps)(CustomTemplates);