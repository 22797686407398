import React, { useEffect, useState } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

import Api from '../../../utils/api';

const SearchMedication = styled.div`
  margin-bottom: 1rem;
`;

const Medication = styled.div`
  margin-bottom: 1rem;
`;

const DoseFreeform = styled.div`
margin-bottom: 1rem;
`;

const DailyDosage = styled.div`
  margin-bottom: 1rem;

  input[type="text"] {
    display: inline-block;
    width: 100px;
    margin-right: .5rem;
  }

  .select_input {
    display: inline-block;
    width: 80px;
  }
`;

const QlinicalRoutes = styled.div`
  margin-bottom: 1rem;
`;

const Frequency = styled.div`
  margin-bottom: 1rem;
`;

const CurrentTherapy = styled.div`
  margin-bottom: 1rem;
`;

const CurrentPolypharmacy = styled.div`
  margin-bottom: 1rem;
`;

const QuickSelection = styled.div`
  display: inline-block;
  margin-right: .5rem;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin-bottom: 1rem;
`;

const AddMedicationHistory = (props) => {
  const[open, setOpen] = useState(false);
  const[medication, setMedication] = useState(null);
  const[medicationId, setMedicationId] = useState(null);
  const[medicationName, setMedicationName] = useState(null);
  const[medicationList, setMedicationList] = useState(null);
  const[active, setActive] = useState(null);
  const[currentTherapy, setCurrentTherapy] = useState(null);
  const[comments, setComments] = useState(null);
  const[dailyDoseValidated, setDailyDoseValidated] = useState('');
  const[dosageTypes, setDosageTypes] = useState(null);
  const[dosageUnit, setDosageUnit] = useState(null);
  const[dailyDosageType, setDailyDosageType] = useState(null);
 
  const[routeTypes, setRouteTypes] = useState(null);
  const[routeType, setRouteType] = useState(null);

  // Fields
  const[selectedDosageUnit, setDosageUnitSelectedOption] = useState(null);
  const[routesSelectedOption, setRoutesSelectedOption] = useState([]);

  // Values
  const[freeformValue, setFreeFormValue] = useState('');
  const[doseValidatedValue, setDoseValidatedValue] = useState('');
  const[frequencyValue, setFrequencyValue] = useState('');
  const[routesValue, setRoutesValue] = useState([]);
  const[polypharmacyValue, setPolypharmacyValue] = useState(false);
  const[noteContentValue, setNoteContentValue] = useState(false);
  const[omeValue, setOmeValue] = useState({});
  const[omeTotalValue, setOmeTotalValue] = useState({});
  
  // Checks
  const [hasDoseFreeform, setHasDoseFreeform] = useState(false);
  const [hasDoseValidated, setHasDoseValidated] = useState(false);
  const [hasDailyDoseFreeform, setHasDailyDoseFreeform] = useState(false);
  const [hasDailyDoseValidated, setHasDailyDoseValidated] = useState(false);
  const [hasFrequency, setHasFrequency] = useState(false);
  const [hasRoutes, setHasRoutes] = useState(false);
  const [hasComment, setHasComment] = useState(false);
  const [hasActive, setHasActive] = useState(false);
  const [hasPolypharmacy, setHasPolypharmacy] = useState(false);
  const [hasNoteContent, setHasNoteContent] = useState(false);
  const [hasOme, setHasOme] = useState(false);

  const[autocompleteOpen, setAutocompleteOpen] = useState(false);
  const[options, setOptions] = useState([]);
  const nameRef = React.createRef();

  const closeModal = () => {
    setOpen(false);
  }

  const medicationSearchHandler = (e) => {
    var query = e.target.value;
    Api.fetch('/api/v1/medications/?q=' + query).then((response) => {
      setOptions(response);
    }).catch((error) => {
      console.error(error);
    })
  }

  const dosageFreeformHandler = (e) => {
    setFreeFormValue(e.target.value);
  }

  const dosageValidatedHandler = (e) => {
    const text = e.target.value;
    const regex = /^(\d+)(\s-\s|\s-|-\s|-|\sto\s){0,1}(\d*)/g;
    var match = text.match(regex);
    if ( match !== null )
      setDoseValidatedValue(e.target.value);
  }

  /*const dailyDosageHandler = (e) => {
    setDailyDoseValidated(e.target.value);
  }*/

  const dosageUnitHandler = (selectedOption) => {
    setDosageUnit(selectedOption.value);
    setDosageUnitSelectedOption(selectedOption);
  }

  const frequencyHandler = (e) => {
    setFrequencyValue(e.target.value);
  }

  const routesHandler = (selectedOption) => {
    setRoutesValue(selectedOption.value);
    setRoutesSelectedOption(selectedOption);
  }

  const commentsHandler = (e) => {
    setComments(e.target.value);
  }

  const currentTherapyHandler = (value) => {
    setActive(value);
    setCurrentTherapy(value);
  }

  const currentPolypharmacyHandler = (value) => {
    setPolypharmacyValue(value);
  }

  const noteContentHandler = (value) => {
    setNoteContentValue(value);
  }

  const quickSelection = (e) => {
    const medicationId = e.target.getAttribute('data-medication-id');
    const medicationName = e.target.getAttribute('data-medication-name');
    const dailyDose = e.target.getAttribute('data-daily-dose');
    const dailyDoseDefault = e.target.getAttribute('data-daily-dose-default');
    const dailyDoseType = e.target.getAttribute('data-daily-dose-type');
    const routesOptions = e.target.getAttribute('data-routes-options');
    const routesDefault = e.target.getAttribute('data-routes-default');
    const active = e.target.getAttribute('data-active');
    const polypharmacy = e.target.getAttribute('data-polypharmacy');

    let dosageOptions = dailyDose.split('.');
    let dosageTypes = [];

    for(var i=0; i<dosageOptions.length; i++) {
      dosageTypes.push(
        {
          value: dosageOptions[i],
          label: dosageOptions[i]
        }
      )
    }

    let routeOptions = routesOptions.split('.');
    let routesTypes = [];
    for(var j=0; j<routeOptions.length; j++) {
      routesTypes.push(
        {
          value: routeOptions[j],
          label: routeOptions[j]
        }
      )
    }

    let dosageDefault = {
      value: dailyDoseDefault,
      label: dailyDoseDefault
    };

    let routeDefault = {
      value: routesDefault,
      label: routesDefault
    }
    
    setMedicationId(medicationId);
    setMedicationName(medicationName);
    setDosageTypes(dosageTypes);
    setDosageUnitSelectedOption(dosageDefault);
    setDailyDosageType(dailyDoseType);
    setRouteTypes(routesTypes);
    setRoutesValue(routesDefault);
    setRoutesSelectedOption(routeDefault);
    setCurrentTherapy(active);
    setPolypharmacyValue(polypharmacy);
  }

  const saveTherapy = () => {
    /*let attributes = [
      { [dailyDosageType]: { "dose": dailyDoseValidated, "unit": selectedDosageUnit.value } },
      { 'routes': 'subcut' }, // how to add this dynamically?
      { 'active': currentTherapy },
      { 'polypharmacy': polypharmacy },
      { 'note_content': comments },
    ]*/
    let attributes = [];

    if ( hasDoseFreeform ) {
      attributes.push(
        { ['dose_freeform']: freeformValue }
      );
    }

    if ( hasDoseValidated ) {
      attributes.push(
        { ['dose_validated']: { "dose": doseValidatedValue, "unit": selectedDosageUnit.value } }
      );
    }

    if ( hasDailyDoseFreeform ) {
      attributes.push(
        { ['daily_dose_freeform']: freeformValue }
      );
    }

    if ( hasDailyDoseValidated ) {
      attributes.push(
        { ['daily_dose_validated']: { "dose": doseValidatedValue, "unit": selectedDosageUnit.value } }
      );
    }

    if ( hasFrequency ) {
      attributes.push(
        { ['frequency']: frequencyValue }
      );
    }

    if ( hasRoutes ) {
      attributes.push(
        { ['routes']: routesValue }
      );
    }

    if ( hasComment ) {
      attributes.push(
        { ['comment']: comments }
      );
    }

    if ( hasActive ) {
      attributes.push(
        { ['active']: currentTherapy }
      );
    }

    if ( hasPolypharmacy ) {
      attributes.push(
        { ['polypharmacy']: polypharmacyValue }
      );
    }

    if ( hasNoteContent ) {
      attributes.push(
        { ['note_content']: noteContentValue }
      );
    }

    if ( hasOme ) {
      let dose = null;
      let singleOmeLower = 0;
      let singleOmeUpper = 0;
      let omeLowerTotal = 0;
      let omeUpperTotal = 0;
      
      if ( hasDailyDoseFreeform || hasDoseFreeform ) {
        dose = freeformValue;
      }

      if ( hasDailyDoseValidated || hasDoseFreeform ) {
        dose = doseValidatedValue;
      }

      if ( currentTherapy && dose ) {
        console.log(medicationName+ ' - ' + routesValue + ' - ' + dose);
        const medOme = calculateOME(medicationId, dose, routesValue, selectedDosageUnit.value);
        console.log(medOme);
        singleOmeLower = medOme.ome_min;
        singleOmeUpper = medOme.ome_max;
        
        omeLowerTotal += medOme.ome_min_total;
        omeUpperTotal += medOme.ome_max_total;
      }

      // get OME for all medications
      props.existingMedications.forEach((med) => {
        let dose = null;
        let active = null;
        let route = null;
        let attributeLength = med.attributes.length;
          
        for ( let i=0; i<attributeLength; i++) {
          const attribute = med.attributes[i];

          if ( attribute[dailyDosageType] ) {
            dose = attribute[dailyDosageType].dose;
          }
          
          if ( attribute.routes )
            route = attribute.routes;
          
          if ( attribute.active )
            active = attribute.active;
        }
        
        if ( active && dose ) {
          console.log(med.details.display_name + ' - ' + route + ' - ' + dose);
          const medsOme = calculateOME(med.id, dose, route, selectedDosageUnit.value);
          console.log(medsOme);

          omeLowerTotal += medsOme.ome_min_total;
          omeUpperTotal += medsOme.ome_max_total;
        }
      })

      // build OME strings
      const omeValue = getOmeValue(singleOmeLower, singleOmeUpper, selectedDosageUnit.value);
      const omeTotalValue = getOmeValue(omeLowerTotal, omeUpperTotal, selectedDosageUnit.value);

      console.log('Save Values');
      console.log(omeValue);
      console.log(omeTotalValue);

      attributes.push(
        { ['ome']: { "ome_medication": omeValue, "ome_total": omeTotalValue } }
      );
    }

    props.saveTherapy(medicationId, medicationName, attributes);

    // clear form
    clearForm();
    closeModal();
  }

  const clearForm = () => {
    setDailyDoseValidated('');
    setMedicationId(null);
    setMedicationName(null);
    setDosageUnitSelectedOption(null);
    setCurrentTherapy(null);
    setPolypharmacyValue(null);
    setDosageTypes(
      [
        { value: 'mg', label: 'mg' },
        { value: 'g', label: 'g' },
        { value: 'mcg', label: 'mcg' }
      ]
    )
  }

  const calculateOME = (medicationId, dose, route, doseUnit) => {
    let omeMin = 0;
    let omeMax = 0;
    let omeMinTotal = 0;
    let omeMaxTotal = 0;

    if ( isFreeformDose(dose) ) {
      let doses = getLowerUppderDoses( dose );

      const newLowerDose = calculateUnitDoses(doses.lower_dose, doseUnit);
      const newUpperDose = calculateUnitDoses(doses.upper_dose, doseUnit);

      const lowerConversionFactor = getConversionFactor(medicationId, route, newLowerDose);
      const upperConversionFactor = getConversionFactor(medicationId, route, newUpperDose);
      omeMin = calculateOmeToMin(lowerConversionFactor, newLowerDose);
      omeMax = calculateOmeToMax(upperConversionFactor, newUpperDose);

      // Add these OME values to the running total
      omeMinTotal += omeMin;
      omeMaxTotal += omeMax;
    } else {
      // newly added medication
      const newDose = calculateUnitDoses(dose, doseUnit);
      const singleConversionFactor = getConversionFactor(medicationId, route, newDose);
      omeMin = calculateOmeToMin(singleConversionFactor, newDose);
      omeMax = calculateOmeToMax(singleConversionFactor, newDose);

      // Add these OME values to the running total
      omeMinTotal += omeMin;
      omeMaxTotal += omeMax;
    }

    if ( omeMinTotal > 0 && omeMaxTotal > 0 ) {
      // Round once more to nearest 0.1 to account for float arithmetic errors
      omeMinTotal = Math.round(omeMinTotal * 10) / 10;
      omeMaxTotal = Math.round(omeMaxTotal * 10) / 10;
    }

    return {
      ome_min: omeMin,
      ome_max: omeMax,
      ome_min_total: omeMinTotal,
      ome_max_total: omeMaxTotal
    }
  }

  const calculateUnitDoses = (dose, doseUnit) => {
    // get OME for added medication
    if ( doseUnit === 'g' ) {
      dose = dose * 1000;
    } else if ( doseUnit === 'mcg' ) {
      dose = dose / 1000;
    }

    return dose;
  }

  const isFreeformDose = (dose) => {
    let toIndex = dose.indexOf(' to ');
    let dashIndex = dose.indexOf('-');

    if ( toIndex > -1 )
      return true
    
    if ( dashIndex > -1 )
      return true;

    return false;
  }

  const getLowerUppderDoses = (dose) => {
    // if freeform break into
    let toIndex = dose.indexOf(' to ');
    var dashIndex = dose.indexOf('-');
    let lower_dose = null;
    let upper_dose = null;

    if ( toIndex > -1 ) {
      lower_dose = parseFloat(dose.substring(0, toIndex));
      upper_dose = parseFloat(dose.substring(toIndex+3, dose.length));
    }

    if ( dashIndex > -1 ) { 
      lower_dose = parseFloat(dose.substring(0, dashIndex));
      upper_dose = parseFloat(dose.substring(dashIndex+1, dose.length));
    }

    return {
      lower_dose: lower_dose,
      upper_dose: upper_dose
    }
  }

  const getConversionFactor = (medicationId, route) => {
    let conversionFactor = {
      toMin: null,
      toMax: null
    }

    props.medicationList.forEach((medication) => {
      if ( medication.id == medicationId ) {
        let attributeLength = medication.attributes.length;
    
        for ( let i=0; i<attributeLength; i++) {
          const attribute = medication.attributes[i];
          let omeArr = attribute.ome;

          if ( omeArr ) {
            omeArr.forEach((ome) => {
              if ( ome.route == route ) {
                if ( ome.conversion_lower )
                  conversionFactor.toMin = ome.conversion_lower;

                if ( ome.conversion_upper )
                  conversionFactor.toMax = ome.conversion_upper;

                if ( ome.dose_lower )
                  conversionFactor.toMin = ome.dose_lower;

                if ( ome.dose_upper )
                  conversionFactor.toMax = ome.dose_upper;
              }
            })
          }
        }
      }
    })

    return conversionFactor;
  }

  const calculateOmeToMin = (conversionFactor, dose) => {
    let omeMin = 0;

    /*console.log(dose);
    console.log(conversionFactor);*/

    // Calculate the lower bound of the OME value and round to nearest 0.1
    omeMin = dose * conversionFactor.toMin;
    omeMin = Math.round(omeMin * 10) / 10;
    //console.log(omeMin);

    return omeMin;
  }

  const calculateOmeToMax = (conversionFactor, dose) => {
    let omeMax = 0;

    // Calculate the upper bound of the OME value and round to nearest 0.1
    omeMax = dose * conversionFactor.toMax;
    omeMax = Math.round(omeMax * 10) / 10;

    return omeMax;
  }

  const getOmeValue = (omeLower, omeUpper, unit) => {
    if ( omeLower == omeUpper ) {
      return omeLower.toString() + ' ' + unit;
    } else if ( omeLower != omeUpper ) {
      return omeLower.toString() + '-' + omeUpper.toString() + ' ' + unit;
    }
  }

  useEffect(() => {
    // Set which attributes we have
    props.attributes.forEach((value) => {
      switch(value) {
        case 'dose_freeform':
          setHasDoseFreeform(true);
          break;
        case 'dose_validated':
          setHasDoseValidated(true);
          break;
        case 'daily_dose_freeform':
          setHasDailyDoseFreeform(true);
          break;
        case 'daily_dose_validated':
          setHasDailyDoseValidated(true);
          break;
        case 'frequency':
          setHasFrequency(true);
          break;
        case 'routes':
          setHasRoutes(true);
          break;
        case 'comment':
          setHasComment(true);
          break;
        case 'active':
          setHasActive(true);
          break;
        case 'polypharmacy':
          setHasPolypharmacy(true);
          break;
        case 'note_content':
          setHasNoteContent(true);
          break;
        case 'ome':
          setHasOme(true);
          break;
      }
    })

    // loop through the medication list to build the QuickSelection
    const medicationList = props.medicationList.map((medication, index) => {
      let medicationName = medication.display_name;
      let attributeLength = medication.attributes.length;
      let daily_dose = '';
      let daily_dose_default = '';
      let daily_dose_type = '';
      let routes_default = '';
      let routes_options = '';
      let active_default = '';
      let polypharmacy_default = '';
      
      // loop through attribute list
      for( let i=0; i<attributeLength; i++) {
        const attribute = medication.attributes[i];
        const type = setDailyDoseType(attribute);

        if ( type !== null ) {
          daily_dose_type = type;
          let daily_dose_type_attr = attribute[type];
          if ( daily_dose_type_attr.length > 0 ) {
            for(let i=0; i<daily_dose_type_attr.length; i++) {
              if ( i > 0 ) {
                daily_dose += '.';
              }
              daily_dose += daily_dose_type_attr[i].abbreviation;
    
              if ( daily_dose_type_attr[i].default ) {
                daily_dose_default = daily_dose_type_attr[i].abbreviation;
              }
            }
          }
        }

        let routes = attribute.routes;
        let active = attribute.active;
        let polypharmacy = attribute.polypharmacy;

        // set route options
        if ( routes ) {
          for( let j=0; j<routes.length; j++) {
            if ( j > 0 ) routes_options += '.';
            routes_options += routes[j].abbreviation;

            if ( routes[j].default ) {
              routes_default = routes[j].abbreviation;
            }
          }
        }
        
        // set active
        if ( active )
          active_default = medication.attributes[i].active;
        
        // set polypharmacy
        if ( polypharmacy )
          polypharmacy_default = medication.attributes[i].polypharmacy;
      }

      return <QuickSelection 
        key={'medication-' + index}
        data-daily-dose={daily_dose}
        data-daily-dose-default={daily_dose_default}
        data-daily-dose-type={daily_dose_type}
        data-routes-options={routes_options}
        data-routes-default={routes_default}
        data-active={active_default}
        data-polypharmacy={polypharmacy_default}
        data-medication-id={medication.id}
        data-medication-name={medication.display_name}
        onClick={quickSelection}>
          {medicationName}
      </QuickSelection>
    });

    setMedicationList(medicationList);
    setDosageTypes(
      [
        { value: 'mg', label: 'mg' },
        { value: 'g', label: 'g' },
        { value: 'mcg', label: 'mcg' }
      ]
    )
    setRouteTypes(
      [
        { value: 'PO', label: 'PO' },
        { value: 'IM', label: 'IM' },
        { value: 'subcut', label: 'subcut' },
        { value: 'IV', label: 'IV' },
      ]
    )
  }, [])

  const buildDosageOptions = (dosage) => {
    let daily_dose = [];

    for(let i=0; i<dosage.length; i++) {
      daily_dose.push(
        { value: dosage[i].abbreviation, label: dosage[i].abbreviation }
      );

      if ( dosage[i].default ) {
        let dosageDefault = {
          value: dosage[i].abbreviation,
          label: dosage[i].abbreviation 
        };
        setDosageUnitSelectedOption(dosageDefault);
      }
    }

    return daily_dose;
  }

  const setDailyDoseType = (dailyDoseObj) => {
    if ( dailyDoseObj.dose_freeform ) {
      return 'dose_freeform';
    }

    if ( dailyDoseObj.dose_validated ) {
      return 'dose_validated';
    }

    if ( dailyDoseObj.daily_dose_freeform ) {
      return 'daily_dose_freeform';
    }

    if ( dailyDoseObj.daily_dose_validated ) {
      return 'daily_dose_validated';
    }

    return null;
  }

  return (
    <React.Fragment>
      <ButtonContainer><button className="div-container" onClick={e => setOpen(true)}>Add Medication History</button></ButtonContainer>
      <Modal id="medication-history-modal" isOpen={open}>
        <Button className="close" onClick={closeModal}><span className="icon-X_CloseForm"></span></Button>
        <ModalHeader>Choose a therapy to add</ModalHeader>
        <ModalBody>
          <SearchMedication>
            <label className="small-text-format small-header text-uppercase colour-teal" htmlFor="medication-search">Search for Medication</label><br />
            <Autocomplete
              id="medication-search"
              style={{ borderRadius: 0, padding: 0 }}
              open={autocompleteOpen}
              onOpen={() => {
                setAutocompleteOpen(true);
              }}
              onClose={() => {
                setAutocompleteOpen(false);
              }}
              onChange={(event, newValue) => {
                var attribute_count = props.attributes.length;
                var attribute_str = '';

                for (var i=0; i<attribute_count; i++ ) {
                  if ( i > 0 ) attribute_str += '&';
                  attribute_str += props.attributes[i] + '=true';
                }

                Api.fetch(`/api/v1/medications/${newValue.id}/?${attribute_str}`).then((response) => {
                  setMedicationId(response.id);
                  setMedicationName(response.display_name);

                  const doseAttribute = props.doseAttribute;
                  const attributes = response.attributes;
                  const attributeLength = attributes.length;

                  setDailyDosageType(doseAttribute);
                  for( let i=0; i<attributeLength; i++) {
                    const type = setDailyDoseType(attributes[i]);

                    if ( attributes[i][doseAttribute] ) {
                      const dose_options = buildDosageOptions(attributes[i][doseAttribute]);
                      setDosageTypes(dose_options);
                    }

                    if ( attributes[i].routes ) {
                      let routesTypes = [];
                      let routesDefault = null;

                      for(var j=0; j<attributes[i].routes.length; j++) {
                        routesTypes.push(
                          {
                            value: attributes[i].routes[j].abbreviation,
                            label: attributes[i].routes[j].abbreviation
                          }
                        )

                        if ( attributes[i].routes[j].default ) {
                          routesDefault = attributes[i].routes[j].abbreviation;
                        }
                      }

                      let routeDefault = {
                        value: routesDefault,
                        label: routesDefault
                      }

                      setRouteTypes(routesTypes);
                      setRoutesValue(routesDefault);
                      setRoutesSelectedOption(routeDefault);
                    }
                    
                    if ( attributes[i].active )
                      setCurrentTherapy(attributes[i].active);
                    
                    if ( attributes[i].polypharmacy )
                      setPolypharmacyValue(attributes[i].polypharmacy);
                  }
                });
              }}
              getOptionSelected={(option, value) => option.value === value.name}
              getOptionLabel={(option) => option.name_display}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(event, newValue) => {
                    medicationSearchHandler(event);
                  }}
                />
              )}
            />
            <div>

            </div>
          </SearchMedication>
          
          <div>
            <div className="small-text-format small-header text-uppercase colour-teal">Quick Selection</div>
            {medicationList}
          </div>
          
          {medicationName ?
            <Medication>
              <div className="small-text-format small-header text-uppercase colour-teal">Name</div>
              <span ref={nameRef}>{medicationName}</span>
            </Medication> 
            : null
          }

          <DoseFreeform>
            {hasDoseFreeform === true &&
              <div>
                <div className="small-text-format small-header text-uppercase colour-teal">Dose Freeform</div>
                <div>
                  <input type="text" value={freeformValue} onChange={dosageFreeformHandler} />
                </div> 
              </div>
            }

            {hasDailyDoseFreeform === true &&
              <React.Fragment>
                <div className="small-text-format small-header text-uppercase colour-teal">Daily Dose Freeform</div>
                <div>
                  <input type="text" value={freeformValue} onChange={dosageFreeformHandler} />
                </div>
              </React.Fragment>
            }
          </DoseFreeform>

          {hasDoseValidated === true &&
            <DailyDosage>
              <div className="small-text-format small-header text-uppercase colour-teal">Dose Validated</div>
              <div>
                <input type="text" value={doseValidatedValue} onChange={dosageValidatedHandler} />
                <Select
                  id="daily-dosage-type"
                  className="select_input"
                  value={selectedDosageUnit}
                  options={dosageTypes}
                  onChange={dosageUnitHandler}
                />
              </div>
            </DailyDosage>
          }

          {hasDailyDoseValidated === true &&
            <DailyDosage>
              <div className="small-text-format small-header text-uppercase colour-teal">Daily Dose Validated</div>
              <div>
                <input type="text" value={doseValidatedValue} onChange={dosageValidatedHandler} />
                <Select
                  id="daily-dosage-type"
                  className="select_input"
                  value={selectedDosageUnit}
                  options={dosageTypes}
                  onChange={dosageUnitHandler}
                />
              </div>
            </DailyDosage>
          }
          {hasFrequency === true &&
            <Frequency>
              <div className="small-text-format small-header text-uppercase colour-teal">Frequency</div>
              <div>
                <input type="text" value={frequencyValue} onChange={frequencyHandler} />
              </div>
            </Frequency>
          }

          {hasRoutes === true &&
            <QlinicalRoutes>
              <div className="small-text-format small-header text-uppercase colour-teal">Route</div>
              <div>
                <Select
                  id="route-type"
                  className="select_input"
                  value={routesSelectedOption}
                  options={routeTypes}
                  onChange={routesHandler}
                />
              </div>
            </QlinicalRoutes>
          }

          {hasComment === true &&
            <div className="div-container">
              <div className="small-text-format small-header text-uppercase colour-teal">Comments</div>
              <div>
              <textarea onChange={commentsHandler}></textarea>
              </div>
            </div>
          }

          {hasActive === true &&
            <CurrentTherapy>
              <div className="small-text-format small-header text-uppercase colour-teal">Current Therapy?</div>
              <div className="radio-input input-field" data-test="input-radio">
                <div className="radio_option" data-test="radio-option">
                  <input type="radio" id="current-type-yes" name="currenttherapy" onChange={e => currentTherapyHandler(true)} checked={currentTherapy ? 'checked' : ''} />
                  <label htmlFor="current-type-yes">Yes</label>
                </div>
              </div>
              
              <div className="radio-input input-field" data-test="input-radio">
                <div className="radio_option" data-test="radio-option">
                  <input type="radio" id="current-type-no" name="currenttherapy" onChange={ e => currentTherapyHandler(false)} checked={currentTherapy ? '' : 'checked'} />
                  <label htmlFor="current-type-no">No</label>
                </div>
              </div>
            </CurrentTherapy>
          }
          
          {hasPolypharmacy == true &&
            <CurrentPolypharmacy>
              <div className="small-text-format small-header text-uppercase colour-teal">Polypharmacy</div>
              <div className="radio-input input-field" data-test="input-radio">
                <div className="radio_option" data-test="radio-option">
                  <input type="radio" id="polypharmacy-yes" name="polypharmacy" onChange={ e => currentPolypharmacyHandler(true)} checked={polypharmacyValue ? 'checked' : ''} />
                  <label htmlFor="polypharmacy-yes">Yes</label>
                </div>
              </div>
              
              <div className="radio-input input-field" data-test="input-radio">
                <div className="radio_option" data-test="radio-option">
                  <input type="radio" id="polypharmacy-no" name="polypharmacy" onChange={ e => currentPolypharmacyHandler(false)} checked={polypharmacyValue ? '' : 'checked'} />
                  <label htmlFor="polypharmacy-no">No</label>
                </div>
              </div>
            </CurrentPolypharmacy>
          }

          {hasNoteContent == true &&
            <div className="div-container">
              <div className="small-text-format small-header text-uppercase colour-teal">Note Content</div>
              <div className="radio-input input-field" data-test="input-radio">
                <div className="radio_option" data-test="radio-option">
                  <input type="radio" id="notecontent-yes" name="notecontent" onChange={ e => noteContentHandler(true)} checked={noteContentValue ? 'checked' : ''} />
                  <label htmlFor="notecontent-yes">Yes</label>
                </div>
              </div>
              
              <div className="radio-input input-field" data-test="input-radio">
                <div className="radio_option" data-test="radio-option">
                  <input type="radio" id="notecontent-no" name="notecontent" onChange={ e => noteContentHandler(false)} checked={noteContentValue ? '' : 'checked'} />
                  <label htmlFor="notecontent-no">No</label>
                </div>
              </div>
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer-container">
            <Button color="primary" onClick={saveTherapy}>Save Therapy</Button>
            <div className="cancel-btn colour-teal" onClick={e => setOpen(false)}>Cancel</div>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default AddMedicationHistory