import React, { Component } from 'react'

import Block from '../BlocksList/Block/Block'

class PdfSettings extends Component {
  state =  {
    settings: this.props.settings
  }

  componentDidMount() {
    this.setState({
      settings: this.props.settings
    })

    console.log('SETTINGS')
    console.log(this.props.settings)
  }

  componentDidUpdate( prevProps, prevState ) {
    if ( prevProps.settings !== this.props.settings ) {
      this.setState({ settings: this.props.settings })
    }
  }

  render() {
    let pdfSettings;
    if ( this.state.settings.length > 0 ) {
      pdfSettings = this.state.settings.map((setting, key) => {
        return setting.blocks.map((block, key) => {
          return <Block
                  key={'pdf-setting-' + key} 
                  blockId={block.block_id} 
                  cds={[]}
                  name={block.header}  
                  fields={block.content}
                  labelPosition="top" />
        })
      })
    }

    return(
      <div>
        <h3>PDF Settings</h3>
        {pdfSettings}
      </div>
    )
  }
}

export default PdfSettings