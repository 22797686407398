import React, { Component } from 'react';

import blockClasses from '../../Block/Block.module.scss'
import inputClasses from '../Inputs.module.scss';
import DatePicker from "react-datepicker";

class InputRadioDate extends Component {
  state = {
    isChecked: this.props.fields[0].checked,
    startDate: null
  }

  componentDidMount() {
    //console.log(this.props.fields[0].checked)
  }

  eventHandler = ( event ) => {
    if ( event.target.checked ) {
      if ( this.state.events.length ) {
        for (let i=0; i< this.state.events.length; i++) {
          this.props.eventsChanged(this.state.events[i], 'radio', true)
        }
      }
    }
  }

  setDate = (date, blockId, apiId) => {
    console.log('set date');
    this.setState({
      isChecked: true,
      startDate: date
    });

    this.inputElement.checked = true;
    
    this.props.radioChanged(this.inputElement);
    this.props.dateChanged(this.getFormattedDate(date), blockId, apiId);

    if ( this.props.events.length > 0 ) {
      console.log('Date Events');
      console.log(this.props.events);
      this.props.events.forEach( (event) => {
        if ( date ) {
          this.props.eventTargetHandler(event.targets, 'value_show');
        } else {
          this.props.eventTargetHandler(event.targets, 'value_hide');
        }
      });
    }
  }

  getFormattedDate = (date) => {
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
  }

  radioChanged = (e) => {
    console.log('radio changed');
    const target = e.target;
    //this.eventHandler(target);
    this.props.radioChanged(target);
    this.setState({ isChecked: true })
  }

  render() {
    let firstFieldKey = 0,
        secondFieldKey = 1;
        
    let radioClasses = [
      'radio-input',
      blockClasses.radio_field
    ];

    //const radioInputId = this.props.blockId + '_' + this.props.fields[0].api_id;
    //const dateInputId = this.props.blockId + '_' + this.props.fields[1].api_id;

    const radioInputId = this.props.fields[firstFieldKey].id;
    const dateInputId = this.props.fields[secondFieldKey].id;

    let fieldClasses = [
      inputClasses.group_item,
      'group-item'
    ];

    let dateLabelBefore,
      dateLabelAfter,
      dateLabel = <label htmlFor={dateInputId}>{this.props.fields[1].label}</label>

    if ( !this.props.fields[1].label )
      dateLabel = <label className="empty-label"></label>

    if ( this.props.fields[1].labelBefore )
      dateLabelBefore = this.props.fields[1].labelBefore;
    
    if ( this.props.fields[1].labelAfter )
      dateLabelAfter = this.props.fields[1].labelAfter;


    let radioValue = '';
    if ( this.props.fields[0].value ) {
      radioValue = this.props.fields[0].value;
    }

    return (
      <div className={fieldClasses.join(' ')}>
        <div className={radioClasses.join(' ')} data-test="input-radio">
          <div className="radio_option" data-test="radio-option">
            <input 
              type="radio"
              id={radioInputId} 
              name={this.props.fields[0].name} 
              onChange={this.radioChanged} 
              value={radioValue} 
              data-block-id={this.props.blockId} 
              data-api-id={this.props.fields[0].api_id}
              defaultChecked={this.state.isChecked}
              disabled={this.props.disabled}
              ref={input => { this.inputElement = input }} />
            <label htmlFor={radioInputId}>{this.props.fields[0].label}</label>
          </div>
        </div>

        <div className="date-input">
          {dateLabelBefore}
          {dateLabel}
          <DatePicker
            id={dateInputId}
            selected={this.state.startDate}
            onChange={date => this.setDate(date, this.props.blockId, this.props.fields[1].api_id)}
            dateFormat="yyyy-MM-dd"
            className="input-date"
            disabled={this.props.disabled}
            />
          {dateLabelAfter}
        </div>
      </div>
    )
  }
}

export default InputRadioDate;