import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Col } from 'reactstrap';
import TemplatesList from '../TemplatesList/TemplatesList';
import TemplatesListDash from '../TemplatesList/TemplatesListDash';

import classes from '../Dashboard.module.scss';

class CreateDocumentationNote extends Component {
  state = {
    filtered: false,
    filterTerms: [],
    searchFilter: null,
    sort: 'r'
  }

  filterTemplateOwnerHandler = ( templateOwner ) =>  {
    //let filter = templateOwner.toLowerCase().trim().replace( ' ', '-' );
    let allTemplates = document.querySelectorAll('.template-list-item');

    //if (this.state.filterTemplates.length > 0) {
      allTemplates.forEach((node) => {
        node.style.display = 'none';
      })

      /*let templates = document.querySelectorAll('.' + filter)

      templates.forEach((node) => {
        node.style.display = 'flex'
      })*/
    /*} else {
      allTemplates.forEach((node) => {
        node.style.display = 'flex'
      })
    }*/
  }

  render() {
    let endResults = '',
        templateList = <TemplatesListDash 
                        templateOwnerHandler={this.filterTemplateOwnerHandler} />
    if ( window.location.pathname === '/create-note/' ) {
      templateList = <TemplatesList 
                        templates={this.state.templates} />
      endResults = <div className={classes.end_results}>
                    <Col xs="12" className={classes.end_results_text}><span>End of Results</span></Col>
                   </div>
    }

    return (
      <div className={classes.dashboard}>
        <div className={classes.dash_row}>
          <header>Create a Documentation Note</header>
        </div>
        
        <div className={[classes.templates_row].join(' ')}>
          <Col>{templateList}</Col>
        </div>
        {endResults}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
  };
};

export default connect(mapStateToProps)(CreateDocumentationNote);