import React, { Component } from 'react';

class InputText extends Component {
  state = {
    events: this.props.events,
    label: this.props.label,
    minLength: this.props.validation.min_length,
    maxLength: this.props.validation.max_length,
    required: this.props.validation.required,
    show: this.props.show,
    value: this.props.value
  }

  /*eventHandlerTargets = (targets, type) => {
    targets.forEach((target) => {
      if ( target.input_id ) {
        this.props.eventsChanged(type, target.input_id);
      } else if ( target.block_id ) {
        this.props.eventsChanged(type, 'block-' + target.block_id);
      }
    });
  }*/
  
  validateHandler = (event) => {
    const value = event.target.value;
    if ( this.state.required === true ) {
      if ( value === null || value === '' ) {
        event.target.classList.add('invalid');
      }
    }

    this.props.changed(event);
    
    if ( this.state.events.length > 0 ) {
      console.log(this.state.events);
      this.state.events.forEach( (event) => {
        const type = event.type;
        const triggers = event.triggers;
        
        switch ( type ) {
          case 'value_toggle_on':
          case 'value_toggle_off':
            if ( triggers.length > 0 ) {
              triggers.forEach((trigger) => {
                const value_eq = trigger.value;
                const value_lt = trigger.value_lt;
                const value_lte = trigger.value_lte;
                const value_gt = trigger.value_gt;
                const value_gte = trigger.value_gte;
  
                if ( value_eq ) {
                  console.log('eq')
                  if ( value === value_eq )
                    this.props.eventTargetHandler(event.targets, type);
                }
  
                if ( value_lt ) {
                  console.log('lt')
                  if ( value < value_lt )
                    this.props.eventTargetHandler(event.targets, type);
                }
  
                if ( value_lte ) {
                  console.log('lte')
                  if ( value <= value_lte )
                    this.props.eventTargetHandler(event.targets, type);
                }
  
                if ( value_gt ) {
                  console.log('gt')
                  if ( value > value_gt )
                    this.props.eventTargetHandler(event.targets, type);
                }
  
                if ( value_gte ) {
                  console.log('gte')
                  if ( value >= value_gte )
                    this.props.eventTargetHandler(event.targets, type);
                }
              });
            }
            break;
          case 'value_show':
          case 'value_hide':
            if ( value ) {
              this.props.eventTargetHandler(event.targets, 'value_show');
            } else {
              this.props.eventTargetHandler(event.targets, 'value_hide');
            }
            break;
          case 'calculate_number_total':
            if ( value )
              console.log(value);
            break;
          default:
        }
      });
      //this.props.eventTargetHandler(this.state.events);
    }
  }

  render() {
    //const inputId = this.props.blockId + '_' + this.props.apiId;
    const inputId = this.props.id;

    let labelBefore = '',
      labelAfter = '',
      classes = [
        'text-input',
        'input-field'
      ];

    if ( this.props.labelBefore ) {
      labelBefore = <span className="label-before">{this.props.labelBefore}</span>
    } else {
      classes.push('no-label-before');
    }
    
    if ( this.props.labelAfter ) {
      labelAfter = <span className="label-after">{this.props.labelAfter}</span>
    } else {
      classes.push('no-label-after');
    }
    
    let label = '';
    if ( this.props.label ) {
      if ( this.props.labelPosition === 'top' ) {
        label = <div className="top-label"><label htmlFor={this.props.name}>{this.state.label}</label></div>
      } else {
        label = <label htmlFor={inputId}>{this.state.label}</label>
      }
    }

    if (this.props.show) {
      return (
        <div className={classes.join(' ')}>
          {labelBefore}
          {label}
          <input
            id={inputId}
            name={this.props.name}
            type="text" 
            data-test="input-text"
            data-api-id={this.props.apiId}
            onChange={this.validateHandler}
            data-block-id={this.props.blockId}
            minLength={this.state.minLength}
            maxLength={this.state.maxLength}
            required={this.state.required}
            defaultValue={this.state.value}
            disabled={this.props.disabled}
            />
          {labelAfter}
        </div>
      )
    } else {
      return (<div></div>)
    }
  }
}

export default InputText;