import React, { Component } from 'react';

import { Row, Col } from 'reactstrap';

import CreateDocumentationNote from './Dashboard/CreateDocumentationNote/CreateDocumentationNote';

class CreateNote extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <CreateDocumentationNote />
          </Col>
        </Row>
      </div>
    )
  }
}

export default CreateNote;