import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import UserMenu from './UserMenu/UserMenu';
import UserPharmacy from './UserPharmacy/UserPharmacy';

class UserProfile extends Component {
  state = {
    showMenu: false
  }

  componentDidMount() {
    
  }

  menuHandler = () => {
    this.setState({
      showMenu: this.state.showMenu ? false : true
    })
  }

  closeMenu = (e) => {
    // click is outside
    this.handleClickOutside();
  }

  handleClickOutside = (e) => {
    if ( true === this.state.showMenu ) {
      this.setState({
        showMenu: false
      })
    }
  }

  render() {
    //console.log(this.props.user);
    return (
      <div className="user-block">
        <Row>
          <Col className="icon" xs="2">
            <div onClick={this.menuHandler}>
              <span className="icon-Profile"></span>
              <div className="tool-tip">Profile</div>
            </div>
          </Col>
          <Col xs="10">
            <div onClick={this.menuHandler}>
              <UserPharmacy />
              <div className="username">{this.props.username}</div>
            </div>
           
            <UserMenu show={this.state.showMenu} closeMenu={this.closeMenu} ref={this.userMenu} />
            
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    currentPharmacyName: state.currentPharmacyName,
    username: state.username
  };
};


export default connect(mapStateToProps)(UserProfile);