import React from 'react';

const bottomNavigation = (props) => {
  const menuClasses = [
    'sidebar-navigation',
    'bottom-navigation'
  ];

  return (
    <ul className={menuClasses.join(' ')}>
      <li><a href="https://support.qlinicalrx.com/" target="_blank" rel="noreferrer noopener">
        <span className="menu-icon icon-Help"></span> Help</a>
      </li>
      <li onClick={props.collapse}>
        <button><span className="menu-icon icon-Expand reverse"></span>Collapse</button>
      </li>
    </ul>
  )
}

export default bottomNavigation;