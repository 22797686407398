import React, { useEffect, useState, useRef } from 'react';

import InputCheckbox from '../Inputs/InputCheckbox'
import InputDate from '../Inputs/InputDate'
import InputDateTime from '../Inputs/InputDateTime'
import InputRadio from '../Inputs/InputRadio'
import InputText from '../Inputs/InputText'
import InputSelect from '../Inputs/InputSelect'
import InputGroup from '../Inputs/InputGroup'
import Text from '../Inputs/Text'
import InputTextMultiline from '../Inputs/InputTextMultiline'
import MedicationBlock from '../Inputs/MedicationBlock'

import classes from './Block.module.scss'

const block = (props) => {
  const toolTipRef = useRef();
  const [open, setOpen] = useState(false);

  let blockFields;
  let blockClasses = [
    classes.block
  ]

  const handleOpenToolTip = (e) => {
    const toolTip = e.target.parentNode.querySelector('.tool-tip');
    if ( toolTip ) {
      toolTip.classList.add('open');
      setOpen(true);
    }
  }

  const handleCloseToolTip = (e) => {
    const toolTip = e.target.parentNode.querySelector('.tool-tip');
    if ( toolTip ) {
      toolTip.classList.remove('open');
      setOpen(false);
    }
  }

  if ( props.fields ) {
    blockFields = props.fields.map((field, index) => {
      let fieldVar;
      let fieldKey = 'field-' + index;
      let show = true;

      switch(field.type) {
        case 'input_text':
          fieldVar = <InputText 
                        key={fieldKey}
                        id={field.id}
                        apiId={field.api_id} 
                        belongsToInputGroup={false}
                        blockId={props.blockId}
                        changed={props.textChanged}
                        className={fieldKey}
                        events={field.events}
                        eventsChanged={props.eventHandler}
                        eventTargetHandler={props.eventTargetHandler}
                        label={field.label}
                        labelAfter={field.label_after}
                        labelBefore={field.label_before}
                        labelPosition={props.labelPosition}
                        name={field.name} 
                        show={show}
                        validation={field.validation}
                        value={field.value}
                      />
          break;
        case 'text':
          fieldVar = <Text 
                        key={fieldKey}
                        id={field.id}  
                        className={fieldKey}
                        show={show}
                        value={field.value} />
                        
          break;
        case 'input_radio':
          fieldVar = <InputRadio
                        key={fieldKey}
                        id={field.id} 
                        apiId={field.api_id}
                        blockId={props.blockId}
                        changed={props.radioChanged}
                        checked={field.checked}
                        events={field.events}
                        eventsChanged={props.eventHandler}
                        label={field.label}
                        labelPosition={props.labelPosition}
                        name={field.name}
                        value={field.api_id} /> 

          break;
        case 'input_checkbox':
          fieldVar = <InputCheckbox
                        key={fieldKey}
                        id={field.id} 
                        apiId={field.api_id}
                        blockId={props.blockId}
                        changed={props.checkboxChanged}
                        checked={field.checked}
                        customTemplate={false}
                        events={field.events}
                        eventsChanged={props.eventHandler}
                        eventTargetHandler={props.eventTargetHandler}
                        label={field.label} 
                        labelBefore={field.label_before}
                        labelAfter={field.label_after}
                        labelPosition={props.labelPosition}
                        name={field.name}
                        value={field.api_id} />
          break;
        case 'input_select':
          fieldVar = <InputSelect 
                      key={fieldKey}
                      id={field.id}
                      apiId={field.api_id}
                      blockId={props.blockId}
                      changed={props.selectChanged}
                      label={field.label}
                      labelPosition={props.labelPosition}
                      options={field.options}
                      />
          break;
        case 'input_group':
          fieldVar = <InputGroup 
                        key={fieldKey}
                        id={field.id} 
                        blockId={props.blockId}
                        labelPosition={props.labelPosition}
                        fields={field.fields}
                        checkboxChanged={props.checkboxChanged}
                        removeCheckbox={props.removeCheckbox}
                        radioChanged={props.radioChanged}
                        textChanged={props.textChanged}
                        selectChanged={props.selectChanged}
                        dateChanged={props.dateChanged}
                        eventsChanged={props.eventHandler}
                        eventTargetHandler={props.eventTargetHandler} />
              
          break;
        case 'input_text_multiline':
          fieldVar = <InputTextMultiline 
                        key={fieldKey}
                        id={field.id} 
                        apiId={field.api_id} 
                        blockId={props.blockId}
                        changed={props.textChanged}
                        fields={field.fields}
                        labelPosition={props.labelPosition}
                        label={field.label} 
                        labelBefore={field.label_before}
                        labelAfter={field.label_after}
                        name={field.name}
                        value={field.value} />
          break;
        case 'input_date':
            fieldVar = <InputDate
                        key={fieldKey}
                        id={field.id} 
                        apiId={field.api_id} 
                        blockId={props.blockId}
                        events={field.events}
                        eventsChanged={props.dateChanged}
                        eventTargetHandler={props.eventTargetHandler}
                        fields={field.fields}
                        label={field.label} 
                        labelBefore={field.label_before}
                        labelAfter={field.label_after}
                        value={field.value} />
          break;
        case 'input_datetime':
          fieldVar = <InputDateTime
                      key={fieldKey}
                      id={field.id} 
                      apiId={field.api_id} 
                      blockId={props.blockId}
                      events={field.events}
                      eventsChanged={props.dateChanged}
                      eventTargetHandler={props.eventTargetHandler}
                      fields={field.fields}
                      label={field.label} 
                      labelBefore={field.label_before}
                      labelAfter={field.label_after}
                      value={field.value} />
          break;
        case 'medications':
          //console.log(field);
          fieldVar = <MedicationBlock 
                      key={fieldKey}
                      id={field.id} 
                      apiId={field.api_id} 
                      blockId={props.blockId}
                      attributes={field.attributes}
                      medicationChanged={props.medicationChanged}
                      labelPosition={props.labelPosition}
                      label={field.label} 
                      labelBefore={field.label_before}
                      labelAfter={field.label_after}
                      medication_list = {field.medication_list}
                      name={field.name}
                      value={field.value} />
          break;
        default:
          // do nothing
          break;
      }
      return fieldVar;
    });
  }

  switch (props.layout) {
    case 'h':
      blockClasses.push(classes.grid_h);
      blockClasses.push('grid_h');
      break
    case 'v':
      blockClasses.push(classes.grid_v);
      blockClasses.push('grid_v');
      break
    default:
  }

  if ( true === props.hide ) {
    blockClasses.push(classes.hide_block)
  }

  useEffect(() => {
    const blockToolTipEls = document.querySelectorAll('.icon-Info')
    blockToolTipEls.forEach((el) => {
      el.addEventListener( 'mouseover', handleOpenToolTip )
      el.addEventListener( 'mouseout', handleCloseToolTip )
    })
    
    return () => {
      blockToolTipEls.forEach((el) => {
        el.removeEventListener( 'mouseover', handleOpenToolTip )
        el.removeEventListener( 'mouseout', handleCloseToolTip )
      })
    }
  }, [])

  // info popup
  let infoTool = '';
  if ( props.cds[0] ) {
    infoTool = <div className={classes.tool_tip_container}><span className="icon-Info"></span><div className="tool-tip" ref={toolTipRef} dangerouslySetInnerHTML={{ __html: props.cds[0] }}></div></div>
  }

  let blockId = 'block-' + props.blockId

  return (
    <div id={blockId} className={blockClasses.join(' ')} data-test="component-block">
      <div className={classes.name}>
        {props.name}
        {infoTool}
      </div>
      {blockFields}
    </div>
  )
};

export default block;