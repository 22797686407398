import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const inputDateTime = (props) => {
  const [startDate, setStartDate] = useState(null);

  const setDate = (date, blockId, apiId) => {
    setStartDate(date);
    props.eventsChanged( getFormattedDate(date), blockId, apiId );

    if ( props.events ) {
      if ( props.events.length > 0 ) {
        props.events.forEach( (event) => {
          if ( date ) {
            props.eventTargetHandler(event.targets, 'value_show');
          } else {
            props.eventTargetHandler(event.targets, 'value_hide');
          }
        });
      }
    }
  }

  const getFormattedDate = (date) => {
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
  }

  useEffect(() => {
    if ( props.value ) {
      let dateObj = new Date(props.value);
      setStartDate(dateObj);
    }
  }, [])

  //const inputId = props.blockId + '_' + props.apiId;
  const inputId = props.id;

  let labelBefore,
      labelAfter,
      label = <label htmlFor={inputId}>{props.label}</label>

  if ( !props.label )
    label = <label className="empty-label"></label>

  if ( props.labelBefore )
    labelBefore = props.labelBefore;
  
  if ( props.labelAfter )
    labelAfter = props.labelAfter;

  let dateClasses = [
    'date-input',
    'input-field',
  ];

  return(
    <div className={dateClasses.join(' ')} data-test="input-datetime">
      {labelBefore}
      {label}
      <DatePicker
        id={inputId}
        selected={startDate}
        showTimeSelect
        onChange={date => setDate(date, props.blockId, props.apiId)}
        dateFormat="yyyy-MM-dd H:mm"
        className="input-date"
        disabled={props.disabled}
        />
      {labelAfter}
    </div>
  )
}

export default inputDateTime;