import React, { useState, useEffect } from 'react';

import templateClasses from '../Template.module.scss';
import classes from './Outline.module.scss';

const outline = (props) => {
  const [open, setOpen] = useState(true)
  //let last_known_scroll_position = 0
  //let ticking = false

  const outlineClasses = [
    classes.outline
  ]

  const iconClasses = [
    'icon-Triangle',
    'colour-teal', 
    templateClasses.icon_font
  ]

  if ( open )
    outlineClasses.push(classes.open)

  const outlineClickHandler = (e) => {
    removeOutlineSelectedClass();

    const parent = e.target.parentNode;
    parent.classList.add(classes.selected);
    const section = parent.getAttribute('data-section');
    console.log(section);
    document.getElementById('section-'+section).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  const removeOutlineSelectedClass = () => {
    const outlineItems = document.querySelectorAll('.outline-item');
    outlineItems.forEach((node) => {
      node.classList.remove(classes.selected)
    })
  }

  const menuHandler = (e) => {
    if ( !open ) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  useEffect(() => {
    const el = document.getElementById('outline-data');
    if ( el ) {
      removeOutlineSelectedClass();
      el.classList.add(classes.selected);
    }
  }, [])

  useEffect(() => {
    const el = document.getElementById('outline-' + props.currentSection);
    if ( el ) {
      removeOutlineSelectedClass();
      el.classList.add(classes.selected);
    }
  }, [props.currentSection])

  const outlineItemClasses = [
    'outline-item'
  ];

  if ( !open )
    iconClasses.push('reverse');

  let outlineSections = [],
      outlinePlanSections = [];

  if ( props.dataSection )
    outlineSections.push(<li key="outline-1" id="outline-data" data-section="data" className={outlineItemClasses.concat(classes.selected).join(' ')}><span onClick={outlineClickHandler}>Data</span></li>);
  
  if ( props.assessmentSection )
    outlineSections.push(<li key="outline-2" id="outline-assessment" data-section="assessment" className={outlineItemClasses.join(' ')}><span onClick={outlineClickHandler}>Assessment</span></li>);

  if ( props.planSection.monitoring.length > 0 || props.planSection.monitoring.length > 0 || props.planSection.follow.length > 0 ) {
    outlineSections.push(<li key="outline-0" className={outlineItemClasses.join(' ')}><span>Plan</span><ul>{outlinePlanSections}</ul></li>);
  }

  if ( props.planSection.intervention.length > 0 )
  outlinePlanSections.push(<li key="outline-3" id="outline-interventions" data-section="plan-interventions" className={outlineItemClasses.join(' ')}><span onClick={outlineClickHandler}>Interventions</span></li>);

  if ( props.planSection.monitoring.length > 0 )
  outlinePlanSections.push(<li key="outline-4" id="outline-monitoring" data-section="plan-monitoring" className={outlineItemClasses.join(' ')}><span onClick={outlineClickHandler}>Monitoring</span></li>);

  if ( props.planSection.follow.length > 0 )
  outlinePlanSections.push(<li key="outline-5" id="outline-follow-up" data-section="plan-follow-up" className={outlineItemClasses.join(' ')}><span onClick={outlineClickHandler}>Follow-Up</span></li>);

  if ( props.otherSection )
  outlineSections.push(<li key="outline-6" id="outline-other-notes-comments" data-section="other-notes-comments" className={outlineItemClasses.join(' ')}><span onClick={outlineClickHandler}>Other Notes & Comments</span></li>);


  return(
    <div className={templateClasses.widget_box} data-test="outline">
      <div className={templateClasses.widget_name}>
        Outline
        <span className={iconClasses.join(' ')} onClick={menuHandler}></span>
      </div>
      <ul className={outlineClasses.join(' ')}>
        {outlineSections}
      </ul>
    </div>
  )
}

export default outline;