import React from 'react'

import classes from './Notice.module.scss'

const notice = ({message, type, showIcon}) => {
  let iconClasses = [
    classes.icon_font
  ]

  let noticeClasses = [
    classes.notice
  ]

  if ( !message ) {
    noticeClasses.push(classes.hide)
  }

  if ( 'error' === type ) {
    noticeClasses.push(classes.error)
  } else {
    noticeClasses.push(classes.success)
  }

  var icon = '';
  if ( true === showIcon ) {
    if ( 'error' === type ) {
      iconClasses.push('icon-Circle_X');
    } else {
      iconClasses.push('icon-Circle_Checkmark');
    }

    icon = <div className={classes.col_left}><span className={iconClasses.join(' ')}><span className="path1"></span><span className="path2"></span><span className="path3"></span></span></div>;
  }

  return (
    <div className={noticeClasses.join(' ')}>
        {icon}
        <div xs="11" className={classes.col_right}>
          <div className={classes.message}>{message}</div>
        </div>
    </div>
  )
}

export default notice;