import React, { useEffect, useState, useRef } from 'react'

import { NavLink } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const copyRecoveryCode = (props) => {
  const node = useRef(null)
  const[open, setOpen] = useState(false)
  const[recoveryCode, setRecoveryCode] = useState(null)
  const[copied, setCopied] = useState(false)

  const closeModal = () => {
    setOpen(false)
    document.querySelector('#export-note-modal').style.display = ''
  }

  const copyRecoveryCode = () => {
    node.current.select()
    document.execCommand('copy')
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  useEffect(() => {
      if ( open ) {
        props.exportHandler()
        document.querySelector('#export-note-modal').style.display = 'none'
        
        setTimeout(function() {
          setRecoveryCode( node.current.value )
        }, 2000)
      }
  }, [open])

  let toolTipStyle = {}
  if (copied) {
    toolTipStyle = {
      display: 'block'
    }
  }

  return (
    <React.Fragment>
      <div onClick={e => setOpen(true)} className="btn export-btn">
        <span className="icon-Finish_Save"></span>
        Finish & Save
      </div>
      <Modal id="copy-recovery-modal" isOpen={open}>
        <Button className="close" onClick={closeModal}><span className="icon-X_CloseForm"></span></Button>
        <ModalHeader>Copy recovery code before closing note?</ModalHeader>
        <ModalBody>
          <p>If you don't copy your code, you won't be able to recover this note.</p>

          <div className="div-container">
            <label className="small-text-format small-header text-uppercase colour-teal" htmlFor="recovery-code">Note Recovery Code</label><br />
            <input ref={node} name="recovery-code" type="text" defaultValue={props.recoveryCode} readOnly />
            <div className="tool-tip" style={toolTipStyle}>Copied!</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer-container">
            <Button onClick={copyRecoveryCode} color="primary">Copy Code</Button>
            <div className="cancel-btn"><NavLink to="/" exact className="colour-teal">Go To Dashboard</NavLink></div>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default copyRecoveryCode