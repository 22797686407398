import React, { Component } from 'react';

import blockClasses from '../../Block/Block.module.scss'
import inputClasses from '../Inputs.module.scss';
import DatePicker from "react-datepicker";

class InputRadioTextDate extends Component {
  state = {
    isChecked: this.props.fields[0].checked,
    startDate: null,
    events: this.props.events,
  }

  eventHandler = ( event ) => {
    if ( event.target.checked ) {
      if ( this.state.events.length ) {
        for (let i=0; i< this.state.events.length; i++) {
          this.props.eventsChanged(this.state.events[i], 'radio', true)
        }
      }
    }
  }

  radioChanged = (e) => {
    const target = e.target;
    //this.eventHandler(target);
    this.props.radioChanged(target, 'group');
    this.setState({ isChecked: true })
  }

  textChanged = (event) => {
    if ( this.props.fields[1].validation.required === true ) {
      if ( event.target.value === null || event.target.value === '' )
        event.target.classList.add('invalid');
    }

    if ( event.target.value !== '' && this.state.isChecked === false ) {
      this.setState({ isChecked: true });
      this.inputElement.checked = true;
      this.props.radioChanged(this.inputElement);
    }

    this.props.textChanged(event);
  }

  setDate = (date, blockId, apiId) => {
    if ( this.state.isChecked === false ) {
      this.setState({
        isChecked: true,
        startDate: date
      });

      this.inputElement.checked = true;
      this.props.radioChanged(this.inputElement);
    }

    this.props.dateChanged(this.getFormattedDate(date), blockId, apiId);

    if ( this.props.events.length > 0 ) {
      console.log('Date Events');
      console.log(this.props.events);
      this.props.events.forEach( (event) => {
        if ( date ) {
          this.props.eventTargetHandler(event.targets, 'value_show');
        } else {
          this.props.eventTargetHandler(event.targets, 'value_hide');
        }
      });
    }
  }

  getFormattedDate = (date) => {
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
  }

  render() {
    let firstFieldKey = 0,
        secondFieldKey = 1,
        thirdFieldKey = 2;

    let radioClasses = [
      'radio-input',
      blockClasses.radio_field
    ];

    /*const inputId = this.props.blockId + '_' + this.props.fields[0].api_id;
    const textInputId = this.props.blockId + '_' + this.props.fields[1].api_id;
    const dateInputId = this.props.blockId + '_' + this.props.fields[2].api_id;*/

    const inputId = this.props.blockId + '_' + this.props.fields[firstFieldKey].api_id;
    const textInputId = this.props.blockId + '_' + this.props.fields[secondFieldKey].api_id;
    const dateInputId = this.props.blockId + '_' + this.props.fields[thirdFieldKey].api_id;

    let fieldClasses = [
      inputClasses.group_item,
      'group-item'
    ];

    let textLabelBefore = '',
        textLabelAfter = '',
        classes = [];

    if ( this.props.fields[1].labelBefore ) {
      textLabelBefore = <span className="label-before">{this.props.fields[1].labelBefore}</span>
    } else {
      classes.push('no-label-before');
    }
    
    if ( this.props.fields[1].labelAfter ) {
      textLabelAfter = <span className="label-after">{this.props.fields[1].labelAfter}</span>
    } else {
      classes.push('no-label-after');
    }
    
    let textLabel = '';
    if ( this.props.fields[1].label ) {
      if ( this.props.fields[1].labelPosition === 'top' ) {
        textLabel = <div className="top-label"><label htmlFor={this.props.fields[1].name}>{this.props.fields[1].label}</label></div>
      } else {
        textLabel = <label htmlFor={textInputId}>{this.props.fields[1].label}</label>
      }
    }

    let dateLabelBefore,
      dateLabelAfter,
      dateLabel = <label htmlFor={dateInputId}>{this.props.fields[2].label}</label>

    if ( !this.props.fields[2].label )
      dateLabel = <label className="empty-label"></label>

    if ( this.props.fields[2].labelBefore )
      dateLabelBefore = this.props.fields[1].labelBefore;
    
    if ( this.props.fields[2].labelAfter )
      dateLabelAfter = this.props.fields[1].labelAfter;

    return (
      <div className={fieldClasses.join(' ')}>
        <div className={radioClasses.join(' ')} data-test="input-radio">
          <div className="radio_option" data-test="radio-option">
            <input 
              type="radio"
              id={inputId} 
              name={this.props.fields[0].name} 
              onChange={this.radioChanged} 
              value={this.props.fields[0].value} 
              data-block-id={this.props.blockId} 
              data-api-id={this.props.fields[0].api_id} 
              defaultChecked={this.state.isChecked}
              disabled={this.props.disabled}
              ref={input => { this.inputElement = input }} />
            <label htmlFor={inputId}>{this.props.fields[0].label}</label>
          </div>
        </div>

        <div className="text-input">
          {textLabelBefore}
          {textLabel}
          <input
            id={textInputId}
            name={this.props.fields[1].name}
            type="text" 
            className={classes.join(' ')}
            data-test="input-text"
            data-api-id={this.props.fields[1].api_id}
            disabled={this.props.disabled}
            onChange={this.textChanged}
            data-block-id={this.props.blockId}
            minLength={this.props.fields[1].validation.min_length}
            maxLength={this.props.fields[1].validation.max_length}
            required={this.props.fields[1].validation.required}
            defaultValue={this.props.fields[1].value}
            />
          {textLabelAfter}
        </div>

        <div className="date-input">
          {dateLabelBefore}
          {dateLabel}
          <DatePicker
            id={dateInputId}
            selected={this.state.startDate}
            onChange={date => this.setDate(date, this.props.blockId, this.props.fields[2].api_id)}
            dateFormat="yyyy-MM-dd"
            className="input-date"
            disabled={this.props.disabled}
            />
          {dateLabelAfter}
        </div>
      </div>
    )
  }
}

export default InputRadioTextDate;