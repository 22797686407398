import React, { Component } from 'react';

import blockClasses from '../../Block/Block.module.scss'
import inputClasses from '../Inputs.module.scss';

class InputRadioText extends Component {
  state = {
    isChecked: this.props.fields[0].checked,
  }

  eventHandler = ( event ) => {
    
  }

  radioChanged = (e) => {
    const target = e.target;
    //this.eventHandler(target);
    this.props.radioChanged(target, 'group');
    this.setState({ isChecked: true });

    if ( this.props.fields[0].length > 0 ) {
      console.log(this.props.fields[0].length);
    }
  }

  textChanged = (event) => {
    if ( this.props.fields[1].validation.required === true ) {
      if ( event.target.value === null || event.target.value === '' )
        event.target.classList.add('invalid');
    }

    if ( event.target.value !== '' && this.state.isChecked === false ) {
      //this.setState({ isChecked: true })
      this.inputElement.checked = true;
      this.props.radioChanged(this.inputElement, 'group');
    } else if ( event.target.value === '' && this.state.isChecked === true ) {
      //this.setState({ isChecked: false })
      /*var self = this;
      setTimeout(function() {
        //self.props.removeCheckbox(self.inputElement);
      }, 1000)*/
    }

    console.log(this.props.fields[1]);

    if ( this.props.fields[1].events.length > 0 ) {
      console.log('InputRadioEvents');
      console.log(this.props.fields[1].events);
      //this.eventHandler();
    }

    // Testing Events
    /*if ( event.target.value > 60 ) {
      console.log('test')
      var eventFire = new MouseEvent('click', {
        'view': window, 
        'bubbles': true, 
        'cancelable': false
       });

      var node = document.getElementById('pamphlet__vascular__kidney__assessment__pharmacist_assessment__controlled_no');
      node.dispatchEvent(eventFire);
    }*/

    this.props.textChanged(event);
  }

  render() {
    let firstFieldKey = 0,
        secondFieldKey = 1;
        
    let radioClasses = [
      'radio-input',
      blockClasses.radio_field
    ];

    if ( this.state.isChecked ) {
      console.log('checked');
      radioClasses.push('radio-checked');
    }

    //const inputId = this.props.blockId + '_' + this.props.fields[0].api_id;
    //const textInputId = this.props.blockId + '_' + this.props.fields[1].api_id;

    const inputId = this.props.fields[firstFieldKey].id;
    const textInputId = this.props.fields[secondFieldKey].id;

    let fieldClasses = [
      inputClasses.group_item,
      'group-item'
    ];

    let textLabelBefore = '',
        textLabelAfter = '',
        classes = [];

    if ( this.props.fields[1].labelBefore ) {
      textLabelBefore = <span className="label-before">{this.props.fields[1].labelBefore}</span>
    } else {
      classes.push('no-label-before');
    }
    
    if ( this.props.fields[1].labelAfter ) {
      textLabelAfter = <span className="label-after">{this.props.fields[1].labelAfter}</span>
    } else {
      classes.push('no-label-after');
    }
    
    let textLabel = '';
    if ( this.props.fields[1].label ) {
      if ( this.props.fields[1].labelPosition === 'top' ) {
        textLabel = <div className="top-label"><label htmlFor={this.props.fields[1].name}>{this.props.fields[1].label}</label></div>
      } else {
        textLabel = <label htmlFor={textInputId}>{this.props.fields[1].label}</label>
      }
    }

    let radioValue = '';
    if ( this.props.fields[0].value ) {
      radioValue = this.props.fields[0].value;
    }

    return (
      <div className={fieldClasses.join(' ')}>
        <div className={radioClasses.join(' ')} data-test="input-radio">
          <div className="radio_option" data-test="radio-option">
            <input 
              type="radio"
              id={inputId} 
              name={this.props.fields[0].name} 
              onChange={this.radioChanged} 
              value={radioValue} 
              data-block-id={this.props.blockId} 
              data-api-id={this.props.fields[0].api_id} 
              defaultChecked={this.state.isChecked}
              disabled={this.props.disabled}
              ref={input => { this.inputElement = input }} />
            <label htmlFor={inputId}>{this.props.fields[0].label}</label>
          </div>
        </div>

        <div className="text-input">
          {textLabelBefore}
          {textLabel}
          <input
            id={textInputId}
            name={this.props.fields[1].name}
            type="text" 
            className={classes.join(' ')}
            data-test="input-text"
            data-api-id={this.props.fields[1].api_id}
            onChange={this.textChanged}
            data-block-id={this.props.blockId}
            disabled={this.props.disabled}
            minLength={this.props.fields[1].validation.min_length}
            maxLength={this.props.fields[1].validation.max_length}
            required={this.props.fields[1].validation.required}
            defaultValue={this.props.fields[1].value}
            />
          {textLabelAfter}
        </div>
      </div>
    )
  }
}

export default InputRadioText;