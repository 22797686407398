import React from 'react';
import Cookies from 'js-cookie';

import { NavLink } from 'react-router-dom';

import classes from './Widgets.module.scss';

const manageCustomTemplates = (props) => {

  let user_pharmacy_cookie = Cookies.get('user_pharmacy');
  let button_classes = ['btn', 'disabled', classes.widget_btn];
  let paid_content = <span className={['small-text', classes.widget_upgrade_message].join(' ')}>
    This feature is only available with a paid subscription. <a href={process.env.REACT_APP_ACCOUNT_URL + '/subscribe/upgrade/'} target="_blank" rel="noreferrer noopener">Upgrade your account.</a>
  </span>

  if ( user_pharmacy_cookie ) {
    let user_pharmacy = JSON.parse(user_pharmacy_cookie);
    if ( user_pharmacy.access.paid ) {
      button_classes = ['btn', classes.widget_btn];
      paid_content = '';
    }
  }

  return (
    <div className={props.widgetClass}>
      <div className={classes.widget_container}>
        <h2 className="main-header">Manage custom templates</h2>
        <p>Edit and manage your organization's templates and your own custom templates.</p>
        <NavLink className={button_classes.join(' ')} to="/custom" exact>Manage Custom Template</NavLink>
        {paid_content}
      </div>
    </div>
  )
}

export default manageCustomTemplates;