import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../../../utils/api';

class UserPharmacy extends Component {
  state = {
    currentPharmacyName: this.props.currentPharmacyName
  }

  componentDidMount() {
    if ( !this.props.currentPharmacyName ) {
      Api.fetch('/api/v1/pharmacies/save/').then((response) => {
        if ( response.pharmacy ) {
          this.setState({ currentPharmacyName: response.pharmacy.pharmacy_name });
        }
      });
    }
  }

  render() {
    const defaultLength = 13;

    let currentPharmacyName = this.state.currentPharmacyName;
    let pharmacyNameDiv = <div><span className="name">No Workspace</span><span className="icon-Triangle icon-small"></span></div>;

    if ( currentPharmacyName ) {
      if ( currentPharmacyName.length > defaultLength ) {
        //currentPharmacyName = this.state.currentPharmacyName.substr(0, defaultLength) + '...';
        currentPharmacyName = this.state.currentPharmacyName;
      }

      pharmacyNameDiv = <div className="pharmacy-name"><span className="name">{currentPharmacyName}</span><span className="icon-Triangle icon-small"></span></div>
    }

    return ( 
      <React.Fragment>{pharmacyNameDiv}</React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    currentPharmacyName: state.currentPharmacyName
  };
};

export default connect(mapStateToProps)(UserPharmacy);