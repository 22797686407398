import Api from '../../utils/api';
import * as actionTypes from './actionTypes';
import Cookies from 'js-cookie';

export const setCurrentPharmacy = (pharmacyId, pharmacyName, pharmacy) => {
  return {
    type: actionTypes.CHANGE_PHARMACY,
    pharmacyId: pharmacyId,
    pharmacyName: pharmacyName,
    workspace: pharmacy
  }
}

export const setNotice = (noticeType, notices) => {
  //console.log(notices)
  return {
    type: actionTypes.SET_NOTICE,
    noticeType: noticeType,
    notices: notices,
  }
}

export const logout = () => {
  return{
    type: actionTypes.LOGOUT
  }
}

export const getPharmacies = () => {
  Api.fetch('/api/v1/pharmacies/').then((response) => {
    //this.setState({ pharmacies: response });
    return response;
  });
}

export const savePharmacy = (pharmacyId, pharmacyName, pharmacy) => {
  const data = {
    pharmacy: pharmacyId
  }

  return dispatch => {
    console.log('savePharmacy');
    return Api.post('/api/v1/pharmacies/save/', data).then((response) => {
      dispatch(setCurrentPharmacy(pharmacyId, pharmacyName, pharmacy));
    }).catch((error) => {
      if ( 401 === error.status ) {
        console.log('savePharmacy: dispatch logout');
        dispatch(logout());
      }
      //dispatch(logout());
    })
  };
}

export const getSavedPharmacy = () => {
  console.log('getSavedPharmacy');
  return dispatch => {
    //if ( Cookies.get('refresh_token') !== 'undefined' && Cookies.get('refresh_token') !== undefined ) {
      return Api.fetch('/api/v1/pharmacies/save/').then((response) => {
        //console.log(response);
        let pharmacy = response.pharmacy;
        if ( pharmacy.id ) {
          console.log('set pharmacy')
          dispatch(setCurrentPharmacy(pharmacy.id, pharmacy.pharmacy_name, pharmacy));
          Cookies.set('user_pharmacy_id', pharmacy.id, {secure: true});
          Cookies.set('user_pharmacy', JSON.stringify(pharmacy), {secure: true});
        }
      }).catch((error) => {
        console.log('logout')
        if ( 401 === error.status ) {
          dispatch(logout());
        }
      })
  }
}

export const addPharmacy = (name, owner, address, city, province, timeZone, phoneNumber, faxNumber, textOutput, isOrganization) => {
  const data = {
    pharmacy_name: name,
    owner: owner,
    address: address,
    city: city,
    province: province,
    postal_code: '',
    country: 'ca',
    phone_number: phoneNumber,
    fax_number: faxNumber,
    time_zone: timeZone,
    is_organization: isOrganization
  }

  return dispatch => {
    return Api.post('/api/v1/pharmacies/', data)
    .then((response) => {
      //dispatch(setCurrentPharmacy(response.pharmacy, response.pharmacy_name));
      dispatch(setNotice('success', 'Pharmacy added'));
    }).catch((error) => {
      if ( 401 === error.status ) {
        console.log('Pharmacy Lists: dispatch logout');
        dispatch(logout());
      } else {
        const noticeErrors = error.data;
        dispatch(setNotice('error', noticeErrors));
      }
    });
  };
}

export const addAssociatedOrganization = (inviteCode) => {
  return dispatch => {
    return Api.fetch(`/api/v1/pharmacies/invite/${inviteCode}`).then((response) => {
      console.log(response);
      // add user to pharmacy
    })
  }
}

export const createAssociatedOrganizationInvite = (pharmacyId, email) => {
  const data = {
    pharmacy: pharmacyId,
    /*user_email: email,
    date_expiry: '2099-01-01T13:30:45',
    invite_type: 'email'*/
  }

  return dispatch => {
    return Api.post('/api/v1/pharmacies/invite/', data).then((response) => {
      console.log(response);
    })
  }
}