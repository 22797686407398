import React, { Component } from 'react';

import blockClasses from '../../Block/Block.module.scss';
import inputClasses from '../Inputs.module.scss';

class InputCheckboxText extends Component {
  state = {
    isChecked: false
  }

  componentDidMount() {
    //console.log(this.props.fields)
  }

  checkboxChanged = (e) => {
    console.log('checkbox event handler')
    const targetChecked = e.target.checked;
    this.props.checkboxChanged(e.target);

    if ( true === targetChecked ) {
      this.setState({ isChecked: true })
    } else {
      this.setState({ isChecked: false })
    }

    /*if ( this.state.events.length > 0 ) {
      for (let i=0; i< this.state.events.length; i++) {
        this.props.eventsChanged(this.state.events[i], 'checkbox', e.target.checked)
        //console.log(this.state.events)
      }
    }*/
  }

  textChanged = (event) => {
    //const target = event.target;

    if ( this.props.fields[1].validation.required === true ) {
      if ( event.target.value === null || event.target.value === '' )
        event.target.classList.add('invalid');
    }

    if ( event.target.value !== '' && this.state.isChecked === false ) {
      //console.log('check the checkbox')
      this.setState({ isChecked: true })
      this.props.checkboxChanged(this.inputElement);
    } else if ( event.target.value === '' && this.state.isChecked === true ) {
      this.setState({ isChecked: false })
      var that = this;
      setTimeout(function() {
        that.props.removeCheckbox(that.inputElement);
      }, 1000)
    }

    this.props.textChanged(event);
  }

  render() {
    let firstFieldKey = 0,
        secondFieldKey = 1;

    //const checkboxInputId = this.props.blockId + '_' + this.props.fields[firstFieldKey].api_id;
    //const textInputId = this.props.blockId + '_' + this.props.fields[secondFieldKey].api_id;

    const checkboxInputId = this.props.blockId + '_' + this.props.fields[firstFieldKey].api_id;
    const textInputId = this.props.blockId + '_' + this.props.fields[secondFieldKey].api_id;

    let fieldClasses = [
      inputClasses.group_item,
      'group-item'
    ];

    let checkboxLabelBefore,
        checkboxLabelAfter,
        checkboxLabel = <label htmlFor={checkboxInputId}>{this.props.fields[firstFieldKey].label}</label>

    if ( !this.props.fields[firstFieldKey].label ) {
      checkboxLabel = <label className="empty-label"></label>
    } else {
      fieldClasses.push('has-label');
    }

    if ( this.props.fields[firstFieldKey].labelBefore )
      checkboxLabelBefore = this.props.fields[firstFieldKey].labelBefore;
    
    if ( this.props.fields[firstFieldKey].labelAfter )
      checkboxLabelAfter = this.props.fields[firstFieldKey].labelAfter;

    let checkbox_classes = [
      'checkbox-input',
      blockClasses.checkbox_field
    ];

    let textLabelBefore = '',
        textLabelAfter = '',
        classes = [];

    if ( this.props.fields[secondFieldKey].label_before ) {
      textLabelBefore = <span className="label-before">{this.props.fields[secondFieldKey].label_before}</span>
    } else {
      classes.push('no-label-before');
    }
    
    if ( this.props.fields[secondFieldKey].label_after ) {
      textLabelAfter = <span className="label-after">{this.props.fields[secondFieldKey].label_after}</span>
      classes.push('has-label-after');
    } else {
      classes.push('no-label-after');
    }
    
    let textLabel = '';
    if ( this.props.fields[secondFieldKey].label ) {
      if ( this.props.fields[secondFieldKey].labelPosition === 'top' ) {
        textLabel = <div className="top-label"><label htmlFor={this.props.fields[secondFieldKey].name}>{this.props.fields[secondFieldKey].label}</label></div>
      } else {
        textLabel = <label htmlFor={textInputId}>{this.props.fields[secondFieldKey].label}</label>
      }
    }

    return (
      <div className={fieldClasses.join(' ')}>
        <div className={checkbox_classes.join(' ')} data-test="input-checkbox">
          {checkboxLabelBefore}
          <input 
            type="checkbox" 
            id={checkboxInputId}
            name={this.props.fields[firstFieldKey].name} 
            onChange={this.checkboxChanged} 
            data-test="checkbox-option" 
            value={this.props.fields[firstFieldKey].api_id}
            data-block-id={this.props.blockId} 
            data-api-id={this.props.fields[firstFieldKey].api_id}
            disabled={this.props.disabled}
            checked={this.state.isChecked}
            ref={input => { this.inputElement = input }}
            />
          {checkboxLabel}
          {checkboxLabelAfter}
        </div>

        <div className="text-input">
          {textLabelBefore}
          {textLabel}
          <input
            id={textInputId}
            name={this.props.fields[secondFieldKey].name}
            type="text" 
            className={classes.join(' ')}
            data-test="input-text"
            data-api-id={this.props.fields[secondFieldKey].api_id}
            onChange={this.textChanged}
            data-block-id={this.props.blockId}
            disabled={this.props.disabled}
            minLength={this.props.fields[secondFieldKey].validation.min_length}
            maxLength={this.props.fields[secondFieldKey].validation.max_length}
            required={this.props.fields[secondFieldKey].validation.required}
            defaultValue={this.props.fields[secondFieldKey].value}
            />
          {textLabelAfter}
        </div>
      </div>
    )
  }
};

export default InputCheckboxText;