import Api from '../../utils/api';
import * as actionTypes from './actionTypes';

export const setErrorMessage = (errors) => {
  return{
    type: actionTypes.SET_ERROR,
    errors: errors
  }
}

export const setSearchFilters = (filters) => {
  return{
    type: actionTypes.SET_SEARCH_FILTERS,
    searchFilters: filters
  }
}

export const setTemplates = (templates) => {
  return{
    type: actionTypes.SET_TEMPLATES,
    templates: templates
  }
}

export const logout = () => {
  return{
    type: actionTypes.LOGOUT
  }
}

export const getTemplate = (templateId, workspaceId) => {
  return dispatch => {
    return Api.fetch(`/api/v1/tools/templates/${templateId}/?pharmacy=${workspaceId}`).then( (response) => {
      return response;
    }).catch((error) => {
      console.log('hit here');
      dispatch(setErrorMessage('500 FOUND'));
    });
  }
}

export const openSavedNote = (recoveryCode, pharmacyId) => {
  return dispatch => {
    return Api.fetch(`/api/v1/tools/recovery/${recoveryCode}/?pharmacy=${pharmacyId}`).then( (response) => {
      console.log(response.template_id);
      return response.template_id;
    }).catch((error) => {
      console.log(error.data.errors);
      dispatch(setErrorMessage('NOT FOUND'));
    });
  }
}

export const getFilters = () => {
  return dispatch => {
    Api.fetch(`/api/v1/tools/templates/filters/`).then((response) => {
      //console.log(response);
      const templateOptions = response.map((value) => {
        return { val: value.id, name: value.display }
      });
      dispatch(setSearchFilters(templateOptions));
    }).catch((error) => {
      console.log('Filter Error: ' + error.status);
      if ( 401 === error.status ) {
        console.log('Filters: dispatch logout');
        dispatch(logout());
      }
    });
  }
}