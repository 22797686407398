import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

import classes from '../Template.module.scss';

const messageBox = ({message}) => {
  const[open, setOpen] = useState(true);

  const messageBoxClasses = [
    classes.message_box
  ]

  if ( open ) {
    messageBoxClasses.push(classes.message_box_open)
  }

  const handleCloseHandler = e => {
    console.log(open);
    setOpen(false);
  };

  return (
    <Row>
      <Col xs="12">
        <div className={messageBoxClasses.join(' ')}>
          <div className={classes.message_box_container}>
            <div className={classes.message_box_message}>{message}</div>
            <div className={classes.message_box_close} onClick={handleCloseHandler}>Close Message</div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default messageBox;