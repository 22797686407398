import React from 'react';

const filterList = ({ options, click }) => (
  <ul>
    {options.map((optObj, index) => (
      <li key={'filter-'+index} onClick={click} data-filter={optObj.val}>{optObj.name}</li>
    ))}
  </ul>
)

export default filterList;