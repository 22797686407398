import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Notice from '../../../layout/UI/Notice';

import classes from './Widgets.module.scss';

class SavedNote extends Component {
  state = {
    errors: null,
    recoveryCode: null,
    redirect: false,
    redirectTo: null
  }

  retrieveSavedNote = () => {
    if ( null !== this.state ) {
      this.setState({ 
        redirect: true,
        recoveryCode: this.state.recoveryCode
      })
    }
  }

  handleRecoveryCode = (event) => {
    this.setState({ recoveryCode: event.target.value })
  }

  render() {
    let redirect;
    if ( this.state.redirect ) {
      let link = 'create-note/' + this.state.recoveryCode
      redirect = <Redirect to={link} />
    }

    return (
      <React.Fragment>
        {redirect}
        <div className={this.props.widgetClass}>
          <div className={classes.widget_container}>
            <h2 className="main-header">Open saved note</h2>
            <p>Enter your recovery code to recover an existing note. Your note recovery code is on any of the note outputs you may have generated.</p>
            <Notice
              message={this.state.errors}
              type="error"
              showIcon={true}
            />
            <label className="small-text-format small-header text-uppercase colour-teal" htmlFor="recovery-code">Note Recovery Code</label><br />
            <input name="recovery-code" type="text" onChange={this.handleRecoveryCode} /><br />
            <button onClick={this.retrieveSavedNote}>Open Saved</button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    currentPharmacyName: state.currentPharmacyName,
    userId: state.userId
  };
};

export default connect(mapStateToProps)(SavedNote);