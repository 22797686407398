import React, { Component } from 'react';

import { connect } from 'react-redux';
//import * as actions from '../store/actions/index';

import { Row, Col } from 'reactstrap';
import Equalizer from 'react-equalizer';

import CreateDocumentationNote from './Dashboard/CreateDocumentationNote/CreateDocumentationNote';
import SavedNote from './Dashboard/Widgets/SavedNote';
import CreateDocumentationNoteWidget from './Dashboard/Widgets/CreateDocumentationNoteWidget';
import CreateCustomTemplate from './Dashboard/Widgets/CreateCustomTemplate';
import ManageCustomTemplates from './Dashboard/Widgets/ManageCustomTemplates';
import ChooseWorkspace from './Dashboard/Modals/ChooseWorkspace';

import classes from './Dashboard/Dashboard.module.scss';

class Dashboard extends Component {
  state = {
    documentationScreen: false,
  }

  constructor(props) {
    super(props);
    this.sidebarNode = React.createRef();
  }

  setSidebarHeight = () => {
    this.sidebarNode.current.style.height = (this.sidebarNode.current.childNodes[0].clientHeight) + 'px';
  }

  handleDocumentationNotesHandler = ( dashboard_classes ) => {
    this.setState({
      documentationScreen: this.state.documentationScreen ? false : true
    })
  }

  render() {
    const dashboard_classes = [
      classes.dashboard_container,
      classes.dashboard_templates
    ]

    const styleStr = {
      height: window.innerHeight + 'px',
      'overflowY': 'scroll'
    }

    let choose_workspace_modal = '';
    if ( this.props.workspace === null ) {
      choose_workspace_modal = <ChooseWorkspace />
    }

    if ( this.state.documentationScreen )
      dashboard_classes.push( classes.dashboard_templates_open )

    return (
      <React.Fragment>
        {choose_workspace_modal}
        
        <Row>
          <Col xl="8" className={dashboard_classes.join(' ')}>
            <CreateDocumentationNote />
          </Col>
          <Col xs="12" xl="4" className={classes.dashboard_container}>
            <Equalizer byRow={false} className={classes.widget_container} style={styleStr}>
              <CreateDocumentationNoteWidget widgetClass={classes.dashboard_widget} click={this.handleDocumentationNotesHandler} />
              <SavedNote widgetClass={classes.dashboard_widget} />
              <CreateCustomTemplate widgetClass={classes.dashboard_widget} />
              <ManageCustomTemplates widgetClass={classes.dashboard_widget} />
            </Equalizer>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    currentPharmacyName: state.currentPharmacyName,
    workspace: state.workspace
  };
};

export default connect(mapStateToProps)(Dashboard);