import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import Api from '../../../utils/api';

import { Row, Col } from 'reactstrap';
import CustomTemplatesListItem from './CustomTemplatesListItem';

import classes from '../../Dashboard/TemplatesList/TemplateList.module.scss';
import customTemplatesClasses from './CustomTemplatesList.module.scss';

/**
 * Loads templates for main Dashboard Page
 */
class CustomTemplatesList extends Component {
  state = {
    templates: [],
    currentPharmacy: null,
    start: 0,
    end: 9,
    increment: 10
  }

  /**
   * On mount - Initial load
   */
  componentDidMount() {
    // Get templates
    this.getTemplates();

    // Scroll Function for infinite scroll
    let self = this;
    window.onscroll = function() {
      var scrollHeight, totalHeight;
      scrollHeight = document.body.scrollHeight;
      totalHeight = window.scrollY + window.innerHeight;
  
      if(totalHeight >= scrollHeight) {
        // set a new end var
        self.setState((prevState) => {
          return {
            end: prevState.end + prevState.increment
          }
        })

        // fetch new templates with new end var
        self.getTemplates();
      }
    }
  }

  /**
   * Check that components have updated and customize how to handle them
   * 
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props.templates !== prevProps.templates) {
      this.setState({ templates: this.props.templates });
    }
  }


  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.currentPharmacy !== this.state.currentPharmacy)
      return true;

    return true;
  }

  /**
   * Retrieve QlilnicalRx templates from API
   */
  getTemplates = () => {
    Api.fetch('/api/v1/pharmacies/save/').then((response) => {
      if ( response.pharmacy ) {
        Api.fetch(`/api/v1/tools/templates/?pharmacy=${response.pharmacy.id}&custom=${true}&start=${this.state.start}&end=${this.state.end}`).then((response) => {
          console.log(response);
          if ( response.length > 0 ) {
            this.setState({ 
              templates: response,
            });
          }
        }).catch((error) => {
          console.log('Custom Templates Error: ' + error.status);
          this.props.logout();
        })
      }
    });
  }

  /**
   * Output our page
   */
  render() {
    let templateHeader = '';
    let templateItems = <div className={classes.template_no_results}>Please choose a workspace to get started. If you do not have a workspace, you will need to create a workspace to use QlinicalRx. <a href={process.env.REACT_APP_ACCOUNT_URL} target="_blank" rel="noopener noreferrer">Create a workspace now.</a></div>;

    if ( this.state.templates.length > 0 ) {
      templateItems = this.state.templates.map((template, cindex) => {
        return <CustomTemplatesListItem 
          key={cindex}
          id={template.id}
          title={template.title}
          owner={template.owner}
          creator={template.creator}
          dateCreated={template.datetime_created}
          lastChanged={template.datetime_modified}
          paperFormat={template.flag_paper}
          filters={template.filters}
          />
      });

      templateHeader = <Row data-test="component-list-item">
                          <Col className={[classes.template_header, customTemplatesClasses.header_check].join(' ')}><input type="checkbox" name="selectedTemapltes" /></Col>
                          <Col className={[classes.template_header, customTemplatesClasses.header_templates].join(' ')}>Templates</Col>
                          <Col className={[classes.template_header, customTemplatesClasses.header_owner].join(' ')}>Template Owner</Col>
                          <Col className={[classes.template_header, customTemplatesClasses.header_creator].join(' ')}>Creator</Col>
                          <Col className={[classes.template_header, customTemplatesClasses.header_date].join(' ')}>Date Created</Col>
                          <Col className={[classes.template_header, customTemplatesClasses.header_date].join(' ')}>Last Changed</Col>
                          <Col className={[classes.template_header, customTemplatesClasses.header_btn].join(' ')}><span>Edit & Use</span></Col>
                          <Col></Col>
                        </Row>
    }

    return (
      <div data-test="component-templates">
        {templateHeader}
        {templateItems}
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    token: state.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch( actions.logout() )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTemplatesList);