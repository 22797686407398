import React, { useState, useEffect } from 'react';

import Select from 'react-select';

import classes from './Inputs.module.scss';

const inputSelect = (props) => {
  const[value, setValue] = useState(null);

  //const inputId = props.blockId + '_' + props.apiId;
  const inputId = props.id;

  let selectClasses = [
    classes.select_input,
    'input-field',
    'select-input'
  ];

  /*const options = props.options.map((option, index) => {
    const optionKey = "option-" + index;
    return <option key={optionKey} data-test="select-option" value={option.value} selected={props.options.selected}>
             {option.value}
           </option>
  });*/

  let label = <label htmlFor={inputId}>{props.label}</label>
  if (!props.label) {
    label = ''
    selectClasses.push(classes.full_width)
  }

  const options = props.options.map((option) => {
    return { value: option.value, label: option.value }
  })

  const valueHandler = (selectedOption) => {
    setValue(selectedOption);
    props.changed(selectedOption, props.apiId, props.blockId);

    if ( props.parentChanged ) {
      props.parentChanged()
    }
  }

  useEffect(() => {
    setValue(options[0])
  }, [])

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? '#29BBB3'
          : isFocused
          ? '#29BBB3'
          : null,
        color: isDisabled
          ? '#42515A'
          : isSelected
          ? 'white'
          : '#42515A',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':hover': {
          ...styles[':hover'],
          color: 'white'
        },
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? '#29BBB3' : '#29BBB3'),
        },
      };
    }
  }

  return (
    <div className={selectClasses.join(' ')} data-test="component-select">
      {label}
      <Select
        id={inputId}
        data-test="input-select"
        value={value}
        className="select_input"
        isDisabled={props.disabled}
        options={options}
        onChange={valueHandler}
        styles={colourStyles}
      />
    </div>
  )
};

export default inputSelect;