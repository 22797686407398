import React, {useEffect, useState} from 'react';

import { NavLink, useHistory } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import ExportNoteModal from '../../pages/Template/Modals/ExportNoteModal'
import SaveNewTemplateModal from '../../pages/Template/Modals/SaveNewTemplateModal'

import classes from './PageHeader.module.scss';

const pageHeader = ( props ) => {
  let [style, setStyle] = useState(null);
  let [ticking, setTicking] = useState(false);
  const history = useHistory();

  let sidebarWidth = 250;

  const goBackTo = () => {
    history.goBack();
  }

  const collapseAll = () => {

  }

  useEffect(() => {
    //sidebarWidth = document.getElementById('sidebar').clientWidth
    updateStyle()

    window.addEventListener('resize', function() {
      if ( !ticking ) {
        window.requestAnimationFrame(function() {
          updateStyle()
          setTicking(false)
        });
        
        setTicking(true)
      } 
    })
  }, [])

  const updateStyle = () => {
    if ( window.innerWidth < 1200 ) {
      if ( document.querySelector('.sidebar_container.collapsed_menu') )
        sidebarWidth = document.querySelector('.sidebar_container.collapsed_menu').clientWidth
    } else {
      if ( document.querySelector('.sidebar_container') )
        sidebarWidth = document.querySelector('.sidebar_container').clientWidth
    }

    style = {
      width: window.innerWidth-sidebarWidth-45 + 'px'
    }

    setStyle(style)
  }

  return (
    <div id="page-header" className={classes.page_header} style={style}>
      <Row>
        <Col className={classes.header_left} sm="11" lg="5" xl="6">
          <div className={classes.back_btn} onClick={goBackTo} style={{cursor:'pointer'}}>
            <span className="icon-Back-Arrow reverse colour-teal"></span>
          </div>
          <div className={classes.page_title}>
            <div className={classes.template_name}>{props.name}</div>
            <div className={classes.pharmacy_name}>{props.pharmacy}</div>
          </div>
          
        </Col>
        <Col className={classes.header_right} sm="1" lg="7" xl="6">
          <div className={classes.header_menu}><span className="icon-DotMenu colour-teal"></span></div>
          <div className={classes.ui_btns}>
            <div className={classes.text_link}>Undo</div>
            <div className={classes.text_link}>Collapse All</div>
            <button onClick={e => props.save()}>Update</button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default pageHeader;