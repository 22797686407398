import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../../utils/api';
import Cookies from 'js-cookie';
import * as actions from '../../../store/actions/index';

import { Row, Col } from 'reactstrap';
import Search from '../Search/Search';
import TemplateListItem from './TemplatesListItem/TemplatesListItem';

import classes from './TemplateList.module.scss';

/**
 * Loads templates for main Dashboard Page
 */
class TemplatesListDash extends Component {
  state = {
    templates: [],
    filtered: false,
    filterTerms: [],
    searchFilter: null,
    start: 0,
    end: 9,
    query: null,
    sort: 'r',
    increment: 10,
    workspaceId: Cookies.get('user_pharmacy_id')
  }

  constructor() {
    super();
    this._isMounted = false;
  }

  /**
   * On mount - Initial load
   */
  componentDidMount() {
    this._isMounted = true;
    console.log('Templates Dash')
    // Get templates
    //console.log(jwt_decode(Cookies.get('user_token')))
    if ( this.state.workspaceId ) {
      this.getTemplates(this.state.workspaceId);

      // Scroll Function for infinite scroll
      let self = this;
      window.onscroll = function() {
        var scrollHeight, totalHeight;
        scrollHeight = document.body.scrollHeight;
        totalHeight = window.scrollY + window.innerHeight;
    
        if( totalHeight >= scrollHeight ) {
          // set a new end var
          self.setState((prevState) => {
            return {
              end: prevState.end + prevState.increment
            }
          })

          // fetch new templates with new end var
          self.getTemplates(self.state.workspaceId);
        }

        // TODO: CHECK MAX RESULTS BEFORE RUNNING
      }
    } else {
      Api.fetch('/api/v1/pharmacies/save/').then((response) => {
        if ( response.pharmacy ) {
          this.setState({
            workspaceId: response.pharmacy.id,
            currentPharmacy: response.pharmacy
          });
        
          this.getTemplates(response.pharmacy.id);
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.templates !== prevProps.templates) {
      this.setState({ templates: this.props.templates });
    }

    if (this.state.workspace !== prevProps.workspace) {
      //this.getTemplates(this.state.workspace.id);
    }
  }

  /*shouldComponentUpdate(nextProps, nextState) {
    if ( nextProps.currentPharmacy !== this.state.currentPharmacy )
      return true;

    return true;
  }*/

  /**
   * Retrieve QlilnicalRx templates from API
   */
  getTemplates = (workspaceId) => {
    let url = this.buildQuery(workspaceId);
    console.log(url);

    Api.fetch(url).then((response) => {
      if ( response.length > 0 && this._isMounted ) {
        this.setState({
          templates: response,
        });
      } else {
        this.setState({
          templates: []
        });
      }
    });
  }

  buildQuery = (workspaceId) => {
    let apiQuery = '/api/v1/tools/templates/?pharmacy='+workspaceId+'&start='+this.state.start+'&end='+this.state.end;

    if ( this.state.query ) {
      apiQuery += '&q=' + this.state.query;
    }

    if ( this.state.sort ) {
      apiQuery += '&sort=' + this.state.sort;
    }

    return apiQuery;
  }

  searchHandler = (search) => {
    this.setState({ 
      query: search
    });

    let self = this;
    setTimeout(function() {
      self.getTemplates(self.state.workspaceId);
    }, 500)
  }

  sortHandler = (sort) => {
    this.setState({ 
      sort: sort
    });

    let self = this;
    setTimeout(function() {
      self.getTemplates(self.state.workspaceId);
    }, 500)
  }

  /**
   * Output our page
   */
  render() {
    let templateHeader = '';
    let templateItems = <div className={classes.template_no_results}>Please choose a workspace to get started. If you do not have a workspace, you will need to create a workspace to use QlinicalRx. <a href={process.env.REACT_APP_ACCOUNT_URL} target="_blank" rel="noopener noreferrer">Create a workspace now.</a></div>;
    
    if ( this.state.templates.length > 0 ) {
      templateItems = this.state.templates.map((template, cindex) => {
        return <TemplateListItem 
          key={cindex}
          id={template.id}
          title={template.title}
          owner={template.owner}
          paperFormat={template.flag_paper}
          filters={template.filters}
          filterOwner={this.props.templateOwnerHandler}
          />
      });

      templateHeader = <Row className={classes.template_row_header} data-test="component-list-item">
                          <Col className={[classes.header_templates, classes.template_header].join(' ')}>Templates</Col>
                          <Col className={[classes.template_header, classes.header_format].join(' ')}>Format</Col>
                          <Col className={[classes.template_header, classes.header_btn].join(' ')}><span>Edit & Use</span></Col>
                        </Row>
    }

    return (
      <React.Fragment>
        <div>
          <Search 
            search={this.searchHandler} 
            sort={this.sortHandler}
            searchFilter={this.state.searchFilter}
            sortFilter={this.state.sort}
          />
        </div>
        <div data-test="component-templates">
          {templateHeader}
          {templateItems}
        </div>
      </React.Fragment>
    );
    
  };
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    workspace: state.workspace
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshAccessToken: () => dispatch( actions.refreshAccessToken() ),
    setPharmacy: () => dispatch( actions.getSavedPharmacy() )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesListDash);