import * as actionTypes from './actions/actionTypes';
import * as jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

let user_token = Cookies.get('user_token');
let user_pharmacy = Cookies.get('user_pharmacy');

let initialState = {
  currentPharmacy: '',
  currentPharmacyName: '',
  username: null,
  userId: null,
  isAuthenticated: false,
  notices: null,
  workspace: false
};

if ( user_token && user_token !== 'undefined' ) {
  let user = jwt_decode(user_token);
  initialState = {
    currentPharmacy: '',
    currentPharmacyName: '',
    isAuthenticated: true, 
    username: user.user_name,
    userId: user.id,
    notices: null,
    workspace: user_pharmacy
  }
} else {
  console.log('missing access token');
}

const reducer = (state = initialState, action) => {
  if (actionTypes.CHANGE_PHARMACY === action.type) {
    //console.log(action)
    return {
      ...state,
      workspace: action.workspace,
      currentPharmacy: action.pharmacyId,
      currentPharmacyName: action.pharmacyName
    }
  }

  if (actionTypes.SET_USER === action.type) {
    if (action.user) {
      //console.log('set user')

      return {
        ...state,
        isAuthenticated: true,
        pharmacies: action.user.pharmacies,
        username: action.user.user_name,
        userId: action.user.id,
      }
    } /*else {
      return {
        ...state,
        isAuthenticated: false,
        pharmacies: [],
        username: null,
        userId: null,
        isLoading: false
      }
    }*/
  }

  if (actionTypes.SET_NOTICE === action.type) {
    //console.log(action.notices);
    return {
      ...state,
      notices: {
        show: true,
        type: action.noticeType,
        notices: action.notices
      }
    }
  }

  if (actionTypes.SET_ERROR === action.type) {
    return {
      ...state,
      errors: action.errors
    }
  }

  if (actionTypes.SET_SEARCH_FILTERS === action.type) {
    return {
      ...state,
      searchFilters: action.searchFilters
    }
  }

  if (actionTypes.LOGOUT === action.type) {
    Cookies.remove('user_token');
    Cookies.remove('refresh_token');

    return {
      ...state,
      currentPharmacy: null,
      currentPharmacyName: null,
      isAuthenticated: false,
      user: null,
      workspace: null,
    }
  }

  return state;
};

export default reducer;