import React, { Component } from 'react';

import Api from '../../../utils/api';
import { Row, Col } from 'reactstrap';

import Filter from '../../Dashboard/Search/Filters/Filter';

import templateClasses from '../Template.module.scss';
import classes from './PdfSettingsOutline.module.scss';

class PdfSettingsOutline extends Component {
  state = {
    open: true,
    filterSettings: [],
    filterTerms: [],
    provincialFilters: [],
    searchTerm: '',
    sortTerm: 'r',
    showProvincialFilters: false,
    resultsCount: 0
  }

  constructor() {
    super();
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    Api.fetch(`/api/v1/tools/administrative/filters/`).then((response) => {
      if ( this._isMounted ) {
        const provincialFilters = response.map((value) => {
          return { val: value.id, name: value.display }
        })

        this.setState({ 
          provincialFilters: provincialFilters,
        });

        this.updateSettingsCount();
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  searchHandler = (e) => {
    let searchTerm = e.target.value;
    this.setState({ 
      searchTerm: searchTerm,
      showProvincialFilters: true
    })
    this.props.searchHandler(searchTerm, this.state.sortTerm);
  }

  sortHandler = (e) => {
    const sortTerm = e.target.getAttribute('data-filter');
    const restofLi = e.target.parentNode.querySelectorAll('li');

    for ( let i=0; i<restofLi.length; i++ ) {
      restofLi[i].classList.remove(classes.selected);
    }
    e.target.classList.add(classes.selected);

    this.setState({ sortTerm: sortTerm });
    this.props.searchHandler(this.state.searchTerm, sortTerm);
  }

  menuHandler = (e) => {
    this.setState((prevState) => { 
      return { open: !prevState.open }
    })
  }

  doFilterSettings = () => {
    let allTemplates = document.querySelectorAll('.setting-list-item');

    if (this.state.filterSettings.length > 0) {
      allTemplates.forEach((node) => {
        node.style.display = 'none'
      })

      this.state.filterSettings.forEach((filter) => {
        let templates = document.querySelectorAll('.' + filter)

        templates.forEach((node) => {
          node.style.display = 'block'
        })
      })
    } else {
      allTemplates.forEach((node) => {
        node.style.display = 'block'
      })
    }
  }

  settingsFilterHandler = (event) => {
    const target = event.target;
    const filterType = target.getAttribute('data-filter')
    const filterName = target.getAttribute('data-filter-name')
    
    if ( true === target.checked ) {
      if ( filterType ) {
        this.state.filterSettings.push(filterType)

        this.doFilterSettings()

        let item = [{
          id: filterType,
          name: filterName
        }]

        this.setState((prevState) => {
          let terms = prevState.filterTerms.concat(item)
          return { filterTerms: terms }
        });
        
      }
    } else {
      const indexOf = this.state.filterSettings.indexOf(filterType)
      if (indexOf >= 0) {
        this.state.filterSettings.splice(indexOf, indexOf+1)

        this.doFilterSettings()
        this.removeFilterTerm(filterType)
      }
    }

    this.updateSettingsCount();
  }

  removeFilterTerm = (filterType) => {
    const check = this.state.filterTerms.findIndex( function(term) {
      return term.id === filterType
    })

    if ( check > -1 ) {
      this.setState((prevState) => {
        const filteredItems = prevState.filterTerms.slice(0, check).concat(prevState.filterTerms.slice(check + 1, prevState.filterTerms.length))
        return { filterTerms: filteredItems }
      })
    }
  }

  updateSettingsCount = () => {
    let currentResultsCount = 0;
    const templates = document.querySelectorAll('.setting-list-item')
    for (var i = 0, max = templates.length; i < max; i++) {
      var style = window.getComputedStyle(templates[i]);
      if (style.display === 'none' || style.visibility === 'hidden') {
        // do nothing
      } else { 
        currentResultsCount++
      }
    }

    this.setState({ resultsCount: currentResultsCount })
  }

  render() {
    const pdfSettingsClasses = [
      classes.pdf_settings,
      'pdf-settings-outline'
    ]

    const templateListItemClasses = [
      classes.setting_item,
      'setting-list-item'
    ]

    const iconClasses = [
      'icon-Triangle', 
      'colour-teal', 
      templateClasses.icon_font
    ]
    
    if ( this.state.open ) {
      pdfSettingsClasses.push(classes.open)
      iconClasses.push('reverse')
    }

    let provincialFilters = ''
    if ( this.state.showProvincialFilters ) {
      provincialFilters = <Filter
                            name="Provincial Forms" 
                            options={this.state.provincialFilters}
                            icon="icon-Filter"
                            click={this.settingsFilterHandler}
                            type="checkbox"
                            customClass="template_filter pdf-outline-filter"
                          />
    }

    const pdfSettingsList = this.props.settings.map((setting, key) => {
      let noteText = 'Add to Note'
      this.props.currentSettings.forEach((selectedSetting) => {
        if ( selectedSetting.id === setting.id ) {
          noteText = 'Remove from Note'
        }
      })
      
      if ( setting.filters ) {
        //console.log(setting)
        setting.filters.forEach((value, index) =>  {
          templateListItemClasses.push(value)
        })
      }

      return <div className={templateListItemClasses.join(' ')} key={`setting-${key}`}>
              <Row>
                <Col xl="7" className={classes.setting_header}>
                  {setting.title}<br />
                  <span className={classes.category}>Category</span>
                </Col>
                <Col xl="5" className={classes.setting_toggle} onClick={e => this.props.toggleHandler(setting)}>
                  {noteText}
                </Col>
              </Row>
            </div>
    })

    return(
      <div className={templateClasses.widget_box} data-test="pdfSettings">
        <div className={templateClasses.widget_name}>
          PDF Settings
          <span className={iconClasses.join(' ')} onClick={this.menuHandler}></span>
        </div>
        <div className={pdfSettingsClasses.join(' ')}>
          <div className={classes.settings_container}>
            <div className={classes.setting_search}>
              <span className="icon-Search"></span>
              <input 
                className={classes.search_box}
                type="text" 
                placeholder="Find administrative forms (ex. Patient Information, Fax Cover Sheet)"
                onChange={this.searchHandler} />
            </div>
            <div className={classes.setting_meta}>
              <div className={classes.setting_filters}>
                <Filter
                  name="Sort" 
                  options={[
                    { val: "a", name: "Title A > Z"},
                    { val: "d", name: "Title Z < A"}
                  ]}
                  icon="icon-Sort"
                  click={this.sortHandler}
                  type="list"
                  customClass="pdf-outline-filter"
                />
                {provincialFilters}
              </div>
              <div className={classes.setting_results}>{this.state.resultsCount} results</div>
            </div>
            {pdfSettingsList}
          </div>
        </div>
      </div>
    )
  }
}

export default PdfSettingsOutline;