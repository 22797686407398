import React, { Component } from 'react'

import { connect } from 'react-redux'
import * as actions from '../store/actions/index'
import { Redirect } from 'react-router-dom'

import Notice from '../layout/UI/Notice';

import { ReactComponent as Logo } from '../images/logo.svg';

export class Login extends Component {
  state = {
    email: null,
    password: null,
    errors: this.props.errors,
    redirectToReferrer: false
  }

  emailHandler = (e) => {
    this.setState({ email: e.target.value })
  }

  passwordHandler = (e) => {
    this.setState({ password: e.target.value })
  }

  handleEnterKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.loginHandler();
    }
  }

  loginHandler = () => {
    const inputEmail = this.state.email
    const inputPassword = this.state.password

    const data = {
      email: inputEmail,
      password: inputPassword
    };

    this.props.login(data).then(
      (response) => {
        this.props.setPharmacy();
      },
      (error) => {
        console.log(error);
      }
    )
  }

  render () {
    if ( this.props.isAuthenticated && this.props.workspace !== null ) {
      return <Redirect to="/" />
    }

    let notices = null
    if ( this.props.errors ) {
      console.log(this.props.errors)
      let currentErrors = this.props.errors;
      if ( currentErrors.field.length > 0 ) {

      }

      if ( currentErrors.form.length > 0 ) {

      }

      if ( currentErrors.non_form.length > 0 ) {
        console.log(currentErrors.non_form)
        notices = <Notice
                  message={currentErrors.non_form[0].message}
                  type="error"
                  showIcon={false}
                  />
      }
    }

    const inputStyle = {
      border: '1px solid #DDE0E1'
    }


    return (
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-logo"><Logo /></div>
          <div className="login-box" data-test="component-login">
            <div><h2>Log In</h2></div>
            {notices}
            <div>
              <label className="small-text-format small-header text-uppercase colour-teal"  htmlFor="input-email">
                Email Address
              </label><br />
              <input id="input-email" name="username" type="text" onChange={this.emailHandler} onKeyDown={this.handleEnterKeyDown} style={inputStyle} />
            </div>
            <div>
              <label className="small-text-format small-header text-uppercase colour-teal" htmlFor="input-password">Password</label><br />
              <input id="input-password" name="password" type="password" onChange={this.passwordHandler} onKeyDown={this.handleEnterKeyDown} />
            </div>
            <div>
              <a href="account.qlinicalrx.com/accounts/password/reset/">Forgot your password?</a>
            </div>
            <div>
              <button onClick={this.loginHandler}>Log In</button>
            </div>
          </div>
          <div className="login-links">
            Don't have an account?
            <button href="https://qlinicalrx.com/signup/">Sign Up</button>
          </div>
          <div className="footer-links">
            <ul>
              <li><a href="https://qlinicalrx.com/about/">About QlinicalRx</a></li>
              <li><a href="https://support.qlinicalrx.com/">Help</a></li>
              <li><a href="https://qlinicalrx.com/terms/">Terms</a></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    isAuthenticated: state.isAuthenticated,
    workspace: state.workspace
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (data) => dispatch(actions.login(data)),
    setPharmacy: () => dispatch(actions.getSavedPharmacy())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
