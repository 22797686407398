import React from 'react';
//import { useSelector, useDispatch, connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom';
//import * as actions from '../../store/actions/index';
import Cookies from 'js-cookie';

const PrivateRoute = ({ component: Component, ...rest }) => {
  //const isLoggedIn = useSelector( state => state.isAuthenticated );
  //const dispatch = useDispatch();
  //const refreshAccessToken = useCallback( () => dispatch(actions.refreshAccessToken()), [dispatch]);
  //const setPharmacy = useCallback( () => dispatch(actions.getSavedPharmacy()), [dispatch]);
  
  return (
    <Route
      {...rest}
      render={props =>
        Cookies.get('user_token') 
          ? ( <Component {...props} {...rest} /> ) 
          : ( <Redirect to={{ pathname: '/login', state: { from: props.location } }} /> )
      }
    />
  )
}

export default PrivateRoute;