import React, { useEffect, useRef, useState } from 'react';

import FilterList from './FilterList';
import FilterCheckboxes from './FilterCheckboxes';

import classes from '../Search.module.scss';

const filter = ({ name, options, icon, click, type, customClass }) => {
  const node = useRef();
  const[open, setOpen] = useState(false);

  const classNames = [
    'popups',
    customClass
  ];

  const parentClassNames = [
    classes.filter_item,
    customClass
  ];

  if (open) {
    classNames.push('open')
    parentClassNames.push(classes.active)
  }

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return; // inside click
    }
    
    setOpen(false); // outside click
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  let list = '';
  if ( 'list' === type ) {
    list = <FilterList options={options} click={click} />
  } else if ( 'checkbox' === type ) {
    list = <FilterCheckboxes options={options} click={click} />
  }

  return (
    <div className={parentClassNames.join(' ')} ref={node}>
      <div className="popup_label text-uppercase" onClick={e => setOpen(!open)}>
        <span className="filter-label">{name}</span>
        <span className={icon}></span>
      </div>
      <div className={classNames.join(' ')}>
        {list}
      </div>
    </div>
  )
}

export default filter;