
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import Api from '../utils/api';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';

import { Row, Col } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import PageHeader from '../layout/PageHeader/PageHeader';
import MessageBox from './Template/MessageBox/MessageBox';
import NoteHeader from './Template/NoteHeader/NoteHeader';
import BlocksList from './Template/BlocksList/BlocksList';
import References from './Template/References/References';
import Outline from './Template/Outline/Outline';
import PdfSettingsOutline from './Template/PdfSettingsOutline/PdfSettingsOutline';
import PdfSettings from './Template/PdfSettings/PdfSettings';

import { getNewRadioValues, removeRadioValues, getNewDateValues, getNewSelectValues, getNewCheckboxValues, removeCheckboxValues, getNewTextValues, removeTextValues, buildBlocksHandler, buildInputsHandler, buildAdminHandler } from '../utils/template';

import classes from './Template/Template.module.scss';

class Templates extends Component {
  state = {
    name: '',
    template: {},
    references: [],
    events: [],
    triggers: [],
    saved: false,
    recovery_code: '',
    administrativeBlocks: [],
    pdfSettings: [],
    blocksData: [],
    plainTextOutput: '',
    currentOutline: 'data',
    templateLoaded: false,
    data: [],
    assessment: [],
    plan: {
      intervention: [],
      monitoring: [],
      follow: []
    },
    other: [],
    workspaceId: Cookies.get('user_pharmacy_id'),
    error: null
  }

  constructor(props) {
    super(props)
    this.contentNode = React.createRef()
    this.sidebarNode = React.createRef()
  }

  componentDidMount() {
    //this.setNoteHeight();
    console.log('Start Template')
    Api.fetch(`/api/v1/tools/templates/${this.props.match.params.id}/?pharmacy=${this.state.workspaceId}`).then((response) => {
      const template = response;
      //console.log(template)
      this.setState({
        name: template.title,
        data: template.data,
        references: template.references,
        events: template.events,
        template: template,
        assessment: template.assessment,
        plan: template.plan,
        other: template.other,
        templateLoaded: true
      })

      if ( template.administrative.length > 0 ) {
        for ( let key in template.administrative ) {
          let setting = template.administrative[key]
          Api.fetch(`/api/v1/tools/administrative/${template.administrative[key].id}/`).then((adminResponse) => {
            //console.log(adminResponse)
            setting.blocks = adminResponse
            this.setState((prevState) => {
              return { administrativeBlocks: prevState.administrativeBlocks.concat(setting) }
            })
          })
        }
      }
      
      this.buildDefaultsHandler();
      
    }).catch((error) => {
      if ( error.status === 401 ) {
        console.log('template logout')
        this.props.logout();
      } else {
        this.setState({
          error: error
        });
      }
    })
  }

  setAdministrativeBlocks = (adminArr) => {
    this.setState({ administrativeBlocks: [] })
    for ( let key in adminArr ) {
      let setting = adminArr[key]
      Api.fetch(`/api/v1/tools/administrative/${adminArr[key].id}/`).then((adminResponse) => {
        setting.blocks = adminResponse
        this.setState((prevState) => {
          return { administrativeBlocks: prevState.administrativeBlocks.concat(setting) }
        })
      })
    }
  }

  setNoteHeight = () => {
    let that = this;
    if ( that.sidebarNode.current ) {
      let clientHeight = that.sidebarNode.current.clientHeight+25;
      console.log(clientHeight);
      //setTimeout(function() {
        console.log(clientHeight);
        //that.sidebarNode.current.style.height = clientHeight + 'px';
      //}, 500)
    }
  }

  buildDefaultsHandler = () => {
    this.loopDefaultBlocks(this.state.data);
    this.loopDefaultBlocks(this.state.assessment);
    this.loopDefaultBlocks(this.state.plan.intervention);
    this.loopDefaultBlocks(this.state.plan.monitoring);
    this.loopDefaultBlocks(this.state.plan.follow);

    //this.checkData();
  }

  loopDefaultBlocks = (blocks) => {
    blocks.forEach( (block, index) => {
      let fields = block.content;
      this.setDefaultField(block.block_id, fields);
    });
  }

  setDefaultField = (block_id, fields) => {
    fields.forEach( (field, i) => {
      switch ( field.type ) {
        case 'input_radio':
        case 'input_checkbox':
          if ( field.checked )
            this.addToBlocksData(block_id, field.api_id, true, field.name);
          break;
        case 'input_select':
          field.options.forEach( (option) => {
            if ( option.selected )
              this.addToBlocksData(block_id, field.api_id, [option.value]);
          });
          break;
        case 'input_text':
          if ( field.value )
            this.addToBlocksData(block_id, field.api_id, field.value, field.name);
          break;
        case 'input_date':
          if ( field.value )
            this.addToBlocksData(block_id, field.api_id, field.value);
          break;
        case 'input_group':
            let group_fields = field.fields;
            this.setDefaultField(block_id, group_fields);
          break;
        default:
          break;
      }
    });
  }

  addToBlocksData = (block_id, api_id, value, name = null) => {
    let newData = {
      id: block_id,
      input: api_id,
      value: value,
      name: name
    }

    this.setState((prevState) => {
      return { blocksData: prevState.blocksData.concat(newData) }
    })
  }

  buildBlocksHandler = () => {
    let blocks = [];
    let data = this.getBlockId(this.state.data);
    let assessmentData = this.getBlockId(this.state.assessment);
    let interventionData = this.getBlockId(this.state.plan.intervention);
    let monitoringData = this.getBlockId(this.state.plan.monitoring);
    let followData = this.getBlockId(this.state.plan.follow);
    let otherData = this.getBlockId(this.state.other);

    blocks = data.concat(assessmentData).concat(interventionData).concat(monitoringData).concat(followData).concat(otherData);

    return blocks;
  }

  getBlockId = (blocks) => {
    const blocksArr = blocks.map((data) => {
      return data.block_id;
    });

    return blocksArr;
  }

  buildInputsHandler = () => {
    const inputs = this.state.blocksData.map((data) => {
      return { block_id: data.id, input_id: data.input, input_value: data.value };
    });

    return inputs;
  }

  buildAdminHandler = () => {
    if ( this.state.administrativeBlocks.length > 0 ) {
      const adminArr = this.state.administrativeBlocks.map((data) => {
        return data.id;
      });

      return adminArr;
    } else {
      return [];
    }
  }

  saveHandler = () => {
    const dataBlocks = this.buildBlocksHandler();
    const dataInputs = this.buildInputsHandler();
    const adminGroups = this.buildAdminHandler();

    const data = {
      pharmacy: this.props.currentPharmacy,
      recovery_code: this.state.template.recovery_code,
      template_title: this.state.name,
      blocks: dataBlocks,
      inputs: dataInputs,
      admin_groups: adminGroups,
    }

    Api.post('/api/v1/tools/templates/save/', data).then((response) => {
      this.setState({
        saved: true,
        recovery_code: response.recovery_code
      })
    })
  }

  exportHandler = (outputType) => {
    const blocksArr = this.buildBlocksHandler();
    const inputArr = this.buildInputsHandler();
    const adminGroups = this.buildAdminHandler();

    console.log(this.state.workspaceId);

    const data = {
      template_title: this.state.name,
      pharmacy: this.state.workspaceId,
      output: outputType,
      blocks: blocksArr,
      inputs: inputArr,
      admin_groups: adminGroups,
      admin_blocks: [],
      admin_inputs: [],
      note_datetime: this.state.noteDateTime,
    }

    Api.post('/api/v1/tools/templates/output/', data).then((response) => {
      this.saveHandler();

      switch (outputType) {
        case 'plain_text':
          this.setState({ plainTextOutput: response.output_data });
          break;
        case 'rich_text':
          this.setState({ plainTextOutput: response.output_data });
          break;
        case 'pdf':
          const link = document.createElement('a')
          link.href = response.output_data
          link.setAttribute('target', '_blank')
          link.setAttribute('rel', 'noreferrer noopener')
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          break
        default:
      }
    })
  }

  togglePdfSettingsHandler = (setting) => {
    const check = this.state.pdfSettings.indexOf(setting)
    if (check > -1) {
      this.setState((prevState) => {
        const filteredItems = prevState.pdfSettings.slice(0, check).concat(prevState.pdfSettings.slice(check + 1, prevState.pdfSettings.length))
        return { pdfSettings: filteredItems }
      })
    } else {
      this.setState((prevState) => {
        return { pdfSettings: prevState.pdfSettings.concat(setting) }
      })
    }
  }

  /**
   * Checbox Data Handler
   */
  updateCheckboxValue = (target, groupVal = null) => {
    const blockId = target.getAttribute('data-block-id');
    const apiId = target.getAttribute('data-api-id');
    const arr = this.state.blocksData;

    let removeIndexes = [];
    arr.find((object, index) => {
      //console.log(object.input + ' - ' + target.value)
      //console.log(object.input + ' - ' + groupVal)
      if ( object.input === groupVal ) {
        removeIndexes.push(index);
      }

      if (object.input === target.value && object.id === blockId) {
        removeIndexes.push(index);
        /*const updatedArr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length))
        this.setState((prevState) => {
          return { blocksData: updatedArr }
        })
        return true*/
      }
      return false
    });

    let newData = {
      id: blockId,
      input: apiId,
      value: true
    }

    this.setState((prevState) => {
      return { blocksData: prevState.blocksData.concat(newData) }
    });

    this.removeGroupValues(removeIndexes, arr);

    this.checkData();
  }

  removeGroupValues = (removeIndexes, arr) => {
    if ( removeIndexes.length > 0 ) {
      for (var i = removeIndexes.length -1; i >= 0; i--) {
        arr.splice(removeIndexes[i],1);
      }

      this.setState(() => {
        return { blocksData: arr }
      });
    }
  }

  removeCheckboxValue = (target) => {
    const blockId = target.getAttribute('data-block-id');
    //const apiId = target.getAttribute('data-api-id');
    const arr = this.state.blocksData;

    console.log('remove checkbox');

    arr.find((object, index) => {
      if (object.input === target.value && object.id === blockId) {
        console.log('pass')
        const updatedArr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
        console.log(updatedArr);
        this.setState((prevState) => {
          return { blocksData: updatedArr }
        })
        return true
      }
      return false
    });

    this.checkData()
  }

  /**
   * Add radio value to our block data
   */
  updateRadioValueHandler = (target) => {
    let removeIndexes = removeRadioValues(target, this.state.blocksData);
    if ( removeIndexes )
      this.removeGroupValues(removeIndexes, this.state.blocksData);

    let newData = getNewRadioValues(target, this.state.blocksData);
  
    this.setState((prevState) => {
      return { blocksData: prevState.blocksData.concat(newData) }
    });
  
    this.checkData();
  }

  /*updateRadioValueHandler = (target) => {
    const blockId = target.getAttribute('data-block-id');
    const apiId = target.getAttribute('data-api-id');
    const name = target.getAttribute('name');
    const arr = this.state.blocksData;

    if ( arr ) {
      let removeIndexes = [];
      arr.find((object, index) => {
        if ( object.id === blockId && object.name === name ) {
          removeIndexes.push(index);
        }
        return false;
      });

      this.removeGroupValues(removeIndexes, arr);

      const newData = {
        id: blockId,
        name: name,
        input: apiId,
        value: true
      };
    
      this.setState((prevState) => {
        return { blocksData: prevState.blocksData.concat(newData) }
      });
    
      this.checkData();
    }
  }*/

  removeObjecFromBlocksData( arr, index ) {
    const updatedArr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
    this.setState((prevState) => {
      return { blocksData: updatedArr }
    })
  }

  updateTextValueHandler = (e) => {
    const target = e.target;
    const blockId = target.getAttribute('data-block-id');
    const apiId = target.getAttribute('data-api-id');
    const name = target.getAttribute('name');
    const arr = this.state.blocksData;

    let added;
    if ( arr ) {
      added = arr.find((object, index) => {
        if (object.id === blockId && object.input === apiId) {
          if ( target.value ) {
            object.value = target.value;
            this.setState((prevState) => {
              return { blocksData: arr }
            });
          } else {
            /*const updatedArr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
            this.setState((prevState) => {
              return { blocksData: updatedArr }
            });*/
            this.removeObjecFromBlocksData(arr, index)
          }
          return true
        }
        return false
      })
    }

    if ( !added ) {
      let newData = {
        id: blockId,
        name: name,
        input: apiId,
        value: target.value
      }
      
      this.setState((prevState) => {
        return { blocksData: prevState.blocksData.concat(newData) }
      })
    }

    this.checkData()
  }

  updateSelectValueHandler = (selectedOption, apiId, blockId) => {
    const arr = this.state.blocksData;

    let added;
    if ( arr ) {
      added = arr.find((object, index) => {
        if (object.id === blockId && object.input === apiId) {
          object.value = [selectedOption.value]
          this.setState((prevState) => {
            return { blocksData: arr }
          })
          return true;
        }
        return false;
      })
    }

    if ( !added ) {
      let newData = {
        id: blockId,
        input: apiId,
        value: [selectedOption.value]
      }

      this.setState((prevState) => {
        return { blocksData: prevState.blocksData.concat(newData) }
      })
    }

    this.checkData()
  }

  updateDateHandler = (date, blockId, apiId) => {
    const arr = this.state.blocksData;

    let added;
    if ( arr ) {
      added = arr.find((object) => {
        if (object.id === blockId && object.input === apiId) {
          object.value = date
          this.setState((prevState) => {
            return { blocksData: arr }
          })
          return true
        }
        return false
      })
    }

    if ( !added ) {
      let newData = {
        id: blockId,
        input: apiId,
        value: date
      }
      
      this.setState((prevState) => {
        return { blocksData: prevState.blocksData.concat(newData) }
      })
    }

      
    this.checkData()
  }

  updateMedicationHandler = (data) => {
    console.log(data);
    /*this.setState((prevState) => {
      return { blocksData: prevState.blocksData.concat(data) }
    })*/
      
    this.checkData()
  }

  checkData = () => {
    let that = this;
    setTimeout(function() {
      console.log(that.state.blocksData);
    }, 500)
  }

  setDate = (date) => {
    this.setState({ noteDateTime: date })
  }

  searchPdfSettings = (searchTerm, sort) => {
    let sortTerm = sort
    if ( !sort )
      sortTerm = 'r'

    let url = `/api/v1/tools/administrative/?pharmacy=${this.props.currentPharmacy}`

    if ( searchTerm )
      url += `&q=${searchTerm}`
    
    if ( sortTerm )
      url += `&sort=${sortTerm}`

    Api.fetch(url).then((response) => {
      console.log(response)
      this.setAdministrativeBlocks(response);
    })
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
        backgroundColor: '#bbb',
        borderRadius: '5px',
        opacity: '0.5'
    };
    return (
        <div
            style={{ ...style, ...thumbStyle }}
            {...props}/>
    );
  }

  changeOutline = (inView, entry) => {
    //console.log('change outline')
    //console.log(inView)
    if ( inView && this.state.templateLoaded ) {
      this.setState({
        currentOutline: entry.target.children[0].getAttribute('data-section')
      })
    }
  }

  outerHeight = (el) => {
    var height = el.offsetHeight;
    var style = getComputedStyle(el);
  
    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  }

  render() {
    if (this.state.error) {
      //throw new Error(this.state.error.statusText);
      //throw new Error(this.state.error);

      return <div>There was an error with the page.</div>
    }

    const pageHeader = document.getElementById('page-header');

    let headerHeight = 0
    if ( pageHeader )
      headerHeight = this.outerHeight(pageHeader);

    let pdfSettingsClasses = [classes.template_content];
    if ( this.state.pdfSettings.length === 0 )
      pdfSettingsClasses.push(classes.no_pdf_settings);
    
    const styleStr = {
      height: (window.innerHeight-34)-headerHeight + 'px'
    }

    let blockList = [];
    if ( this.state.data.length > 0 ) {
      blockList.push(<BlocksList
                    key="section-1"
                    blocks={this.state.data}
                    header="Data"
                    events={this.state.events} 
                    triggers={this.state.triggers}  
                    checkboxChanged={this.updateCheckboxValue}
                    removeCheckbox={this.removeCheckboxValue}
                    radioChanged={this.updateRadioValueHandler}
                    textChanged={this.updateTextValueHandler}
                    selectChanged={this.updateSelectValueHandler}
                    dateChanged={this.updateDateHandler} 
                    medicationChanged={this.updateMedicationHandler}
                    outlineChanged={this.changeOutline} />);
    }

    if ( this.state.assessment.length > 0 ) {
      blockList.push(<BlocksList 
                    key="section-2"
                    blocks={this.state.assessment}
                    header="Assessment"
                    events={this.state.events} 
                    triggers={this.state.triggers} 
                    checkboxChanged={this.updateCheckboxValue}
                    radioChanged={this.updateRadioValueHandler}
                    textChanged={this.updateTextValueHandler}
                    selectChanged={this.updateSelectValueHandler}
                    dateChanged={this.updateDateHandler}
                    medicationChanged={this.updateMedicationHandler}
                    outlineChanged={this.changeOutline} />);
    }

    if ( this.state.plan.intervention.length > 0 ) {
      blockList.push(<BlocksList 
                    key="section-3"
                    blocks={this.state.plan.intervention}
                    header="Plan: Interventions"
                    events={this.state.events} 
                    triggers={this.state.triggers} 
                    checkboxChanged={this.updateCheckboxValue}
                    radioChanged={this.updateRadioValueHandler}
                    textChanged={this.updateTextValueHandler}
                    selectChanged={this.updateSelectValueHandler}
                    dateChanged={this.updateDateHandler}
                    medicationChanged={this.updateMedicationHandler}
                    outlineChanged={this.changeOutline} />);
    }

    if ( this.state.plan.monitoring.length > 0 ) {
      blockList.push(<BlocksList
                    key="section-4"
                    blocks={this.state.plan.monitoring}
                    header="Plan: Monitoring"
                    events={this.state.events} 
                    triggers={this.state.triggers} 
                    checkboxChanged={this.updateCheckboxValue}
                    radioChanged={this.updateRadioValueHandler}
                    textChanged={this.updateTextValueHandler}
                    selectChanged={this.updateSelectValueHandler}
                    dateChanged={this.updateDateHandler}
                    medicationChanged={this.updateMedicationHandler}
                    outlineChanged={this.changeOutline} />);
    }

    if ( this.state.plan.follow.length > 0 ) {
      blockList.push(<BlocksList
                    key="section-5"
                    blocks={this.state.plan.follow}
                    header="Plan: Follow-Up"
                    events={this.state.events} 
                    triggers={this.state.triggers} 
                    checkboxChanged={this.updateCheckboxValue}
                    radioChanged={this.updateRadioValueHandler}
                    textChanged={this.updateTextValueHandler}
                    selectChanged={this.updateSelectValueHandler}
                    dateChanged={this.updateDateHandler}
                    medicationChanged={this.updateMedicationHandler}
                    outlineChanged={this.changeOutline} />);
    }

    if ( this.state.other.length > 0 ) {
      blockList.push(<BlocksList 
                    key="section-6"
                    blocks={this.state.other}
                    header="Other Notes & Comments"
                    events={this.state.events} 
                    triggers={this.state.triggers} 
                    checkboxChanged={this.updateCheckboxValue}
                    radioChanged={this.updateRadioValueHandler}
                    textChanged={this.updateTextValueHandler}
                    selectChanged={this.updateSelectValueHandler}
                    dateChanged={this.updateDateHandler}
                    medicationChanged={this.updateMedicationHandler}
                    outlineChanged={this.changeOutline} />);
    }

    return (
      <div className={classes.template}>
        <Row>
          <Col>
            <PageHeader
              name={this.state.name}
              pharmacy={this.props.currentPharmacyName}
              save={this.saveHandler}
              export={this.exportHandler}
              plainTextOutput={this.state.plainTextOutput}
              recoveryCode={this.state.recovery_code}
              />
          </Col>
        </Row>

        <Row className={classes.template_section}>
          <Col sm="8" id="template-content" style={styleStr}>
            <Scrollbars id="template-scroll-content" renderThumbVertical={this.renderThumb}>
              <Col  xs="12" className={classes.template_content}>
                <div ref={this.contentNode}>
                  <MessageBox message="This template has default values that have been added for you." />
                  <NoteHeader 
                    name={this.state.name} 
                    noteId={this.state.template.recovery_code} 
                    dateObj={this.state.template.pharmacy_datetime} 
                    setDateHandler={this.setDate} />
                  {blockList}
                  <References references={this.state.references} />
                </div>
              </Col>

              <Col xs="12" className={pdfSettingsClasses.join(' ')}>
                <PdfSettings settings={this.state.pdfSettings} />
              </Col>
            </Scrollbars>
          </Col>

          <Col sm="4" className={classes.template_sidebar}>
            <div className={classes.template_sidebar_container} ref={this.sidebarNode}>
              <Outline 
                currentSection={this.state.currentOutline}
                dataSection={this.state.data.length}
                assessmentSection={this.state.assessment.length}
                planSection={this.state.plan}
                otherSection={this.state.other.length}
              />
              <PdfSettingsOutline
                settings={this.state.administrativeBlocks}
                currentSettings={this.state.pdfSettings}
                toggleHandler={this.togglePdfSettingsHandler}
                searchHandler={this.searchPdfSettings}
                sortHandler={this.searchPdfSettings}
                filterHandler={this.filterPdfSettings}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    currentPharmacyName: state.currentPharmacyName,
    userId: state.userId,
    workspace: state.workspace
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTemplate: ( templateId, workspaceId ) => dispatch( actions.getTemplate(templateId, workspaceId) ),
    logout: () => dispatch( actions.logout() )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);