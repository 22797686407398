import React, { useState, useEffect } from 'react';
import MedicationBlockItem from './MedicationBlockItem';
import AddMedicationModal from '../../../Template/Modals/AddMedicationHistory';
//import Api from '../../../../utils/api';
//import { Row, Col } from 'reactstrap';

const medicationBlock = (props) => {
  const inputId = props.id;
  const [medList, setMedList] = useState(null);
  const [extraMedList, setExtraMedList] = useState([]);
  const [medListHeaders, setMedListHeaders] = useState(null);
  const [doseAttribute, setDoseAttribute] = useState(null);

  let labelBefore = '',
      labelAfter = '',
      medicationBlockClasses = ['input-medication-block'];

  if ( props.labelBefore )
    labelBefore = <span>{props.labelBefore}</span>
  
  if ( props.labelAfter )
    labelAfter = <span>{props.labelAfter}</span>
  
  let label = '';
  if ( props.label ) {
    if ( props.labelPosition === 'top' ) {
      label = <div className="top-label"><label htmlFor={props.name}>{props.label}</label></div>
    } else {
      label = <label htmlFor={inputId}>{props.label}</label>
    }
  }

  useEffect(() => {
    const medListTemplate = props.value.map((medication, index) => {
      let values = medication.attributes;

      let medkey = 'med-' + index;
      return <MedicationBlockItem
        key={medkey}
        attributes={props.attributes}
        displayName={medication.details.display_name}
        values={values}
      />
    });

    const medListHeaders = props.attributes.map((attribute, index) => {
      if ( attribute.indexOf('dose') >= 0 ) {
        setDoseAttribute(attribute);
      }

      let medKey = 'med-' + index;
      return <th key={medKey} className="med-block-header small-header small-text-format">{attribute.replace(/_/g, ' ')}</th>
    });

    setMedListHeaders(medListHeaders);
    setMedList(medListTemplate);
  }, [])

  

  const validateHandler = (event) => {
    const value = event.target.value;
    props.changed(event);

    if ( props.events ) {
      if ( props.events.length > 0 ) {
        props.events.forEach( (event) => {
          if ( value ) {
            props.eventTargetHandler(event.targets, 'value_show');
          } else {
            props.eventTargetHandler(event.targets, 'value_hide');
          }
        });
      }
    }
  }

  const saveTherapy = (medication_id, medication_name, attributes) => {
    let data = [
      {
        id: medication_id,
        block_id: props.blockId,
        attributes: attributes
      }
    ];

    props.medicationChanged(data);

    //console.log(attributes);
    let medkey = 'med-extra' + medication_id;

    const newItem = 
      <MedicationBlockItem
        key={medkey}
        attributes={props.attributes}
        displayName={medication_name}
        values={attributes}
      />
    
    setExtraMedList([newItem]);
  }

  return (
    <React.Fragment>
      <div className={medicationBlockClasses.join(' ')} style={{overflowX: 'scroll'}} data-test="medication-block"> 
        {labelBefore}
        {label}
        <table className="search-table inner" style={{width: '200%'}}>
          <thead>
            <tr>
              <th className="med-block-header small-header small-text-format">Name</th>
              {medListHeaders}
            </tr>
          </thead>
          <tbody>
            {medList}
            {extraMedList}
          </tbody>
        </table>
      </div>
      <AddMedicationModal
        saveTherapy={saveTherapy}
        doseAttribute={doseAttribute}
        medicationList={props.medication_list}
        attributes={props.attributes}
        existingMedications={props.value}
      />
    </React.Fragment>
  )
};

export default medicationBlock;