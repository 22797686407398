import React from 'react';
import Block from './Block/Block';
import { InView } from 'react-intersection-observer';

import classes from '../Template.module.scss';

const blocksList = (props) => {
  let blocks
  //console.log(props)

  // Events
  const eventHandler = (type, target, value) => {
    console.log('Block List Event handler');
    //console.log(type);
    //console.log(target);
    switch (type) {
      case 'choice_show':
      case 'value_show':
        document.getElementById(target).style.display = 'block';
        break;
      case 'choice_hide':
      case 'value_hide':
        document.getElementById(target).style.display = 'none';
        break;
      case 'value_toggle_on':
        document.getElementById(target).checked = true;
        break;
      case 'value_toggle_off':
        document.getElementById(target).checked = false;
        break;
      case 'calculate_number_total':
        console.log('Calculate Number Total');
        let totalPointsNt = document.getElementById(target).value;
        console.log(totalPointsNt);
        break;
      case 'calculate_choice_total':
        console.log('Calculate Choice Total');
        const totalTarget = document.getElementById(target);
        let totalPointsCT = totalTarget.value;
        if ( totalPointsCT ) {
          totalTarget.value = parseInt(totalPointsCT)+value;
        } else {
          let totalPointsCTStart = 0;
          totalTarget.value = totalPointsCTStart+value;
        }
        break;
      default:
    }
  }

  const eventTargetHandler = (targets, type, value = 0) => {
    targets.forEach((target) => {
      if ( target.input_id ) {
        eventHandler(type, target.input_id, value);
      } else if ( target.block_id ) {
        eventHandler(type, 'block-' + target.block_id, value);
      }
    });
  }

  if ( props.blocks ) {
    blocks = props.blocks.map((block, index) => {
      const key = 'block-' + index
      return <Block 
                blockId={block.block_id}
                cds={block.cds}
                eventHandler={eventHandler}
                eventTargetHandler={eventTargetHandler}
                fields={block.content}
                hide={block.hide}
                key={key}
                labelPosition={props.labelPosition}
                layout={block.layout}
                name={block.header}
                checkboxChanged={props.checkboxChanged}
                removeCheckbox={props.removeCheckbox}
                radioChanged={props.radioChanged}
                selectChanged={props.selectChanged}
                textChanged={props.textChanged}
                dateChanged={props.dateChanged}
                medicationChanged={props.medicationChanged} />
    });
  }
  let id = 'section-';
  if ( props.header )
    id += props.header.replace('& ', '').replace(/ /g, '-').replace(':', '').toLowerCase();

  return (
    <InView onChange={ (inView, entry) => { props.outlineChanged(inView, entry) }}>
      {({ inView, ref, entry }) => (
        <div className={classes.block_list} ref={ref} data-test="component-block-list">
          <div id={id} className={classes.section_name} data-section={id}>{props.header}</div>
          <hr />
          {blocks}
        </div>
      )}
    </InView>
  )
};

export default blocksList;
