import React from 'react';

import classes from './Inputs.module.scss';

const inputTextMultiline = (props) => {
  //const inputId = props.blockId + '_' + props.apiId;
  const inputId = props.id;

  let labelBefore = '',
      labelAfter = '',
      textMultilineClasses = ['input-field', classes.text_multiline];

  if ( props.labelBefore )
    labelBefore = <span className={classes.label_before}>{props.labelBefore}</span>
  
  if ( props.labelAfter )
    labelAfter = <span className={classes.label_after}>{props.labelAfter}</span>
  
  let label = '';
  if ( props.label ) {
    if ( props.labelPosition === 'top' ) {
      label = <div className="top-label"><label htmlFor={props.name}>{props.label}</label></div>
    } else {
      label = <label htmlFor={inputId}>{props.label}</label>
    }
  }

  const validateHandler = (event) => {
    const value = event.target.value;
    props.changed(event);

    if ( props.events ) {
      if ( props.events.length > 0 ) {
        props.events.forEach( (event) => {
          if ( value ) {
            props.eventTargetHandler(event.targets, 'value_show');
          } else {
            props.eventTargetHandler(event.targets, 'value_hide');
          }
        });
      }
    }
  }

  return (
    <div className={textMultilineClasses.join(' ')} data-test="input-text-multiline"> 
      {labelBefore}
      {label}
      <textarea id={inputId} data-api-id={props.apiId} onChange={validateHandler} data-block-id={props.blockId} defaultValue={props.value}></textarea>
      {labelAfter}
    </div>
  )
};

export default inputTextMultiline;