import React, { useState, useEffect } from 'react';
//import * as actions from '../../../store/actions/index';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notice from '../../../layout/UI/Notice';

const openSavedModal = () => {
  //const currentPharmacy = useSelector(state => state.currentPharmacy);
  const modalErrors = useSelector(state => state.errors);

  const[open, setOpen] = useState(false);
  const[redirect, setRedirect] = useState(null);
  const[recoveryCode, setRecoveryCode] = useState(null);

  //const dispatch = useDispatch();
  /*const openSavedNote = useCallback(
    (recoveryCode, pharamcyId) => dispatch(actions.openSavedNote(recoveryCode, pharamcyId)),
    [dispatch],
  );*/

  const closeModal = () => {
    setOpen(false);
  }

  const handleRecoveryCode = (event) => {
      setRecoveryCode( event.target.value );
  }

  const openSavedHandler = () => {
    //openSavedNote(recoveryCode, currentPharmacy);
    /*setTimeout(function() {
      
      console.log(modalErrors);
    }, 5000)*/
    if ( recoveryCode ) {
      let link = 'create-note/' + recoveryCode;
      setRedirect(<Redirect to={link} />);
      setOpen(false);
    }
  }

  const handleClickOutside = e => {
    const currentModal = document.querySelector('#open-saved')
    if (currentModal.contains(e.target)) {
      return // inside click
    }
    
    if ( open )
      closeModal() // outside click
  }

  const openModal = (e) => {
    e.preventDefault()
    setOpen(!open)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [open])

  let notices = null;
  if ( modalErrors ) {
    let currentErrors = modalErrors;
    //console.log('found errors')
    //console.log(currentErrors)
    if ( currentErrors ) {
      notices = <Notice
                message={currentErrors}
                type="error"
                showIcon={false}
                />
    }

    /*if ( currentErrors.form.length > 0 ) {

    }

    if ( currentErrors.non_form.length > 0 ) {
      console.log(currentErrors.non_form)
      notices = <Notice
                message={currentErrors.non_form[0].message}
                type="error"
                showIcon={false}
                />
    }*/
  }

  return (
    <React.Fragment>
      {redirect}
      <a href="/open-saved-note" onClick={openModal}>
        <span className="menu-icon icon-OpenSaved"></span>
        <span className="nav-text">Open Saved Note</span>
      </a>

      <Modal id="open-saved" isOpen={open}>
        <Button className="close" onClick={closeModal}><span className="icon-X_CloseForm"></span></Button>
        <ModalHeader>Open saved note</ModalHeader>
        <ModalBody>
          <p>Enter your recovery code to recover an existing note. Your note recovery code is on any of the note outputs you may have generated.</p>
          {notices}
          <label className="small-text-format small-header text-uppercase colour-teal" htmlFor="recovery-code">Note Recovery Code</label><br />
          <input name="recovery-code" type="text" onChange={handleRecoveryCode} /><br />
         
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer-container">
            <button onClick={openSavedHandler}>Open Saved</button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default openSavedModal;