import React, { Component } from 'react';

import blockClasses from '../Block/Block.module.scss'

class InputCheckbox extends Component {
  state = {
    events: this.props.events,
    isChecked: this.props.checked,
  }

  /**
   * Controls custom events passed in by the API
   */
  eventHandler = (target) => {
    this.state.events.forEach( (event) => {
      const type = event.type;
      let value = 0;

      if ( 'calculate_choice_total' === type ) {
        event.triggers.forEach((trigger) => {
          if ( trigger.input_id === target.id ) {
            value = trigger.value;

            if ( false === target.checked )
              value = -trigger.value;
          }
        })
        
        this.props.eventTargetHandler(event.targets, type, value);
      } else {
        this.props.eventTargetHandler(event.targets, type);
      }
    });
  }

  checkboxChanged = (e) => {
    const targetChecked = e.target.checked;

    if ( true === targetChecked ) {
      this.setState({ isChecked: true })
    } else {
      this.setState({ isChecked: false })
    }

    if ( false === this.props.customTemplate ) {
      this.props.changed(e.target);

      if ( this.state.events.length > 0 ) {
        this.eventHandler(e.target);
      }
    }
  }

  render() {
    //const inputId = this.props.blockId + '_' + this.props.apiId;
    const inputId = this.props.id;

    let labelBefore,
        labelAfter,
        label = <label htmlFor={inputId}>{this.props.label}</label>

    if ( !this.props.label )
      label = <label className="empty-label"></label>

    if ( this.props.labelBefore )
      labelBefore = this.props.labelBefore;
    
    if ( this.props.labelAfter )
      labelAfter = this.props.labelAfter;

    let checkbox_classes = [
      'checkbox-input',
      'input-field',
      blockClasses.checkbox_field
    ];

    return (
      <div className={checkbox_classes.join(' ')} data-test="input-checkbox">
        {labelBefore}
        <input 
          type="checkbox" 
          id={inputId}
          name={this.props.name} 
          onChange={this.checkboxChanged} 
          data-test="checkbox-option" 
          value={this.props.value} 
          data-block-id={this.props.blockId} 
          data-api-id={this.props.apiId}
          defaultChecked={this.state.isChecked}
          disabled={this.props.disabled}
          ref={input => { this.inputElement = input }}
          />
        {label}
        {labelAfter}
      </div>
    )
  }
};

export default InputCheckbox;