export const getNewRadioValues = (target, blocksData) => {
  const blockId = target.getAttribute('data-block-id');
  const apiId = target.getAttribute('data-api-id');
  const name = target.getAttribute('name');
  //const arr = blocksData;

  //if ( arr ) {
    const newData = {
      id: blockId,
      name: name,
      input: apiId,
      value: true
    };
  
    return newData;
  //}
}

export const removeRadioValues = (target, blocksData) => {
  const blockId = target.getAttribute('data-block-id');
  const name = target.getAttribute('name');

  if ( blocksData ) {
    let removeIndexes = [];
    blocksData.find((object, index) => {
      if ( object.id === blockId && object.name === name ) {
        removeIndexes.push(index);
      }
      return false;
    });

    return removeIndexes;
  }

  return false;
}

export const getNewCheckboxValues = (target) => {
  const blockId = target.getAttribute('data-block-id');
  const apiId = target.getAttribute('data-api-id');
  
    let newData = {
      id: blockId,
      input: apiId,
      value: true
    }

    return newData;

  //return false;
}

export const removeCheckboxValues = (target, groupVal, blockData) => {
  const blockId = target.getAttribute('data-block-id');
  const arr = blockData;

  let removeIndexes = [];
  arr.find((object, index) => {
    if ( object.input === groupVal ) {
      removeIndexes.push(index);
    }

    if (object.input === target.value && object.id === blockId) {
      removeIndexes.push(index);
    }
    return false
  });

  return removeIndexes;
}

export const removeGroupValues = (removeIndexes, arr) => {
  if ( removeIndexes.length > 0 ) {
    for (var i = removeIndexes.length -1; i >= 0; i--) {
      arr.splice(removeIndexes[i],1);
    }

    return removeIndexes;
  }
}

export const getNewSelectValues = (selectedOption, apiId, blockId, blockData) =>  {
  const arr = blockData;
  let added;

  if ( arr ) {
    added = arr.find((object, index) => {
      if (object.id === blockId && object.input === apiId) {
        object.value = [selectedOption.value]
        return true;
      }
      return false;
    })
  }

  if ( !added ) {
    let newData = {
      id: blockId,
      input: apiId,
      value: [selectedOption.value]
    }

    return newData;
  }

  return false;
}

export const getNewTextValues = (target, blockData ) => {
  const blockId = target.getAttribute('data-block-id');
  const apiId = target.getAttribute('data-api-id');
  const name = target.getAttribute('name');
  const arr = blockData;

  let added;
  if ( arr ) {
    added = arr.find((object, index) => {
      if (object.id === blockId && object.input === apiId) {
        if ( target.value ) {
          object.value = target.value;
          return true
        } else {
          // remove blank value
          //const updatedArr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
          /*this.setState((prevState) => {
            return { blocksData: updatedArr }
          });*/
          //this.removeObjecFromBlocksData(arr, index)
          return true;
        }
      }
      return false;
    })
  }

  if ( !added ) {
    let newData = {
      id: blockId,
      name: name,
      input: apiId,
      value: target.value
    }

    return newData;
  }
}

export const removeTextValues = (target, blockData ) => {
  const arr = blockData;
  const blockId = target.getAttribute('data-block-id');
  const apiId = target.getAttribute('data-api-id');

  let index = arr.findIndex((object, index) => {
    if (object.id === blockId && object.input === apiId) {
      //console.log('found')
      //console.log(index)
      //const updatedArr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
      return true;
    }

    return false;
  });

  return index;
  //this.removeObjecFromBlocksData(arr, index)
}

export const getNewDateValues = (date, blockId, apiId, blocksData) => {
  const arr = blocksData;

  let added;
  if ( arr ) {
    added = arr.find((object) => {
      if (object.id === blockId && object.input === apiId) {
        object.value = date
        /*this.setState((prevState) => {
          return { blocksData: arr }
        })*/
        return true
      }
      return false
    })
  }

  if ( !added ) {
    let newData = {
      id: blockId,
      input: apiId,
      value: date
    }

    return newData;
  }

  return false;
}

export const getNewMedicationValues = (date, blockId, apiId, blocksData) => {

}

export const buildBlocksHandler = ( dataBlock, assessmentBlock, interventionBlock, monitoringBlock, followBlock, otherBlock ) => {
  let blocks = [];
  let data = getBlockId(dataBlock);
  let assessmentData = getBlockId(assessmentBlock);
  let interventionData = getBlockId(interventionBlock);
  let monitoringData = getBlockId(monitoringBlock);
  let followData = getBlockId(followBlock);
  let otherData = getBlockId(otherBlock);

  blocks = data.concat(assessmentData).concat(interventionData).concat(monitoringData).concat(followData).concat(otherData);

  return blocks;
}

const getBlockId = (blocks) => {
  const blocksArr = blocks.map((data) => {
    return data.block_id;
  });

  return blocksArr;
}

export const buildInputsHandler = (blocksData) => {
  const inputs = blocksData.map((data) => {
    return { block_id: data.id, input_id: data.input, input_value: data.value };
  });

  return inputs;
}

export const buildAdminHandler = (administrativeBlocks) => {
  if ( administrativeBlocks.length > 0 ) {
    const adminArr = administrativeBlocks.map((data) => {
      return data.id;
    });

    return adminArr;
  } else {
    return [];
  }
}