import React from 'react';

const filterList = ({ options, click }) => {
  let checkboxes = options.map((optObj, index) => {
    if ( optObj.children ) {
      return <li key={'filter-'+index}>
                <div className="checkbox-input">
                  <input type="checkbox" onClick={click} data-filter={optObj.val} />
                  <label>{optObj.name}</label>

                  <ul>
                    {optObj.children.map((optChildObj, cindex) => (
                      <li key={'filter-child-'+cindex}>
                        <div className="checkbox-input">
                          <input type="checkbox" onClick={click} data-filter={optChildObj.val} data-filter-name={optChildObj.name} />
                          <label>{optChildObj.name}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
    } else {
      return <li key={'filter-'+index}>
              <div className="checkbox-input">
                <input type="checkbox" onClick={click} data-filter={optObj.val} data-filter-name={optObj.name} />
                <label>{optObj.name}</label>
              </div>
            </li>
    }
    
  });

  return (
    <ul>
      {checkboxes}
    </ul>
  )
}

export default filterList;