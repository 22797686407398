import React, { useState, useEffect, useCallback } from 'react'

import CustomBlock from './CustomBlock';
import update from 'immutability-helper';
import classes from './CustomBlock.module.scss'

const customBlocksList = (props) => {
  let blocks
  const [testBlocks, setTestBlocks] = useState([])

  useEffect(() => {
    setTestBlocks(props.blocks)
  }, [props.blocks])

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = testBlocks[dragIndex]
      setTestBlocks(
        update(testBlocks, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [testBlocks],
  )
  
  if ( Object.keys(testBlocks).length > 0 ) {
    blocks = testBlocks.map((block, index) => {
      const key = 'block-' + index;
      return <CustomBlock 
                blockId={block.block_id}
                eventsChanged={props.eventsChanged}
                cds={block.cds}
                checkboxChanged={props.checkboxChanged}
                fields={block.content}
                hide={block.hide}
                index={index}
                key={key}
                labelPosition={props.labelPosition}
                layout={block.layout}
                moveCard={moveCard}
                name={block.header}
                radioChanged={props.radioChanged}
                selectChanged={props.selectChanged}
                textChanged={props.textChanged}
                dateChanged={props.dateChanged}
                removeBlock={props.removeBlock}
                type={props.type}
                saveHandler={props.saveHandler}
              />
    })
  } else {
    console.log('empty blocks');
    blocks =  <CustomBlock 
              blockId="0"
              eventsChanged={props.eventsChanged}
              cds="0"
              checkboxChanged={props.checkboxChanged}
              fields={[]}
              hide={false}
              index="0"
              key="block-0"
              labelPosition={props.labelPosition}
              layout=""
              moveCard={moveCard}
              name=""
              radioChanged={props.radioChanged}
              selectChanged={props.selectChanged}
              textChanged={props.textChanged}
              dateChanged={props.dateChanged}
              removeBlock={props.removeBlock}
              type={props.type}
            />
  }

  return (
    <div className={classes.customBlockList}>
      <h4>{props.header}</h4>
      {blocks}
    </div>
  )
}

export default customBlocksList;