import React from 'react';

import classes from './Widgets.module.scss';

const createDocumentationNoteWidget = (props) => {

  return (
    <div className={[props.widgetClass, classes.tablet_only].join(' ')}>
      <div className={classes.widget_container}>
        <h2 className="main-header">Create a Documentation Note</h2>
        <p>Create a new documentation note using a QlinicalRx template, your custom templates or any templates from your organization.</p>
        <button onClick={props.click}>Find Template & Create Note</button>
      </div>
    </div>
  )
}

export default createDocumentationNoteWidget;