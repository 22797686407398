import React,  { Component } from 'react';

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import { Container } from 'reactstrap';

import Login from '../../pages/Login';
import Dashboard from '../../pages/Dashboard';
import CreateNote from '../../pages/CreateNote';
import Template from '../../pages/Template';
import CustomTemplates from '../../pages/CustomTemplates';
import EditCustomTemplate from '../../pages/CustomTemplates/EditCustomTemplate/EditCustomTemplate';

class MainContent extends Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid={true}>
          <Switch>
            <PrivateRoute path="/" exact component={Dashboard} />
            <PrivateRoute path="/create-note/" exact component={CreateNote} />
            <PrivateRoute path="/create-note/:id" exact component={Template} />
            <PrivateRoute path="/custom/" exact component={CustomTemplates} />
            <PrivateRoute path="/edit-template/:id" exact component={EditCustomTemplate} />
            <PrivateRoute path="/new-template/" exact component={EditCustomTemplate} />
            <Route path="/login" exact component={Login} />
          </Switch>
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.isAuthenticated,
  isLoading: state.isLoading
});

export default connect(mapStateToProps)(MainContent)