import React from 'react'
import { Col } from 'reactstrap';

//import classes from '../../../Template/PdfSettingsOutline/PdfSettingsOutline.module.scss';
import classes from './CardTemplate.module.scss';

const cardTemplate = (props) => {

  const setTemplate = () => {
    props.setBlocks(props.id)
  }

  const cardTemplateClasses = [
    'row',
    'template-list-item',
    classes.card_block
  ]

  if ( props.filters.length > 0 ) {
    props.filters.forEach((value, index) =>  {
      cardTemplateClasses.push(value);
    })
  }

  return (
    <div className={cardTemplateClasses.join(' ')}>
      <Col xl="7">
        <div className={classes.setting_header}>{props.name}</div>
        <div className={classes.owner_text}>{props.creator} Template</div>
      </Col>
      <Col xl="5"  className={classes.setting_toggle}  onClick={e => setTemplate()}>Add to Template</Col>
    </div>
  )
}

export default cardTemplate;