import React, { useEffect, useState } from 'react';

const medicationBlockItem = (props) => {
  const [itemRow, setItemRow] = useState(null);

  useEffect(() => {
    //console.log('Props Values:');
    const itemRow = props.values.map((item, index) => {
      const attribute = props.attributes[index];
     
      let itemKey = 'med-item' + index;
      let itemValue = item[attribute];
      if ( typeof itemValue === 'object' && ( attribute === 'daily_dose_validated' || attribute === 'daily_dose_freeform'  ) ) {
        itemValue = itemValue.dose + itemValue.unit;
      } else if ( typeof itemValue === 'object' && attribute === 'ome' ) {
        itemValue = itemValue.ome_medication;
      } else if ( typeof itemValue === 'boolean' ) {
        itemValue = itemValue.toString();
      }
      return <td key={itemKey}>{itemValue}</td>
    });

    setItemRow(itemRow);
  }, [])

  return (
    <tr className="med-block-items-row">
      <td>{props.displayName}</td>
      {itemRow}
    </tr>
  )
}

export default medicationBlockItem;