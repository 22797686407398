import React from 'react';
import classes from '../Template.module.scss';

const references = (props) => {
  const referenceLinks = props.references.map((reference, index) => {
    const linkKey = 'reference-' + index;
    return <li key={linkKey} data-test="reference-link"><a href={reference.url}>{reference.value}</a></li>
  })

  return <div data-test="component-references">
    <div id="section-references" className={classes.section_name} data-section="section-references">References</div>
    <hr />
    <ul>{referenceLinks}</ul>
  </div>
}

export default references;