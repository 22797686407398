import Api from '../../utils/api';
import * as jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
//import { history } from '../../utils/history';

import * as actionTypes from './actionTypes';

export const setCurrentUser = (user) => {
  return{
    type: actionTypes.SET_USER,
    user: user
  }
}

export const setRefreshToken = (user) => {
  return{
    type: actionTypes.SET_REFRESH_TOKEN,
    user: user
  }
}

export const setIsLoading = (isLoading) => {
  return{
    type: actionTypes.SET_IS_LOADING,
    isLoading: isLoading
  }
}

export const setCurrentPharmacy = (pharmacyId, pharmacyName, pharmacy) => {
  return {
    type: actionTypes.CHANGE_PHARMACY,
    pharmacyId: pharmacyId,
    pharmacyName: pharmacyName,
    workspace: pharmacy
  }
}

export const setErrorMessage = (errors) => {
  return{
    type: actionTypes.SET_ERROR,
    errors: errors
  }
}

export const logoutUser = () => {
  return{
    type: actionTypes.LOGOUT
  }
}

export function logout() {
  return dispatch => {
    const refresh_token = Cookies.get('refresh_token').replace(/\\\//g, "/").replace(/"/g, '').replace(/'/g, '');
    const data = { refresh_token: refresh_token }
    return Api.post('api/v1/authentication/logout/', data).then((response) => {
      dispatch(logoutUser());
    }).catch((error) => {
      //console.log(error.data);
      //console.log('authorization error: dispatch logout')
    });
  }
}

export const login = (data) => {
  return dispatch => {
    return Api.post('/api/v1/authentication/login/', data).then((response) => {
      const token = response.access_token;
      Cookies.set('user_token', JSON.stringify(token), {secure: true});
      Cookies.set('refresh_token', JSON.stringify(response.refresh_token), {secure: true});
      dispatch(setCurrentUser(jwt_decode(token)));
      //history.push('/');
    }).catch((error) => {
      console.log('login error');
      console.log(error);
      dispatch(setErrorMessage('Sorry, there was an issue with our server. We have been notified of the issue and will fix it as soon as possible.'));
    });
  }
}

export const refreshAccessToken = () => {
  console.log('refreshAuthentication Called');
  return dispatch => {
    if ( Cookies.get('refresh_token') !== 'undefined' && Cookies.get('refresh_token') !== undefined ) {
      const refresh_token = Cookies.get('refresh_token').replace(/\\\//g, "/").replace(/"/g, '').replace(/'/g, '');
      const data = {
        refresh_token: refresh_token
      }

      return Api.post('api/v1/authentication/refresh/', data).then(
        (response) => {
          console.log('Refresh Response');
          Cookies.set( 'user_token', JSON.stringify(response.access_token), {secure: true} );
          dispatch( setCurrentUser(jwt_decode(response.access_token)) );
        }).catch((error) => {
          console.log('error');
          if ( error ) {
            console.log(error)
            if ( 401 === error.status ) {
              // dispatch login error
              //dispatch(setErrorMessage(error.data.errors));
              dispatch(setErrorMessage('Your session has expired. Pleae login again.'));
            }
          } else {
            console.log('network error');
            //Cookies.remove('user_token')
            dispatch(setCurrentUser(null))
          }

          console.log('refresh logout');
          //Cookies.remove('user_token');
          //Cookies.remove('refresh_token');
          dispatch(setCurrentUser(null));
          //dispatch(setCurrentPharmacy(null, null, null));
        }
      )
    } else {
      console.log('missing cookie')
      //dispatch(setCurrentUser(null))
    }
  }
}

export const checkUser = () => {
  if ( Cookies.get('user_token') ) {
    return true;
  }

  return false;
}