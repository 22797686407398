import React from 'react'

import { NavLink } from 'react-router-dom'
import OpenSavedModal from '../../pages/Dashboard/Modals/OpenSavedModal';

const Navigation = () => {
  return (
    <ul className="sidebar-navigation">
      <li>
        <NavLink to="/" exact><span className="menu-icon icon-Dashboard"></span> Dashboard</NavLink>
      </li>
      <li>
        <NavLink to="/create-note/"><span className="menu-icon icon-NewNote"></span> Create Note</NavLink>
      </li>
      <li>
        <OpenSavedModal />
      </li>
      <li>
        <NavLink to="/custom" exact><span className="menu-icon icon-CustomForm"></span> Custom Templates</NavLink>
      </li>
    </ul>
  )
}

export default Navigation;