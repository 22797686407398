import axios from 'axios';
import Cookies from 'js-cookie';
import apiInterceptor from './api.interceptor';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	paramsSerializer: function(params) { 
		return params;
	}
});

instance.interceptors.response.use(undefined, function (error) {
	throw error;
})

instance.interceptors.response.use(undefined, apiInterceptor(instance));

const request = (url, data, method, dispatch) => {
	//next(action);

	let current_user_token = Cookies.get('user_token');
	//console.log(typeof(current_user_token));
	if ( current_user_token !== 'undefined' && current_user_token !== '' && current_user_token !== null && typeof(current_user_token) !== 'undefined' ) {
		let userToken = JSON.parse( Cookies.get('user_token') );
		instance.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
	}

	var newdata = {
		...data,
		crossdomain: true
	}

	//console.log(url);
	
	return new Promise((resolve, reject) => {
		instance.request({
			method: method,
			url: url,
			data: newdata,
		}).then((response) => {
			//console.log('success response: ' + url);
			//console.log(response)
			resolve(response.data);
		}).catch((error) => {
			console.log('error response: ' + url);
			//console.log(error.response.status);
			console.log(error.response);
			if ( error.response ) {
				//reject(error.response);
			}
			//throw error;
			reject(error);
			//throw new Error('Sorry, there was an issue with our server. We have been notified of the issue and will fix it as soon as possible.');
		});
	});
}

export default {
	fetch: (url, data) => {
		return request(url, data, 'get');
	},

	post: (url, data) => {
		return request(url, data, 'post');
	}
};