import axios from 'axios';
import Cookies from 'js-cookie';

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  
  failedQueue = [];
}

const interceptor = (axiosInstance) => (error) => {
  const _axios = axiosInstance;
  const originalRequest = error.config;

  if ( error.response.status === 401 ) {
    if ( isRefreshing ) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({resolve, reject})
      }).then( token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return _axios.request(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    //const refreshToken = window.localStorage.getItem('refreshToken') || 'refresh123xyz';
    //const refreshToken = Cookies.get('refresh_token') || undefined;
    const refresh_token = Cookies.get('refresh_token').replace(/\\\//g, "/").replace(/"/g, '').replace(/'/g, '');
    return new Promise((resolve, reject) => {
      //window.api.auth.refresh(refreshToken).then(({data}) => {
      axios.post('https://api.beta.qlinicalrx.com/api/v1/authentication/refresh/', {refresh_token: refresh_token}).then((data) => {
        let token = data.data.access_token;

        Cookies.set('user_token', JSON.stringify(token), {secure: true});
        _axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        processQueue(null, token);
        resolve(_axios(originalRequest));
      }).catch((err) => {
        console.log('interceptor logout');
        console.log(err);
        let user_cookie = Cookies.get('user_token');
        if ( user_cookie !== undefined ) {
          let userToken = JSON.parse( Cookies.get('user_token') );
        
          _axios.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
          console.log(err);

          const data = { refresh_token: refresh_token }
          return axios.post('https://api.beta.qlinicalrx.com/api/v1/authentication/logout/', data).then((response) => {
            console.log(response)
            processQueue(err, null);
            reject(err);
          });
        }
      }).catch((error) => {
        console.log(error)
        if ( error.response ) {
          if ( error.response.status === 500 ) {
            console.log('500 error');
          } else if ( error.response.status === 401 ) {
            Cookies.remove('user_token');
            Cookies.remove('refresh_token');

            //history.push('/login');
            //return window.location.href = '/login';
            return Promise.reject('Refresh token has expired');
          }
        }
      }).then(() => { 
        isRefreshing = false 
      })
    })
  } else if ( error.response.status === 500 ) {
    console.log('500 error');
    console.log(error)
    return 'Sorry, there was an issue with our server. We have been notified of the issue and will fix it as soon as possible.';
    //return Promise.reject(error);
  }

  return Promise.reject(error);
};

export default interceptor