import React from 'react';

import classes from './Inputs.module.scss';

const text = (props) => {
  const inputId = props.id;

  let attachedClasses = [
    classes.hide_input,
    props.className,
    'block-field',
  ]

  if (props.show) {
    attachedClasses = [
      classes.inputs,
      props.className,
      'block-field',
      'input-field'
    ]
  }

  return (
    <div id={inputId} className={attachedClasses.join(' ')}>{props.value}</div>
  )
}

export default text;
