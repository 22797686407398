import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import Api from '../../../utils/api';
//import * as actions from '../../../store/actions/index';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const SaveNewTemplateModal = (props) => {
  const[open, setOpen] = useState(false);
  const[pharmacies, setPharmacies] = useState([]);

  const handleClickOutside = e => {
    const addPharmacyModal = document.querySelector('#export-note-modal');

    if (addPharmacyModal) {
      if (addPharmacyModal.contains(e.target)) {
        return;
      }
    }

    setOpen(false); // outside click
  };

  const templateNameChangeHandler = (e) => {
    
  }

  const savePharmacyHandler = () => {
    
  }

  const closeModal = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
      getPharmacies()
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [open]);

  const getPharmacies = () => {
    Api.fetch('/api/v1/pharmacies/').then((response) => {
      setPharmacies(response);
    });
  }

  const options = pharmacies.map((pharmacy) => {
    return {
      value: pharmacy.id, label: pharmacy.pharmacy_name
    }
  })


  return (
    <React.Fragment>
      <div><button className="btn teal-border-btn" onClick={e => setOpen(!open)}>Save as new template</button></div>
      <Modal id="export-note-modal" isOpen={open}>
        <Button className="close" onClick={closeModal}><span className="icon-X_CloseForm"></span></Button>
        <ModalHeader>Save as new template</ModalHeader>
        <ModalBody>
          <p>The note you’ve created can be saved as a new template for future use. Enter your basic template information in order to save.</p>
          <div className="input-container">
            <div className="small-text-format">Template Name</div>
            <input type="text" name="temaplte-name" required onChange={templateNameChangeHandler} />
          </div>

          <div className="input-container">
            <div className="small-text-format">Default Note Name</div>
            <input type="text" name="default-note-name" required onChange={templateNameChangeHandler} />
          </div>

          <div className="input-container">
            <div className="small-text-format">Template Owner</div>
            <Select
              className="select_input"
              options={options}                       
            />
          </div>

          <div className="input-container">
            <div className="small-text-format">Default Values</div>
            <p>Save your documentation notes as default values for future use?</p>
            <div className="radio_input">
              <input type="radio" name="default-values" onChange={templateNameChangeHandler} />
              <label>Save notes as default values</label>
            </div>
            <div className="radio_input">
              <input type="radio" name="default-values" onChange={templateNameChangeHandler} />
              <label>Discard notes and save as blank template</label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="modal-footer-container">
            <Button color="primary" onClick={savePharmacyHandler}>Save</Button>
            <div className="cancel-btn colour-teal" onClick={closeModal}>Cancel</div>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default SaveNewTemplateModal;