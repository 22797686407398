import React, { useEffect, useState, useCallback } from 'react';
import * as actions from '../../../store/actions/index';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

const ChooseWorkspace = () => {
  const[open, setOpen] = useState(true);

  const pharmacies = useSelector(state => state.pharmacies);

  const dispatch = useDispatch();
  const savePharmacy = useCallback(
    (pharmacyId, pharmacyName, pharmacy) => dispatch(actions.savePharmacy(pharmacyId, pharmacyName, pharmacy)),
    [dispatch],
  );

  const closeModal = () => {
    setOpen(false);
  }
  
  const handleClickOutside = e => {
    const workspace_menu = document.querySelector('#choose-workspace-modal');
    //console.log(e.target)
    if (workspace_menu.contains(e.target)) {
      return; // inside click
    }

    closeModal();
  };

  const choosePharmacyHandler = (e) => {
    let chosenPharmacyId = e.target.getAttribute('data-pharmacy-id');

    pharmacies.forEach(pharmacy => {
      if ( chosenPharmacyId === pharmacy.id ) {
        savePharmacy(chosenPharmacyId, e.target.getAttribute('data-pharmacy-name'), pharmacy);
      } 
    });

    closeModal();
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  let modal_content = '';
  if ( pharmacies.length === 0 ) {
    modal_content = <p>You must create a workspace before you may use the app. You can create one at <a href="https://account.beta.qlinicalrx.com/workspaces/" target="_blank" rel="noreferrer noopener">https://account.beta.qlinicalrx.com/workspaces/</a>.</p>;
  } else { 
    const workspaces = pharmacies.map((pharmacy, index) => {
      const uniqueKey = `choose-workspace-${index}`;
      return (
        <li key={uniqueKey} onClick={choosePharmacyHandler} data-pharmacy-id={pharmacy.id} data-pharmacy-name={pharmacy.pharmacy_name}>
          {pharmacy.pharmacy_name}
        </li>
      )
    });

    modal_content = <div><p>Choose the team workspace you would like to view.</p><ul>{workspaces}</ul></div>;
  }

  return (
    <React.Fragment>
      <div className="modal-link" onClick={e => setOpen(!open)}><span className="icon-Plus"></span> Add your organization's invite code</div>
      <Modal id="choose-workspace-modal" className="modal-choose-workspace" isOpen={open}>
          <Button className="close" onClick={closeModal}><span className="icon-X_CloseForm"></span></Button>
          <ModalHeader>Choose a team workspace</ModalHeader>
          <ModalBody>
            {modal_content}
          </ModalBody>
        </Modal>
      </React.Fragment>
  )
}

export default ChooseWorkspace;