import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend';
import Select from 'react-select';
import Cookies from 'js-cookie';

import PageHeader from '../../../layout/PageHeader/PageHeaderCustomTemplate';
import CardTemplate from './CardTemplate/CardTemplate';
import CustomBlockList from '../CustomBlockList/CustomBlockList';
//import Filter from '../../Dashboard/Search/Filters/Filter';
//import Filter from '../../Dashboard/Search/Filters/Filter';
import Search from '../../Dashboard/Search/Search';

import classes from './EditCustomTemplate.module.scss';
//import templateClasses from '../../Template/Template.module.scss';

import Api from '../../../utils/api';
import { getNewRadioValues, removeRadioValues, getNewDateValues, getNewSelectValues, getNewCheckboxValues, removeCheckboxValues, getNewTextValues, removeTextValues, buildBlocksHandler, buildInputsHandler, buildAdminHandler } from '../../../utils/template';
import * as actions from '../../../store/actions/index';
//import settingsClasses from '../../Template/PdfSettingsOutline/PdfSettingsOutline.module.scss';


/**
 * Loads templates for main Dashboard Page
 */
class EditCustomTemplate extends Component {
  state = {
    administrativeBlocks: [],
    blocksData: [],
    template_items: [],
    blocks: {},
    query: null,
    searchFilter: null,
    sort: 'r',
    resultsCount: 0,
    template_name: null,
    editTemplate: false,
    workspaceId: Cookies.get('user_pharmacy_id')
  }

  constructor() {
    super();
    this._isMounted = false;
  }

  componentDidMount() {
    this.getTemplates();
    this.props.getFilters();

    if ( this.props.match.params.id ) {
      this.setState({
        editTemplate: true
      })
      this.loadTemplate();
    } else {
      this.setState({
        blocks: {
          data: [],
          plan: { intervention: [] }
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Retrive current template items
   */
  loadTemplate = () => {
    Api.fetch('/api/v1/pharmacies/save/').then((response) => {
      Api.fetch(`/api/v1/tools/templates/${this.props.match.params.id}/?pharmacy=${response.pharmacy.id}`).then((response) => {
        console.log(response)
        this.setState({
          blocks: { 
            data: response.data, 
            assessment: response.assessment,
            plan: response.plan,
            other: response.other
          },
          template_name: response.title
        });

        if ( response.administrative.length > 0 ) {
          for ( let key in response.administrative ) {
            let setting = response.administrative[key]
            Api.fetch(`/api/v1/tools/administrative/${response.administrative[key].id}/`).then((adminResponse) => {
              setting.blocks = adminResponse
              this.setState((prevState) => {
                return { administrativeBlocks: prevState.administrativeBlocks.concat(setting) }
              })
            })
          }
        }

        this.buildDefaultsHandler();
      
      }).catch((error) => {
        console.log('template logout')
        if ( error.status === 401 ) {
          this.props.logout();
        }
      });
    });
  }

  buildDefaultsHandler = () => {
    this.loopDefaultBlocks(this.state.blocks.data);
    this.loopDefaultBlocks(this.state.blocks.assessment);
    this.loopDefaultBlocks(this.state.blocks.plan.intervention);
    this.loopDefaultBlocks(this.state.blocks.plan.monitoring);
    this.loopDefaultBlocks(this.state.blocks.plan.follow);

    //this.checkData();
  }

  loopDefaultBlocks = (blocks) => {
    blocks.forEach( (block, index) => {
      let fields = block.content;
      this.setDefaultField(block.block_id, fields);
    });
  }

  setDefaultField = (block_id, fields) => {
    fields.forEach( (field, i) => {
      switch ( field.type ) {
        case 'input_radio':
        case 'input_checkbox':
          if ( field.checked )
            this.addToBlocksData(block_id, field.api_id, true, field.name);
          break;
        case 'input_select':
          field.options.forEach( (option) => {
            if ( option.selected )
              this.addToBlocksData(block_id, field.api_id, [option.value]);
          });
          break;
        case 'input_text':
          if ( field.value )
            this.addToBlocksData(block_id, field.api_id, field.value, field.name);
          break;
        case 'input_date':
          if ( field.value )
            this.addToBlocksData(block_id, field.api_id, field.value);
          break;
        case 'input_group':
            let group_fields = field.fields;
            this.setDefaultField(block_id, group_fields);
          break;
        default:
          break;
      }
    });
  }

  addToBlocksData = (block_id, api_id, value, name = null) => {
    let newData = {
      id: block_id,
      input: api_id,
      value: value,
      name: name
    }

    this.setState((prevState) => {
      return { blocksData: prevState.blocksData.concat(newData) }
    })
  }

  /**
   * Retrieve QlilnicalRx templates from API
   */
  getTemplates = () => {
    if (this.state.workspaceId) {
      let url = this.buildQuery();
      Api.fetch(url).then((response) => {
        this.setState({ template_items: response });
      })
    }
  }

  buildQuery = () => {
    let apiQuery = '/api/v1/tools/templates/?pharmacy='+this.state.workspaceId;
    //+'&start='+this.state.start+'&end='+this.state.end;

    if ( this.state.query ) {
      apiQuery += '&q=' + this.state.query;
    }

    if ( this.state.sort ) {
      apiQuery += '&sort=' + this.state.sort;
    }

    return apiQuery;
  }

  setBlocks = (template_id) => {
    Api.fetch(`/api/v1/tools/templates/${template_id}/?pharmacy=${this.state.workspaceId}`).then((response) => {   
      this.setState((prevState) => {
        return { blocks: {
            data: [...prevState.blocks.data, ...response.data ],
            plan: {
              intervention: prevState.blocks.plan.intervention.concat(response.plan.intervention)
            }
          }
        }
      })
    })
  }

  removeBlock = (type, index) => {
    this.setState((prevState) => {
      const newBlocks = prevState.blocks[type].slice(0, index).concat(prevState.blocks[type].slice(index + 1, prevState.blocks[type].length))
      return { 
        blocks: {
          [type]: newBlocks
        }
      }
    })
  }

  searchHandler = (search) => {
    this.setState({ 
      query: search
    });

    let self = this;
    setTimeout(function() {
      self.getTemplates();
    }, 500)
  }

  sortHandler = (sort) => {
    this.setState({ 
      sort: sort
    });

    let self = this;
    setTimeout(function() {
      self.getTemplates();
    }, 500)
  }

  /**
   * Add checkbox value to our block data
   */
  updateCheckboxValue = (target, groupVal = null) => {
    let newData = getNewCheckboxValues(target, groupVal, this.state.blocksData);
    if ( newData ) {
      this.setState((prevState) => {
        return { blocksData: prevState.blocksData.concat(newData) }
      });
    }

    let removeIndexes = removeCheckboxValues(target, groupVal, this.state.blocksData);
    if ( removeIndexes )
      this.removeGroupValues(removeIndexes, this.state.blocksData);

    this.checkData();
  }

  /**
   * Add select value to our block data
   */
  updateSelectValueHandler = (selectedOption, apiId, blockId) => {
    let newData = getNewSelectValues(selectedOption, apiId, blockId, this.state.blocksData);
    if ( newData ) {
      this.setState((prevState) => {
        return { blocksData: prevState.blocksData.concat(newData) }
      })
    }

    this.checkData()
  }

  /** 
   * Add input text value to our block data
   * */
  updateTextValueHandler = (e) => {
    const target = e.target;
    let newData = getNewTextValues(target, this.state.blocksData);
    if ( newData ) {
      this.setState((prevState) => {
        return { blocksData: prevState.blocksData.concat(newData) }
      })
    }

    // remove blank value
    if ( target.value === '' ) {
      const index = removeTextValues(target, this.state.blocksData);
      if ( index > -1 ) {
        const arr = this.state.blocksData;
        const updatedArr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
        this.setState((prevState) => {
          return { blocksData: updatedArr }
        });
      }
    }

    this.checkData()
  }

  /**
   * Add date value to our block data
   */
  updateDateHandler = (date, blockId, apiId) => {
    let newData = getNewDateValues(date, blockId, apiId, this.state.blocksData);
    if ( newData ) {
      this.setState((prevState) => {
        return { blocksData: prevState.blocksData.concat(newData) }
      })
    }
      
    this.checkData()
  }

  /**
   * Add radio value to our block data
   */
  updateRadioValueHandler = (target) => {
    let removeIndexes = removeRadioValues(target, this.state.blocksData);
    if ( removeIndexes )
      this.removeGroupValues(removeIndexes, this.state.blocksData);

    let newData = getNewRadioValues(target, this.state.blocksData);
  
    this.setState((prevState) => {
      return { blocksData: prevState.blocksData.concat(newData) }
    });
  
    this.checkData();
  }

  removeGroupValues = (removeIndexes, arr) => {
    if ( removeIndexes.length > 0 ) {
      for (var i = removeIndexes.length -1; i >= 0; i--) {
        arr.splice(removeIndexes[i],1);
      }

      this.setState(() => {
        return { blocksData: arr }
      });
    }
  }

  saveHandler = () => {
    const dataBlocks = buildBlocksHandler(this.state.blocks.data, this.state.blocks.assessment, this.state.blocks.plan.intervention, 
                                          this.state.blocks.plan.monitoring, this.state.blocks.plan.follow, this.state.blocks.other);
    const dataInputs = buildInputsHandler(this.state.blocksData);
    const adminGroups = buildAdminHandler(this.state.administrativeBlocks);

    const data = {
      pharmacy: this.state.workspaceId,
      template_title: this.state.template_name,
      blocks: dataBlocks,
      inputs: dataInputs,
      admin_groups: adminGroups,
    }

    Api.post('/api/v1/tools/templates/custom/save/', data).then((response) => {
      this.setState({
        saved: true,
        recovery_code: response.recovery_code
      })
    })
  }

  checkData = () => {
    let that = this;
    setTimeout(function() {
      console.log(that.state.blocksData);
    }, 500)
  }

  /**
   * Output our page
   */
  render() {
    let template_content = null;
    if ( Object.keys(this.state.blocks).length === 0 ) {
      template_content = <div>
                            <CustomBlockList 
                              blocks={this.state.blocks}
                              header="Data"
                              removeBlock={this.removeBlock}
                              type={'data'}
                              checkboxChanged={this.updateCheckboxValue}
                              dateChanged={this.updateDateHandler}
                              radioChanged={this.updateRadioValueHandler}
                              selectChanged={this.updateSelectValueHandler}
                              textChanged={this.updateTextValueHandler}
                              saveHandler={this.saveHandler}
                            />
                            <p>You have no items on your template.</p><p>Click templates on the right to add your custom template.</p>
                          </div>;
    } else {
      template_content = <div>
                            <CustomBlockList 
                              blocks={this.state.blocks.data}
                              header="Data"
                              removeBlock={this.removeBlock}
                              type={'data'}
                              checkboxChanged={this.updateCheckboxValue}
                              dateChanged={this.updateDateHandler}
                              radioChanged={this.updateRadioValueHandler}
                              selectChanged={this.updateSelectValueHandler}
                              textChanged={this.updateTextValueHandler}
                              saveHandler={this.saveHandler}
                            />
                            <CustomBlockList 
                              blocks={this.state.blocks.assessment}
                              header="Assessment"
                              removeBlock={this.removeBlock}
                              type={'assessement'}
                              checkboxChanged={this.updateCheckboxValue}
                              dateChanged={this.updateDateHandler}
                              radioChanged={this.updateRadioValueHandler}
                              selectChanged={this.updateSelectValueHandler}
                              textChanged={this.updateTextValueHandler}
                              saveHandler={this.saveHandler}
                            />
                            <CustomBlockList 
                              blocks={this.state.blocks.plan.intervention}
                              header="Plan: Intervention"
                              removeBlock={this.removeBlock}
                              type={'plan'}
                              saveHandler={this.saveHandler}
                            />
                          </div>
    }

    const add_templates = this.state.template_items.map( (template, i) => {
      let template_key = 'template-' + i;
      return <CardTemplate 
              key={template_key}
              id={template.id}
              creator={template.creator}
              filters={template.filters}
              name={template.title}
              setBlocks={this.setBlocks} />
    });

    let pageHeaderName = 'New Custom Template';
    if ( this.state.editTemplate )
      pageHeaderName = 'Edit Custom Template';

    return (
      <div className="custom-templates" data-test="component-custom-templates">
        <Row>
          <Col sm="11" lg="5" xl="6">
            <PageHeader
              name={pageHeaderName}
              save={this.saveHandler}
            />
          </Col>
        </Row>
       
        <Row className={classes.template_section}>
          <Col md="8" xl="7">
            <div className={classes.basic_information}>
              <div className={classes.section_name}>Basic Information</div>

              <Row>
                <Col md="6">
                  <label className="small-text-format small-header text-uppercase colour-teal">Template Name</label><br />
                  <input type="text" defaultValue={this.state.template_name}/>
                </Col>

                <Col md="6">
                  <label className="small-text-format small-header text-uppercase colour-teal">Default Note Name</label><br />
                  <input type="text" />
                </Col>

                <Col md="6">
                  <label className="small-text-format small-header text-uppercase colour-teal">Template Owner</label><br />
                  <Select
                    id="template-owner"
                    data-test="input-select"
                    className="select_input"
                    options={['test1', 'test2']}
                    onChange={this.selecValueHandler}
                  />
                </Col>
              </Row>
            </div>

            <div className={classes.template_content}>
              <div>
                <div className={classes.section_name}>Template Content</div>
                <hr />
              </div>

              <DndProvider backend={Backend}>
                <div className={classes.edit_container}>
                  {template_content}
                </div>
              </DndProvider>
            </div>

            <div id="pdf-settings-content" className={classes.pdf_content}>
                <div>
                  <div className={classes.section_name}>PDF Settings</div><hr />
                </div>
                <p>Custom which PDF Settings template users will see first when create notes.</p>
                <Select
                    className="select_input"
                    options={[{ label: 'By Popularity', value: 'By Poularity'}, { label: 'Option 2', value: 'Option 2'}]}
                  />
              </div>
          </Col>

          <Col md="4" xl="5">
            <div className={classes.template_content}>
              <div className={classes.section_name}>Add a template</div>
              <hr />
              <Search 
                search={this.searchHandler} 
                sort={this.sortHandler}
                searchFilter={this.state.searchFilter}
                sortFilter={this.state.sort}
                size="full"
              />
              <div style={{marginTop:'2rem'}}>
                {add_templates}
              </div>
            </div>
          </Col>
        </Row>
        
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    searchFilters: state.searchFilters,
    workspace: state.workspace
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFilters: () => dispatch( actions.getFilters() )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomTemplate);