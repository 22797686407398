import React, { useEffect } from 'react';

import { NavLink } from 'react-router-dom';

import Navigation from './Navigation';
import BottomNavigation from './BottomNavigation';
import UserProfile from '../../pages/Dashboard/UserProfile/UserProfile';
import OpenSavedModal from '../../pages/Dashboard/Modals/OpenSavedModal';

import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as LogoIcon } from '../../images/logo_icon.svg';

const sidebar = (props) => {
  const sidebarClasses = [
    //classes.sidebar
    'sidebar'
  ];

  const handleMenuHover = (e) => {
    const toolTip = e.target.parentNode.parentNode.querySelector('.tool-tip');
    if ( toolTip )
      toolTip.classList.add('open')
  }

  const handleMenuUnHover = (e) => {
    const toolTip = e.target.parentNode.parentNode.querySelector('.tool-tip');
    if ( toolTip )
      toolTip.classList.remove('open')
  }

  useEffect(() => {
    const paperFormatEls = document.querySelectorAll('.sidebar-link span')
    paperFormatEls.forEach((el) => {
      el.addEventListener( 'mouseover', handleMenuHover )
      el.addEventListener( 'mouseout', handleMenuUnHover )
    })

    return () => {
      paperFormatEls.forEach((el) => {
        el.removeEventListener( 'mouseover', handleMenuHover )
        el.removeEventListener( 'mouseout', handleMenuUnHover )
      })
    }
  }, [])

  return (
    <div id="sidebar" className={sidebarClasses.join(' ')}>
      <div className="sidebar_container">
        <div className="logo-large">
          <NavLink to="/" exact><Logo /></NavLink>
        </div>

        <div className="nav-container">
          <div className="user-block">
            <UserProfile />
          </div>
          
          <Navigation />
          
          <button className="upgrade-btn">
            <a href="https://account.qlinicalrx.com/subscribe/upgrade/">
              <span className="icon-Upgrade_1 icon-font"></span>Upgrade
            </a>
          </button>
          
          <BottomNavigation collapse={props.collapse} />
        </div>
      </div>
      <div className="sidebar_container collapsed_menu">
        <ul className="logo-small">
          <li><NavLink to="/" exact><LogoIcon /></NavLink></li>
        </ul>
        <ul className="user-navigation">
          <li className="sidebar-link">
            <UserProfile />
          </li>
        </ul>
        <ul className="sidebar-navigation">
          <li className="sidebar-link">
            <NavLink to="/" exact><span className="icon-Dashboard"></span></NavLink>
            <div className="tool-tip">Dashboard</div>
          </li>
          <li className="sidebar-link">
            <NavLink to="/create-note" ><span className="icon-NewNote"></span></NavLink>
            <div className="tool-tip">Create Note</div>
          </li>
          <li className="sidebar-link">
            <OpenSavedModal />
            <div className="tool-tip">Open Saved</div>
          </li>
          <li className="sidebar-link">
            <NavLink to="/custom" exact><span className="icon-CustomForm"></span></NavLink>
            <div className="tool-tip">Custom Templates</div>
          </li>
          <li className="sidebar-link">
            <NavLink to="/login" exact><span className="icon-CustomForm"></span></NavLink>
          </li>
        </ul>
        <ul className="sidebar-navigation upgrade-nav">
          <li className="sidebar-link upgrade-btn">
            <a href="https://account.qlinicalrx.com/subscribe/upgrade/">
              <span className="icon-Upgrade_1 icon-font"></span>
              <div className="tool-tip">Upgrade</div>
            </a>
          </li>
        </ul>
        <ul className="bottom-navigation">
          <li className="sidebar-link">
            <a href="/help/support">
              <span className="icon-Help"></span>
              <div className="tool-tip">Help</div>
            </a>
          </li>
          <li className="sidebar-link" onClick={props.collapse}>
            <button>
              <span className="icon-Expand"></span>
              <div className="tool-tip">Expand</div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default sidebar;