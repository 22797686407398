import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ItemTypes from '../EditCustomTemplate/ItemTypes';
//import { Row, Col } from 'reactstrap'

import InputCheckbox from '../../Template/BlocksList/Inputs/InputCheckbox';
import InputDate from '../../Template/BlocksList/Inputs/InputDate';
import InputDateTime from '../../Template/BlocksList/Inputs/InputDateTime';
import InputTime from '../../Template/BlocksList/Inputs/InputTime';
import InputRadio from '../../Template/BlocksList/Inputs/InputRadio';
import InputText from '../../Template/BlocksList/Inputs/InputText';
import InputSelect from '../../Template/BlocksList/Inputs/InputSelect';
import InputGroup from '../../Template/BlocksList/Inputs/InputGroup';
import Text from '../../Template/BlocksList/Inputs/Text';
import InputTextMultiline from '../../Template/BlocksList/Inputs/InputTextMultiline';

import classes from './CustomBlock.module.scss';

const customBlock = (props) => {
  const ref = useRef(null);
  let blockFields = null;
  const [editDefaultValueMode, setEditDefaultValueMode] = useState(false);

  const saveHandler = () => {
    setEditDefaultValueMode(false);
    props.saveHandler();
  }

  if ( props.fields ) {
    let disabled = true;
    if ( editDefaultValueMode === true ) {
      disabled = false;
    }

    blockFields = props.fields.map((field, index) => {
      let fieldVar;
      let fieldKey = 'field-' + index;
      let show = true;

      switch(field.type) {
        case 'input_text':
          fieldVar = <InputText 
                        key={fieldKey}
                        id={field.id}
                        apiId={field.api_id} 
                        belongsToInputGroup={false}
                        blockId={props.blockId}
                        changed={props.textChanged}
                        className={fieldKey}
                        disabled={disabled}
                        events={field.events}
                        eventsChanged={props.eventHandler}
                        eventTargetHandler={props.eventTargetHandler}
                        label={field.label}
                        labelAfter={field.label_after}
                        labelBefore={field.label_before}
                        labelPosition={props.labelPosition}
                        name={field.name} 
                        show={show}
                        validation={field.validation}
                        value={field.value}
                      />
          break;
        case 'text':
          fieldVar = <Text 
                        key={fieldKey}
                        id={field.id}  
                        className={fieldKey}
                        show={show}
                        value={field.value} />
                        
          break;
        case 'input_radio':
          fieldVar = <InputRadio
                        key={fieldKey}
                        id={field.id} 
                        apiId={field.api_id}
                        blockId={props.blockId}
                        changed={props.radioChanged}
                        checked={field.checked}
                        disabled={disabled}
                        events={field.events}
                        eventsChanged={props.eventHandler}
                        label={field.label}
                        labelPosition={props.labelPosition}
                        name={field.name}
                        value={field.api_id} /> 

          break;
        case 'input_checkbox':
          fieldVar = <InputCheckbox
                        key={fieldKey}
                        id={field.id} 
                        apiId={field.api_id}
                        blockId={props.blockId}
                        changed={props.checkboxChanged}
                        checked={field.checked}
                        customTemplate={false}
                        disabled={disabled}
                        events={field.events}
                        eventsChanged={props.eventHandler}
                        eventTargetHandler={props.eventTargetHandler}
                        label={field.label} 
                        labelBefore={field.label_before}
                        labelAfter={field.label_after}
                        labelPosition={props.labelPosition}
                        name={field.name}
                        value={field.api_id} />
          break;
        case 'input_select':
          fieldVar = <InputSelect 
                      key={fieldKey}
                      id={field.id}
                      apiId={field.api_id}
                      blockId={props.blockId}
                      changed={props.selectChanged}
                      disabled={disabled}
                      label={field.label}
                      labelPosition={props.labelPosition}
                      options={field.options}
                      />
          break;
        case 'input_group':
          fieldVar = <InputGroup 
                        key={fieldKey}
                        id={field.id} 
                        blockId={props.blockId}
                        labelPosition={props.labelPosition}
                        fields={field.fields}
                        checkboxChanged={props.checkboxChanged}
                        disabled={disabled}
                        removeCheckbox={props.removeCheckbox}
                        radioChanged={props.radioChanged}
                        textChanged={props.textChanged}
                        selectChanged={props.selectChanged}
                        dateChanged={props.dateChanged}
                        eventsChanged={props.eventHandler}
                        eventTargetHandler={props.eventTargetHandler} />
              
          break;
        case 'input_text_multiline':
          fieldVar = <InputTextMultiline 
                        key={fieldKey}
                        id={field.id} 
                        apiId={field.api_id} 
                        blockId={props.blockId}
                        changed={props.textChanged}
                        disabled={disabled}
                        fields={field.fields}
                        labelPosition={props.labelPosition}
                        label={field.label} 
                        labelBefore={field.label_before}
                        labelAfter={field.label_after}
                        name={field.name}
                        value={field.value} />
          break;
        case 'input_date':
            fieldVar = <InputDate
                        key={fieldKey}
                        id={field.id} 
                        apiId={field.api_id} 
                        blockId={props.blockId}
                        disabled={disabled}
                        events={field.events}
                        eventsChanged={props.dateChanged}
                        eventTargetHandler={props.eventTargetHandler}
                        fields={field.fields}
                        label={field.label} 
                        labelBefore={field.label_before}
                        labelAfter={field.label_after}
                        value={field.value} />
          break;
        case 'input_datetime':
          //console.log(field);
          fieldVar = <InputDateTime
                      key={fieldKey}
                      id={field.id} 
                      apiId={field.api_id} 
                      blockId={props.blockId}
                      disabled={disabled}
                      events={field.events}
                      eventsChanged={props.dateChanged}
                      eventTargetHandler={props.eventTargetHandler}
                      fields={field.fields}
                      label={field.label} 
                      labelBefore={field.label_before}
                      labelAfter={field.label_after}
                      value={field.value} />
        break;
        case 'input_time':
          console.log(field);
          fieldVar = <InputTime
                      key={fieldKey}
                      id={field.id} 
                      apiId={field.api_id} 
                      blockId={props.blockId}
                      disabled={disabled}
                      events={field.events}
                      eventsChanged={props.dateChanged}
                      eventTargetHandler={props.eventTargetHandler}
                      fields={field.fields}
                      label={field.label} 
                      labelBefore={field.label_before}
                      labelAfter={field.label_after}
                      value={field.value} />
        break;
        case 'medications':
          console.log(field);
        break;
        default:
          // do nothing
          break;
      }
      return fieldVar;
    });
  }

  let blockId = 'block-' + props.blockId

  /*const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.BLOCK },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })*/

  const [, drop] = useDrop({
    accept: ItemTypes.BLOCK,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = props.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  let index = props.index
  let id = props.blockId
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.BLOCK, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  let defaultValue = <span className={classes.default_value} onClick={ e => (setEditDefaultValueMode(true))}>Edit Default Values</span>;
  if ( editDefaultValueMode === true ) {
    defaultValue = <span className={classes.default_value} onClick={ e => saveHandler() }>Save Default Values</span>
  }

  const style = {
    
  }

  const removeBlock = () => {
    props.removeBlock(props.type, index)
  }

  return (
    <div className={classes.customBlock} id={blockId} ref={ref} style={{...style, opacity}} data-test="component-custom-block">
      <div className="close" onClick={ (e) => removeBlock() }><span className={[classes.closeIcon, 'icon-X_Bigger'].join(' ')}></span></div>
      <div className={classes.colRow}>
        <div className={classes.colLeft}>
          <span className={[classes.dragIcon, 'icon-DragIcon'].join(' ')}></span>
        </div>
        <div className={classes.colRight}>
          <h4>{props.name}</h4>{defaultValue}
          {blockFields}
        </div>
      </div>
      
    </div>
  )
}

export default customBlock;