import React, { Component } from 'react';

import inputClasses from '../Inputs.module.scss';

class InputTextText extends Component {
  state = {
    isChecked: this.props.fields[0].checked,
    events: this.props.events,
    isSingleCheck: false
  }

  eventHandler = ( event ) => {
    if ( event.target.checked ) {
      if ( this.state.events.length ) {
        for (let i=0; i< this.state.events.length; i++) {
          this.props.eventsChanged(this.state.events[i], 'radio', true)
        }
      }
    }
  }

  radioChanged = (e) => {
    const target = e.target;
    //this.eventHandler(target);
    this.props.radioChanged(target, 'group');
    this.setState({ isChecked: true })
  }

  textChanged = (event) => {
    if ( this.props.fields[1].validation.required === true ) {
      if ( event.target.value === null || event.target.value === '' )
        event.target.classList.add('invalid');
    }

    if ( event.target.value !== '' && this.state.isChecked === false ) {
      //this.setState({ isChecked: true })
      this.inputElement.checked = true;
      this.props.radioChanged(this.inputElement, 'group');
    } else if ( event.target.value === '' && this.state.isChecked === true ) {
      //this.setState({ isChecked: false })
      /*var self = this;
      setTimeout(function() {
        //self.props.removeCheckbox(self.inputElement);
      }, 1000)*/
    }

    this.props.textChanged(event);
  }

  render() {
    let fieldClasses = [
      inputClasses.group_item,
      'group-item'
    ];

    let textLabelBefore1 = '',
        textLabelAfter1 = '',
        textLabel1 = '',
        textLabelBefore2 = '',
        textLabelAfter2 = '',
        textLabel2 = '',
        classes = [],
        firstFieldKey = 0,
        secondFieldKey = 1;

    const textInputId1 = this.props.blockId + '_' + this.props.fields[firstFieldKey].api_id;
    const textInputId2 = this.props.blockId + '_' + this.props.fields[secondFieldKey].api_id;

    // First Input Text
    if ( this.props.fields[firstFieldKey].labelBefore ) {
      textLabelBefore1 = <span className="label-before">{this.props.fields[firstFieldKey].labelBefore}</span>
    } else {
      classes.push('no-label-before');
    }

    if ( this.props.fields[firstFieldKey].labelAfter ) {
      textLabelAfter1 = <span className="label-after">{this.props.fields[firstFieldKey].labelAfter}</span>
    } else {
      classes.push('no-label-after');
    }

    if ( this.props.fields[firstFieldKey].label ) {
      if ( this.props.fields[firstFieldKey].labelPosition === 'top' ) {
        textLabel1 = <div className="top-label"><label htmlFor={this.props.fields[firstFieldKey].name}>{this.props.fields[firstFieldKey].label}</label></div>
      } else {
        textLabel1 = <label htmlFor={textInputId1}>{this.props.fields[firstFieldKey].label}</label>
      }
    }

    // Second Input Text
    if ( this.props.fields[1].labelBefore ) {
      textLabelBefore2 = <span className="label-before">{this.props.fields[1].labelBefore}</span>
    } else {
      classes.push('no-label-before');
    }
    
    if ( this.props.fields[1].labelAfter ) {
      textLabelAfter2 = <span className="label-after">{this.props.fields[1].labelAfter}</span>
    } else {
      classes.push('no-label-after');
    }
    
    if ( this.props.fields[1].label ) {
      if ( this.props.fields[1].labelPosition === 'top' ) {
        textLabel2 = <div className="top-label"><label htmlFor={this.props.fields[1].name}>{this.props.fields[1].label}</label></div>
      } else {
        textLabel2 = <label htmlFor={textInputId2}>{this.props.fields[1].label}</label>
      }
    }

    return (
      <div className={fieldClasses.join(' ')}>
        <div className="text-input">
          {textLabelBefore1}
          {textLabel1}
          <input
            id={textInputId1}
            name={this.props.fields[firstFieldKey].name}
            type="text" 
            className={classes.join(' ')}
            data-test="input-text"
            data-api-id={this.props.fields[firstFieldKey].api_id}
            disabled={this.props.disabled}
            onChange={this.textChanged}
            data-block-id={this.props.blockId}
            minLength={this.props.fields[firstFieldKey].validation.min_length}
            maxLength={this.props.fields[firstFieldKey].validation.max_length}
            required={this.props.fields[firstFieldKey].validation.required}
            defaultValue={this.props.fields[firstFieldKey].value}
            />
          {textLabelAfter1}
        </div>

        <div className="text-input">
          {textLabelBefore2}
          {textLabel2}
          <input
            id={textInputId2}
            name={this.props.fields[1].name}
            type="text" 
            className={classes.join(' ')}
            data-test="input-text"
            data-api-id={this.props.fields[1].api_id}
            disabled={this.props.disabled}
            onChange={this.textChanged}
            data-block-id={this.props.blockId}
            minLength={this.props.fields[1].validation.min_length}
            maxLength={this.props.fields[1].validation.max_length}
            required={this.props.fields[1].validation.required}
            defaultValue={this.props.fields[1].value}
            />
          {textLabelAfter2}
        </div>
      </div>
    )
  }
}

export default InputTextText;