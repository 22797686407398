import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";

import { Row } from 'reactstrap';

import classes from '../Template.module.scss'
import "react-datepicker/dist/react-datepicker.css";

const noteHeader = ({ name, noteId, dateObj, setDateHandler }) => {
  const [startDate, setStartDate] = useState(null)
  const [noteHeaderName, setNoteHeaderName] = useState(name)

  useEffect(() => {
    if ( dateObj ) {
      const templateDate = dateObj.year + '-' + (dateObj.month+1) + '-' + dateObj.day + ' ' + dateObj.hour + ':' + dateObj.minute;
      setStartDate( new Date(templateDate) )
    }
  }, [dateObj])

  useEffect(() => {
    if ( startDate ) {
      const dateStr = startDate.getFullYear() + '-' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '-' + ("0" + startDate.getDate()).slice(-2) + ' ' + startDate.getHours() + ':' + startDate.getMinutes();
      setDateHandler(dateStr) 
    }
  }, [startDate])

  useEffect(() => {
    setNoteHeaderName(name)
  }, [name])

  useEffect(() => {
    if ( document.getElementById('note-header-name-input').style.display === 'none' && document.getElementById('note-header-name-input').length > 0 )
      document.addEventListener("mousedown", handleClickOutside)
  }, [setNoteHeaderName])

  const handleClickOutside = e => {
    const textInput = document.getElementById('note-header-name-input');
    if ( textInput && textInput.contains(e.target) ) {
      return; // inside click
    }

    document.getElementById('note-header-name').style.display = 'block';
    document.getElementById('note-header-name-input').style.display = 'none';
  };

  const editNoteNameHandler = () => {
    document.getElementById('note-header-name').style.display = 'none';
    document.getElementById('note-header-name-input').style.display = 'block';
  }

  const editNoteNameTextHandler = (e) => {
    setNoteHeaderName(e.target.value)
  }

  let noteInputStyle = {
    display: 'none'
  }

  return( 
    <Row className={classes.note_header}>
      <div className={classes.note_name}>
        <div className={['small-text-format', 'small-header', classes.small_header].join(' ')}>Note Name</div>
        <h2>
          <span id="note-header-name">{noteHeaderName}</span>
          <input id="note-header-name-input" type="text" value={noteHeaderName} onChange={editNoteNameTextHandler} style={noteInputStyle} />
        </h2>
        <span className={['icon-Edit', classes.edit_icon].join(' ')} onClick={editNoteNameHandler}></span>
      </div>
      <div className={classes.note_date}>
        <div className={['small-text-format', 'small-header', classes.small_header].join(' ')}>Date & Time Note Created</div>
        <div className={classes.note_header_text}>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect
            timeCaption="Time"
            dateFormat="yyyy-MM-dd H:mm"
            className={classes.datepicker}
            />
            <span className={['icon-Edit', classes.edit_icon].join(' ')}></span>
        </div>

        <div className={classes.mobile_header}>
          <div className={['small-text-format', 'small-header', classes.small_header].join(' ')}>Note ID</div>
          <div className={classes.note_header_text}>{noteId}</div>
        </div>

      </div>
      <div className={classes.full_header}>
        <div className={['small-text-format', 'small-header', classes.small_header].join(' ')}>Note ID</div>
        <div className={classes.note_header_text}>{noteId}</div>
      </div>
    </Row>
  )
}

export default noteHeader;