import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const plainTextOutputModal = (props) => {
  const workspace = useSelector(state => state.workspace);
  const node = useRef(null);
  const toolTipRecoveryNode = useRef(null);
  const toolTipTextNode = useRef(null);
  const nodeOutput = useRef(null);
  const[open, setOpen] = useState(false);
  //const[recoveryCode, setRecoveryCode] = useState(null)
  //const[copied, setCopied] = useState(false);

  const closeModal = () => {
    setOpen(false);
    document.querySelector('#export-note-modal').style.display = '';
  }

  const exportModal = () => {
    props.exportHandler(workspace.text_output);
    setTimeout(() => {
      setOpen(true);
    }, 1000)
  }

  const copyRecoveryCode = () => {
    node.current.select();
    document.execCommand('copy');

    toolTipRecoveryNode.current.style.display = 'block';
    setTimeout(() => {
      toolTipRecoveryNode.current.style.display = 'none';
    }, 2000);
  }

  const copyOutputText = () => {
    var node = document.getElementById('content-selectable');
    var range = document.body.createTextRange();
    if ( document.selection ) {
      range.moveToElementText( node  );
      range.select();
    } else if ( window.getSelection ) {
      range.selectNodeContents( node );
      window.getSelection().removeAllRanges();
      window.getSelection().addRange( range );
    }

    document.execCommand('copy');
    
    toolTipTextNode.current.style.display = 'block';
    setTimeout(() => {
      toolTipTextNode.current.style.display = 'none';
    }, 2000);
  }

  const handleClickOutside = e => {
    const parentModal = document.querySelector('#export-note-modal')

    if (parentModal) {
      if (parentModal.contains(e.target)) {
        return
      }
    }

    //setPdfOpen(false); // outside click
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
      document.querySelector('#export-note-modal').style.display = 'none'
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [open]);

  let contentEditableClasses = ['content-editable'];
  if ( 'plain_text' === workspace.text_output ) {
    contentEditableClasses.push('plain-text');
  } else if ( 'rich_text' === workspace.text_output ) {
    contentEditableClasses.push('rich-text');
  }

  return (
    <React.Fragment>
      <div className="btn export-btn" onClick={e => exportModal()}>
        <span className="icon-text"></span>
        Output as Text
      </div>
      <Modal id="output-text-modal" isOpen={open}>
        <Button className="close" onClick={closeModal}><span className="icon-X_CloseForm"></span></Button>
        <ModalHeader>Your note has been converted to text!</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              <div ref={nodeOutput} id="content-selectable" className={contentEditableClasses.join(' ')}  dangerouslySetInnerHTML={{ __html: props.outputData }} contentEditable="false"></div>
            </Col>
            <Col xs="12">
              <Button color="primary" className="btn-full-width" onClick={copyOutputText}>Copy Text</Button>
              <div ref={toolTipTextNode} className="tool-tip">Copied!</div>
            </Col>
            <Col xs="12"><hr /></Col>
            <Col xs="12"><p>Use the code below to recover your note at any point.</p></Col>
            <Col xs="12"><label className="small-text-format small-header text-uppercase colour-teal" htmlFor="recovery-code">Note Recovery Code</label><br /></Col>
            <Col xs="7">
              <input ref={node} className="recovery-code-input" name="recovery-code" type="text" defaultValue={props.recoveryCode} readOnly />
              <div ref={toolTipRecoveryNode} className="tool-tip">Copied!</div>
            </Col>
            <Col xs="4">
              <Button onClick={copyRecoveryCode} color="primary">Copy Code</Button>
            </Col>
          </Row>

          <Row>
            <Col xs="12"><hr /></Col>
            <Col xs="6"><Button color="primary" className="btn-full-width" onClick={closeModal}>Export Another Output</Button></Col>
            <Col xs="6"><NavLink to="/" exact className="btn btn-full-width">Go To Dashboard</NavLink></Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default plainTextOutputModal