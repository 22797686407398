import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import ChangeWorkspace from '../ChangeWorkspace/ChangeWorkspace';

import classes from './UserMenu.module.scss';

class UserMenu extends Component {

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  handleClickOutside = e => {
    if ( this.node.contains(e.target) )
      return; // inside click

    this.props.closeMenu();
  };

  logoutHandler = () => {
    this.props.logout();
  }

  render() {
    const mainClass = [
      classes.submenu
    ];

    if (this.props.show)
      mainClass.push(classes.open);

    return (
      <React.Fragment>
        <div ref={node => this.node = node} id="user-menu" className={mainClass.join(' ')}>
          <ul>
            <ChangeWorkspace userId={this.props.userId} />
          </ul>
          <ul>
            <li>
              <a href={process.env.REACT_APP_ACCOUNT_URL} target="_blank" rel="noreferrer noopener">
                Account Settings
                <span className="small-icon icon-opennew"></span>
              </a>
            </li>
            <li>
              <a href={process.env.REACT_APP_ACCOUNT_URL} target="_blank" rel="noreferrer noopener">
                Team Settings
                <span className="small-icon icon-opennew"></span>
              </a>
            </li>
          </ul>
          <ul>
            <li onClick={this.logoutHandler}><div>Log Out</div></li>
          </ul>
        </div>
        
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    userId: state.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);