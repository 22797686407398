import React, { useEffect, useState, useCallback } from 'react';
//import Api from '../../../../utils/api';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import * as jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

import Workspace from './Workspace/Workspace';
import classes from './ChangeWorkspace.module.scss';

const ChangeWorkspace = () => {
  const workspace = useSelector(state => state.workspace);
  //const pharmacies = useSelector(state => state.pharmacies);
  const pharmacies = jwt_decode(Cookies.get('user_token')).pharmacies;
  //console.log(pharmacies);


  const[open, setOpen] = useState(false);

  const handleClickOutside = e => {
    const workspace_menu = document.querySelector('.workspace-menu');
    //console.log(e.target)
    if (workspace_menu.contains(e.target)) {
      //console.log('inside');
      return; // inside click
    }

    workspace_menu.style.display = 'none';
  };

  const changePharmacyHandler = (e) => {
    let chosenPharmacyId = e.target.parentNode.getAttribute('data-pharmacy-id');

    pharmacies.forEach(pharmacy => {
      if ( chosenPharmacyId === pharmacy.id ) {
        savePharmacy(e.target.parentNode.getAttribute('data-pharmacy-id'), e.target.parentNode.getAttribute('data-pharmacy-name'), pharmacy);
      } 
    });
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      getSavedPharmacy();
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const dispatch = useDispatch();
  const savePharmacy = useCallback(
    (pharmacyId, pharmacyName, pharmacy) => dispatch(actions.savePharmacy(pharmacyId, pharmacyName, pharmacy)),
    [dispatch],
  );

  const getSavedPharmacy = useCallback(() => dispatch(actions.getSavedPharmacy()),
    [dispatch],
  );
  
  // Prsonal Workspaces
  /*const personalWorkspaces = pharmacies.map((pharmacy, index) => {
    if ( 'personal' === pharmacy.user_type ) {
      const uniqueKey = `p-workspace-${index}`;
      
      let selected = false;
      if ( workspace !== null ) {
        if ( workspace.id === pharmacy.id ) {
          selected = true;
        }
      }

      return (
        <Workspace
          data-test="component-workspace"
          key={uniqueKey}
          id={pharmacy.id}
          name={pharmacy.pharmacy_name}
          changeHandler={changePharmacyHandler} 
          selected={selected}
        /> 
      )
    } else {
      return null;
    }
  });*/

  const personalWorkspaces = pharmacies.filter(function(pharmacy, index) {
    if ( 'personal' === pharmacy.user_type ) {
      return true;
    }
    return false;
  }).map(function(pharmacy, index) { 
    const uniqueKey = `p-workspace-${index}`;
    
    let selected = false;
    if ( workspace !== null ) {
      if ( workspace.id === pharmacy.id ) {
        selected = true;
      }
    }

    return <Workspace
              data-test="component-workspace"
              key={uniqueKey}
              id={pharmacy.id}
              name={pharmacy.pharmacy_name}
              changeHandler={changePharmacyHandler} 
              selected={selected}
              />
  });

  const teamWorkspaces = pharmacies.filter(function(pharmacy) {
    if ( 'member' === pharmacy.user_type || 'owner' === pharmacy.user_type ) {
      return true
    }
    return false;
  }).map(function(pharmacy, index) { 
    const uniqueKey = `m-workspace-${index}`;
      
      let selected = false;
      if ( workspace !== null ) {
        if ( workspace.id === pharmacy.id )
          selected = true;
      }

      return <Workspace
                data-test="component-workspace"
                key={uniqueKey}
                id={pharmacy.id}
                name={pharmacy.pharmacy_name}
                changeHandler={changePharmacyHandler} 
                selected={selected}
                />  
  });

  let workspace_menu_style = {};
  if (open) {
    workspace_menu_style = {
      'display': 'block'
    }
  }

  let personal_workspace_menu_style = {'display': 'block'};
  if ( personalWorkspaces.length !== 0 ) {
    personal_workspace_menu_style = {
      'display': 'none'
    }
  }

  let team_workspace_menu_style = {'display': 'block'};
  if ( teamWorkspaces.length !== 0 ) {
    team_workspace_menu_style = {
      'display': 'none'
    }
  }

  return (
    <React.Fragment>
      <li onClick={() => setOpen(!open)}><div>Change Workspace<span className="mini-icon colour-teal icon-Arrow"></span></div>
        <ul className={[classes.change_workspace, 'workspace-menu'].join(' ')}  id={classes.workspace_menu} style={workspace_menu_style}>
          <li>
            <div className={classes.small_header}>My Personal Workspaces</div>
            <ul className={classes.pharmacy_list}>
              <li className={classes.pharmacy_no_listing} style={personal_workspace_menu_style}><div>You do not have any Personal Workspaces.</div></li>
              {personalWorkspaces}
            </ul>
          </li>
          <li>
            <div className={classes.small_header}>My Team Workspaces</div>
            <ul className={classes.pharmacy_list}>
              <li className={classes.pharmacy_no_listing} style={team_workspace_menu_style}><div>You do not have any Team Workspaces.</div></li>
              {teamWorkspaces}
            </ul>
          </li>
          <li className={classes.manage_workspaces}>
            <a href={process.env.REACT_APP_ACCOUNT_URL+'/workspaces/'} target="_blank" rel="noreferrer noopener">
              Manage Workspaces <span className="icon-opennew"></span>
            </a>
          </li>
        </ul>
      </li>
    </React.Fragment>
  )
}

export default ChangeWorkspace;