import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { Row, Col } from 'reactstrap';
import classes from '../../Dashboard/TemplatesList/TemplatesListItem/TemplatesListItem.module.scss';
import customTemplatesClasses from './CustomTemplatesList.module.scss';

const CustomTemplatesListItem = (props) => {
  //console.log(props)
  const toolTipRef = useRef();
  const [open, setOpen] = useState(false);
  var formatedDate = null;
  var formatedLastDate = null;

  const templateListItemClasses = [
    customTemplatesClasses.template_item
  ]

  const handlePaperFormatOpen = (e) => {
    const toolTip = e.target.parentNode.querySelector('.tool-tip')
    if ( toolTip ) {
      toolTip.classList.add('open')
      setOpen(true)
    }
  }

  const handlePaperFormatClose = (e) => {
    const toolTip = e.target.parentNode.querySelector('.tool-tip')
    if ( toolTip ) {
      toolTip.classList.remove('open')
      setOpen(false)
    }
  }

  useEffect(() => {
    document.querySelectorAll('.tooltip-hover').forEach((icon) => {
      icon.addEventListener( 'mouseover', handlePaperFormatOpen )
      icon.addEventListener( 'mouseout', handlePaperFormatClose )
    })

    return () => {
      document.querySelectorAll('.tooltip-hover').forEach((icon) => {
        icon.removeEventListener( 'mouseover', handlePaperFormatOpen )
        icon.removeEventListener( 'mouseout', handlePaperFormatClose )
      })
      //document.querySelector('.icon-Info').removeEventListener( 'mouseOver', handlePaperFormatClick )
    };
  }, [open]);

  if ( props.filters.length > 0 ) {
    props.filters.forEach((value, index) =>  {
      templateListItemClasses.push(value)
    })
  }

  const pad = function(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if ( props.dateCreated ) {
    var date = new Date(props.dateCreated);
    formatedDate = date.getFullYear() + '-' + months[date.getMonth()] + '-' + date.getDate() + ' ' + pad(date.getHours(), 2) + ':' + date.getMinutes();
  }

  if ( props.lastChanged ) {
    var lastDate = new Date(props.lastChanged);
    formatedLastDate = lastDate.getFullYear() + '-' + months[lastDate.getMonth()] + '-' + lastDate.getDate() + ' ' + pad(lastDate.getHours(), 2) + ':' + lastDate.getMinutes();
  }

  return (
    <Row className={[customTemplatesClasses.row_item].join(' ')} data-test="component-list-item">
      <Col className={customTemplatesClasses.check}><input type="checkbox" name="selectedTemapltes" /></Col>
      <Col className={customTemplatesClasses.name}>
        <NavLink to={'/create-note/' + props.recovery_code} exact data-test="component-template">{props.title}</NavLink>
      </Col>
      <Col className={customTemplatesClasses.owner}>{props.owner}</Col>
      <Col className={customTemplatesClasses.creator}>{props.creator}</Col>
      <Col className={customTemplatesClasses.date}>{formatedDate}</Col>
      <Col className={customTemplatesClasses.date}>{formatedLastDate}</Col>
      <Col className={customTemplatesClasses.link}>
        <NavLink className={[customTemplatesClasses.create_note_link].join(' ')} to={'/create-note/' + props.id} exact data-test="component-template">Create Note</NavLink>
      </Col>
      <Col className={customTemplatesClasses.edit_template}>
        <NavLink className={['btn', classes.create_note_btn].join(' ')} to={'/edit-template/' + props.id} exact data-test="component-edit-template">Edit Template</NavLink>
      </Col>
    </Row>
  )
};

export default CustomTemplatesListItem;