import React from 'react';

import { NavLink } from 'react-router-dom';

import classes from './Widgets.module.scss';

const createCustomTemplate = (props) => {

  return (
    <div className={props.widgetClass}>
      <div className={classes.widget_container}>
        <h2 className="main-header">Create a custom template</h2>
        <p>Can't find a form template for your note? Create your own template by adding in the fields you need for your patients.</p>
        <NavLink className={['btn', 'disabled', classes.widget_btn].join(' ')} to="/templates" exact>Create Custom Template</NavLink>
        <span className={['small-text', classes.widget_upgrade_message].join(' ')}>This feature is only available with a paid subscription. <a href={process.env.REACT_APP_ACCOUNT_URL + '/subscribe/upgrade/'} target="_blank" rel="noreferrer noopener">Upgrade your account.</a></span>
      </div>
    </div>
  )
}

export default createCustomTemplate;