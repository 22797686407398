import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { Row, Col } from 'reactstrap';
import classes from './TemplatesListItem.module.scss';

const TemplatesListItem = (props) => {
  const toolTipRef = useRef();
  const [open, setOpen] = useState(false);

  const templateListItemClasses = [
    classes.template_item,
    'template-list-item'
  ]

  const handlePaperFormatOpen = (e) => {
    const toolTip = e.target.parentNode.querySelector('.tool-tip')
    if ( toolTip ) {
      toolTip.classList.add('open')
      setOpen(true)
    }
  }

  const handlePaperFormatClose = (e) => {
    const toolTip = e.target.parentNode.querySelector('.tool-tip')
    if ( toolTip ) {
      toolTip.classList.remove('open')
      setOpen(false)
    }
  }

  useEffect(() => {
    document.querySelectorAll('.tooltip-hover').forEach((icon) => {
      icon.addEventListener( 'mouseover', handlePaperFormatOpen )
      icon.addEventListener( 'mouseout', handlePaperFormatClose )
    })

    return () => {
      document.querySelectorAll('.tooltip-hover').forEach((icon) => {
        icon.removeEventListener( 'mouseover', handlePaperFormatOpen )
        icon.removeEventListener( 'mouseout', handlePaperFormatClose )
      })
      //document.querySelector('.icon-Info').removeEventListener( 'mouseOver', handlePaperFormatClick )
    };
  }, [open]);

  let paperFormat = '';
  if ( props.paperFormat ) {
    paperFormat = <div className="paper-format tooltip-hover"><span className={['icon-Primary', classes.icon_font].join(' ')}></span>
    <div className={classes.text_container}><span className={classes.text}>Paper format available</span><span className="icon-Info"></span>
    <div className="tool-tip" ref={toolTipRef}>This template is available in a paper format fromt he QlinicalRx Store. <a href="https://www.qlinicalrx.com/store/" target="_blank" rel="noopener noreferrer">Buy the paper form here ></a></div></div></div>
  }

  if ( props.filters.length > 0 ) {
    props.filters.forEach((value, index) =>  {
      templateListItemClasses.push(value);
    })
  }

  let templateOwnerCol = ''
  let templateOwnerString = <span className={classes.owner} onClick={e => props.filterOwner(props.filters)}>{props.owner}</span>
  if ( props.createNote ) {
    templateOwnerCol = <Col className={classes.owner}>{props.owner}</Col>
    templateOwnerString = ''
  } else {

  }

  return (
    <Row className={templateListItemClasses.join(' ')} data-test="component-list-item">
      <Col className={classes.name}>
        <NavLink to={'/create-note/' + props.id} exact data-test="component-template">{props.title}</NavLink>{templateOwnerString}
      </Col>
      {templateOwnerCol}
      <Col className={classes.format}>
        {paperFormat}
      </Col>
      <Col className={classes.links_container}>
        <NavLink className={['btn', classes.create_note_btn].join(' ')} to={'/create-note/' + props.id} exact data-test="component-template">Create Note</NavLink>
      </Col>
    </Row>
  )
};

export default TemplatesListItem;