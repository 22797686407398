import React, { Component } from 'react';
import Media from 'react-media';

import { connect } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
import Sidebar from './layout/Sidebar/Sidebar';
import MainContent from './layout/MainContent/MainContent';
import Mobile from './layout/Mobile/Mobile';
import * as actions from './store/actions/index';

import './App.scss';
import ErrorBoundary from './ErrorBoundary';

class App extends Component {
  state = {
    mainContentWidth: '1000px',
    showSidebar: true,
    sidebarCollpasedToggle: false,
    ticking: false
  }

  componentDidMount() {
    this.updateDimensions();

    const that = this;
    window.addEventListener('resize', function() {
      if ( !that.state.ticking ) {
        window.requestAnimationFrame(function() {
          that.updateDimensions();
          that.setState({ ticking: false });
        });
        
        that.setState({ ticking: true });
      } 
    })
    
    if ( window.innerWidth < 1024 ) {
      this.setState({
        showSidebar: false
      })
    }
  }

  shouldComponentUpdate( nextProps, nextState ) {
    if ( this.state.showSidebar !== nextState.showSidebar )
      return true;

    if ( this.state.mainContentWidth !== nextState.mainContentWidth )
      return false;

    return true;
  }

  updateDimensions = () => {
    
    if ( window.innerWidth > 1200 && false === this.state.showSidebar && false === this.state.sidebarCollpasedToggle ) {
      this.setState({ showSidebar: true })
    } else if ( window.innerWidth < 1200 && true === this.state.showSidebar && false === this.state.sidebarCollpasedToggle ) {
      this.setState({ showSidebar: false })
    }

    let sidebarWidth = 250
    if ( !this.state.sidebarCollpasedToggle ) {
      sidebarWidth = 80
    }

    this.setState({ mainContentWidth: window.innerWidth-sidebarWidth })
    //console.log(sidebarWidth)
  }

  collapseSidebarHandler = () => {
    this.setState({ 
      showSidebar: this.state.showSidebar ? false : true,
      sidebarCollpasedToggle: this.state.sidebarCollpasedToggle ? false : true,
    })
  }

  render() {
    let style_str = {
      width: this.state.mainContentWidth + 'px',
      float: 'left',
      transition: '0.5s'
    }

    let sidebar_classes = [],
        app_classes = ['App']
    if ( this.state.showSidebar ) {
      sidebar_classes.push('sidebar-open');
    }

    let content = <ErrorBoundary><MainContent /></ErrorBoundary>
    if ( this.props.isAuthenticated ) {
      content = <React.Fragment>
                  <Col className="no_gutter sidebar-col">
                    <Sidebar show={true} collapse={this.collapseSidebarHandler} />
                  </Col>
                  <div className="main_content" style={style_str}>
                    <ErrorBoundary>
                      <MainContent />
                    </ErrorBoundary>
                  </div>
                </React.Fragment>
    } else {
      app_classes.push('login-page');
    }
    /*if ( this.props.isAuthenticated && this.props.workspace !== false ) {
      console.log(1)
      content = <React.Fragment><Col className="no_gutter sidebar-col"><Sidebar show={true} collapse={this.collapseSidebarHandler} /></Col><div className="main_content" style={style_str}><MainContent /></div></React.Fragment>
    } else {
      console.log(2)
      app_classes.push('login-page')
      content = <MainContent />
    }*/

    return (
      <BrowserRouter>
        <Media query="(max-width: 800px)">
          <div className={app_classes.join(' ')}>
            <Container fluid={true}>
              <Mobile />
            </Container> 
          </div>
        </Media>
        <Media query="(min-width: 800px)">
          <div className={app_classes.join(' ')}>
            <Container fluid={true}>
              <Row className={sidebar_classes.join(' ')}>
                {content}
              </Row>
            </Container> 
          </div>
        </Media>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshAccessToken: () => dispatch( actions.refreshAccessToken() ),
    setPharmacy: () => dispatch( actions.getSavedPharmacy() )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
