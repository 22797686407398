import React, { useEffect, useState, useRef } from 'react'

import { NavLink } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

const pdfOutputModal = (props) => {
  const node = useRef(null)
  const[open, setPdfOpen] = useState(false)
  const[copied, setCopied] = useState(false)

  const closeModal = () => {
    setPdfOpen(false)
    document.querySelector('#export-note-modal').style.display = ''
  }

  const exportModal = () => {
    setPdfOpen(true)
    props.exportHandler('pdf')
  }

  const copyRecoveryCode = () => {
    node.current.select()
    document.execCommand('copy')
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  useEffect(() => {
    if (open) {
      document.querySelector('#export-note-modal').style.display = 'none'
    }
  }, [open]);

  const handleClickOutside = e => {
    const addPharmacyModal = document.querySelector('#download-pdf-modal')

    if (addPharmacyModal) {
      if (addPharmacyModal.contains(e.target)) {
        return
      }
    }

    //setPdfOpen(false); // outside click
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [open]);

  let toolTipStyle = {}
  if (copied) {
    toolTipStyle = {
      display: 'block'
    }
  }

  return (
    <React.Fragment>
      <div className="btn export-btn" onClick={e => exportModal()}>
        <span className="icon-pdf"></span>
        Download as PDF
      </div>
      <Modal id="download-pdf-modal" isOpen={open}>
        <Button className="close" onClick={closeModal}><span className="icon-X_CloseForm"></span></Button>
        <ModalHeader>Your note has exported!</ModalHeader>
        <ModalBody>
          <p>Use the code below to recover your note at any point.</p>

          <Row>
            <Col xs="12"><label className="small-text-format small-header text-uppercase colour-teal" htmlFor="recovery-code">Note Recovery Code</label><br /></Col>
            <Col xs="7">
              <input ref={node} className="recovery-code-input" name="recovery-code" type="text" defaultValue={props.recoveryCode} readOnly />
              <div className="tool-tip" style={toolTipStyle}>Copied!</div>
            </Col>
            <Col xs="4">
              <Button onClick={copyRecoveryCode} color="primary">Copy Code</Button>
            </Col>
          </Row>

          <Row>
            <Col xs="6"><Button onClick={closeModal} color="primary">Export Another Output</Button></Col>
            <Col xs="6"><NavLink to="/" exact className="btn">Go To Dashboard</NavLink></Col>
          </Row>
          <Row>
            <Col>
              <p>Didn't see your note? Ensure your browser allows pop-ups from Qlinical Rx.</p>
              <p>If you need more help, <a href="https://support.qlinicalrx.com/" target="_blank" rel="noopener noreferrer">contact us for help.</a></p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default pdfOutputModal