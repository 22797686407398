import React, { Component } from 'react';

import blockClasses from '../../Block/Block.module.scss'
import inputClasses from '../Inputs.module.scss';
import DatePicker from "react-datepicker";

class InputCheckboxDate extends Component {
  state = {
    isChecked: this.props.fields[0].checked,
    startDate: null
  }

  setDate = (date, blockId, apiId) => {
    console.log('set date');
    this.setState({
      isChecked: true,
      startDate: date
    });

    this.inputElement.checked = true;
    
    this.props.checkboxChanged(this.inputElement);

    if ( date != null ) {
      this.props.dateChanged(this.getFormattedDate(date), blockId, apiId);
    } else {
      this.props.dateChanged(null);
    }

    if ( this.props.events.length > 0 ) {
      console.log('Date Events');
      console.log(this.props.events);
      this.props.events.forEach( (event) => {
        if ( date ) {
          this.props.eventTargetHandler(event.targets, 'value_show');
        } else {
          this.props.eventTargetHandler(event.targets, 'value_hide');
        }
      });
    }
  }

  getFormattedDate = (date) => {
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
  }

  checkboxChanged = (e) => {
    console.log('checkbox event handler')
    const targetChecked = e.target.checked;
    this.props.checkboxChanged(e.target, this.props.fields[1].api_id);

    if ( true === targetChecked ) {
      this.setState({ isChecked: true })
    } else {
      this.setState({ isChecked: false })
    }

    /*if ( this.state.events.length > 0 ) {
      for (let i=0; i< this.state.events.length; i++) {
        this.props.eventsChanged(this.state.events[i], 'checkbox', e.target.checked)
        //console.log(this.state.events)
      }
    }*/
  }

  render() {
    let firstFieldKey = 0,
        secondFieldKey = 1;

    //const checkboxInputId = this.props.blockId + '_' + this.props.fields[firstFieldKey].api_id;
    //const dateInputId = this.props.blockId + '_' + this.props.fields[secondFieldKey].api_id;

    const checkboxInputId = this.props.fields[firstFieldKey].id;
    const dateInputId = this.props.fields[secondFieldKey].id;

    let fieldClasses = [
      inputClasses.group_item,
      'group-item'
    ];

    let checkboxLabelBefore,
        checkboxLabelAfter,
        checkboxLabel = <label htmlFor={checkboxInputId}>{this.props.fields[firstFieldKey].label}</label>

    if ( !this.props.fields[firstFieldKey].label ) {
      checkboxLabel = <label className="empty-label"></label>
    } else {
      fieldClasses.push('has-label');
    }

    if ( this.props.fields[firstFieldKey].labelBefore )
      checkboxLabelBefore = this.props.fields[firstFieldKey].labelBefore;
    
    if ( this.props.fields[firstFieldKey].labelAfter )
      checkboxLabelAfter = this.props.fields[firstFieldKey].labelAfter;

    let checkbox_classes = [
      'checkbox-input',
      blockClasses.checkbox_field
    ];

    let dateLabelBefore,
      dateLabelAfter,
      dateLabel = <label htmlFor={dateInputId}>{this.props.fields[secondFieldKey].label}</label>

    if ( !this.props.fields[secondFieldKey].label )
      dateLabel = <label className="empty-label"></label>

    if ( this.props.fields[secondFieldKey].labelBefore )
      dateLabelBefore = this.props.fields[secondFieldKey].labelBefore;
    
    if ( this.props.fields[secondFieldKey].labelAfter )
      dateLabelAfter = this.props.fields[secondFieldKey].labelAfter;

    return (
      <div className={fieldClasses.join(' ')}>
        <div className={checkbox_classes.join(' ')} data-test="input-checkbox">
          {checkboxLabelBefore}
          <input 
            type="checkbox" 
            id={checkboxInputId}
            name={this.props.fields[firstFieldKey].name} 
            onChange={this.checkboxChanged} 
            data-test="checkbox-option" 
            disabled={this.props.disabled}
            value={this.props.fields[firstFieldKey].api_id}
            data-block-id={this.props.blockId} 
            data-api-id={this.props.fields[firstFieldKey].api_id}
            checked={this.state.isChecked}
            ref={input => { this.inputElement = input }}
            />
          {checkboxLabel}
          {checkboxLabelAfter}
        </div>

        <div className="date-input">
          {dateLabelBefore}
          {dateLabel}
          <DatePicker
            id={dateInputId}
            selected={this.state.startDate}
            onChange={date => this.setDate(date, this.props.blockId, this.props.fields[secondFieldKey].api_id)}
            dateFormat="yyyy-MM-dd"
            className="input-date"
            disabled={this.props.disabled}
            />
          {dateLabelAfter}
        </div>
      </div>
    )
  }
}

export default InputCheckboxDate;