import React, { Component } from 'react';

import blockClasses from '../Block/Block.module.scss'
//import block from '../Block/Block';

class InputRadio extends Component {
  state = {
    isChecked: this.props.checked,
    events: this.props.events,
  }

  eventHandler = () => {
    this.state.events.forEach( (event) => {
      const type = event.type;
      event.targets.forEach((target) => {
        if ( target.block_id ) {
          this.props.eventsChanged(type, 'block-' + target.block_id);
        } else if ( target.input_id ) {
          this.props.eventsChanged(type, target.input_id);
        }
      });
    });
  }

  radioChanged = (e) => {
    this.setState({ isChecked: true });
    this.props.changed(e.target, 'single');

    if ( this.state.events.length > 0 ) {
      this.eventHandler();
    }
  }

  render() {
    let radioClasses = [
      'radio-input',
      'input-field',
      blockClasses.radio_field
    ];

    //const inputId = this.props.blockId + '_' + this.props.apiId;
    const inputId = this.props.id;

    return (
      <div className={radioClasses.join(' ')} data-test="input-radio">
        <div className="radio_option" data-test="radio-option">
          <input 
            type="radio"
            id={inputId} 
            name={this.props.name}
            defaultChecked={this.state.isChecked}
            disabled={this.props.disabled}
            onChange={this.radioChanged} 
            value={this.props.value} 
            data-block-id={this.props.blockId} 
            data-api-id={this.props.apiId} 
             />
          <label htmlFor={inputId}>{this.props.label}</label>
        </div>
      </div>
    )
  }
}

export default InputRadio;