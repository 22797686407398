import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import { Row, Col } from 'reactstrap';

import Filter from './Filters/Filter';

import classes from './Search.module.scss';
import dashboardClasses from '../Dashboard.module.scss';

class Search extends Component {
  state = {
    filtered: false,
    filterTemplates: [],
    filterTerms: [],
    resultsCount: 0,
    searchTerm: null,
    sort: null,
    templateFilterOptions: [],
  }

  constructor() {
    super();
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    this.props.getFilters();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if ( prevProps.searchFilter !== this.props.searchFilter ) {
      this.setState({
        searchTerm: this.props.searchFilter
      })
    }
  }

  templateFilterHandler = (event) => {
    const target = event.target;
    const filterType = target.getAttribute('data-filter')
    const filterName = target.getAttribute('data-filter-name')
    
    if (true === target.checked) {
      if ( filterType ) {
        this.state.filterTemplates.push(filterType)

        this.doFilterTemplates()
        this.setState({ filtered: true })

        let item = [{
          id: filterType,
          name: filterName
        }]

        this.setState((prevState) => {
          let terms = prevState.filterTerms.concat(item)
          return { filterTerms: terms }
        });
        
      }
    } else {
      const indexOf = this.state.filterTemplates.indexOf(filterType)
      if (indexOf >= 0) {
        this.state.filterTemplates.splice(indexOf, indexOf+1)

        this.doFilterTemplates()
        this.removeFilterTerm(filterType)
      }
    }

    this.updateTemplateCount()
  }

  doFilterTemplates = () => {
    let allTemplates = document.querySelectorAll('.template-list-item')

    if ( this.state.filterTemplates.length > 0 ) {
      allTemplates.forEach((node) => {
        node.style.display = 'none'
      })

      this.state.filterTemplates.forEach((filter) => {
        let templates = document.querySelectorAll('.' + filter)

        templates.forEach((node) => {
          node.style.display = 'flex'
        })
      })
    } else {
      allTemplates.forEach((node) => {
        node.style.display = 'flex'
      })
    }
  }

  sortHandler = (event) => {
    const sortType = event.target.getAttribute('data-filter')
    const restofLi = event.target.parentNode.querySelectorAll('li')

    for ( let i=0; i<restofLi.length; i++ ) {
      restofLi[i].classList.remove(classes.selected)
    }
    event.target.classList.add(classes.selected)

    if ( sortType ) {
      this.setState({ sort: sortType })
      this.props.sort(sortType);
    }
  }

  searchHander= (e) => {
    if ( e.target.value.length >= 2 ) {
      let searchTerm = e.target.value;
      this.setState({ 
        filtered: true,
        searchTerm: searchTerm
      });

      this.props.search(searchTerm);
      this.updateTemplateCount();

    } else if ( e.target.value.length >= 0 ) {
      this.removeSearchFilter();
    }
  }

  removeSearchFilter = () => {
    this.setState({ 
      searchTerm: null
    });

    this.props.search(null);
    this.updateTemplateCount();

    if ( 0 === this.state.filterTerms.length )
        this.setState({ filtered: false });
  }

  removeTemplateFilter = (e) => {
    const filterType = e.target.parentNode.getAttribute('data-filter');

    // Remove filter
    const indexOf = this.state.filterTemplates.indexOf(filterType);
    if (indexOf >= 0) {
      this.state.filterTemplates.splice(indexOf, indexOf+1);
      this.doFilterTemplates();

      // clear checkboxes
      let checkboxes = document.querySelectorAll('.template_filter input');
      checkboxes.forEach( (input) => {
        if ( input.getAttribute('data-filter') === filterType ) {
          input.checked = false
        }
      })

      this.removeFilterTerm(filterType);
      this.updateTemplateCount();
    }
  }

  /**
   * Remove a filter term from the state
   */
  removeFilterTerm = (filterType) => {
    const check = this.state.filterTerms.findIndex( function(term) {
      return term.id === filterType;
    })

    if ( check > -1 ) {
      this.setState((prevState) => {
        const filteredItems = prevState.filterTerms.slice(0, check).concat(prevState.filterTerms.slice(check + 1, prevState.filterTerms.length));
        return { filterTerms: filteredItems }
      })
    }

    if ( 1 === this.state.filterTerms.length && null === this.state.searchTerm )
      this.setState({ filtered: false })
  }

  updateTemplateCount = () => {
    let currentResultsCount = 0;
    const templates = document.querySelectorAll('.template-list-item')
    for (var i = 0, max = templates.length; i < max; i++) {
      var style = window.getComputedStyle(templates[i]);
      if (style.display === 'none' || style.visibility === 'hidden') {
        // do nothing
      } else { 
        currentResultsCount++
      }
    }

    this.setState({ resultsCount: currentResultsCount })
  }

  clearHandler = () => {
      this.setState({
        filtered: false,
        filterTemplates: [],
        filterTerms: [],
        searchTerm: null,
      });

    this.props.search(null);

    // remove template filters
    let filterTerms = this.state.filterTerms
    for ( let key in filterTerms ) {
      const indexOf = this.state.filterTemplates.indexOf(filterTerms[key].id)
      if (indexOf >= 0) {
        this.state.filterTemplates.splice(indexOf, indexOf+1)
        this.doFilterTemplates()

        let checkboxes = document.querySelectorAll('.template_filter input');
        checkboxes.forEach( (input, index) => {
          input.checked = false
        })
      }
    }
  }

  render() {
    let filtersOptions = [];
    if ( this.props.searchFilters ) {
      filtersOptions = this.props.searchFilters;
    }

    let showFiltered = { 'display': 'none' }
    let showSearchFilter = { 'display': 'none' }

    if ( true === this.state.filtered )
      showFiltered = { 'display': 'block' }

    if ( this.state.searchTerm )
      showSearchFilter = { 'display': 'inline-block' }

    let filterTerms = ''
    if ( this.state.filterTerms.length > 0 ) {
      filterTerms = this.state.filterTerms.map((obj, index) => {
        let filterKey = 'filter-'+index;
        return <div key={filterKey} data-filter={obj.id} className={classes.filter_term}>
                {obj.name}
                <span className="icon-X_CloseForm" onClick={this.removeTemplateFilter}></span>
               </div>
      })
    }

    let col = '6';
    let search_block_classes = [
      dashboardClasses.dash_row,
      classes.search_full_width
    ];
    if ( 'full' === this.props.size ) {
      col = '12';
    }

    return (
      <React.Fragment>
        <Row className={search_block_classes.join(' ')}>
          <Col lg={col} data-test="component-search" className={classes.search_input_container}>
            <span className="icon-Search"></span>
            <input 
              className={classes.search_box}
              name="template-search" 
              type="text" 
              onChange={this.searchHander} 
              placeholder="Find a template (ex. diabetes, contraception, asthma)"
              defaultValue={this.state.searchTerm} />
          </Col>
          <Col lg={col} className={classes.filters}>
            <Filter
              name="Sort" 
              options={[
                { val: "a", name: "Title A > Z"},
                { val: "d", name: "Title Z < A"},
                { val: "r", name: "Most Recent"}
              ]}
              icon="icon-Sort"
              click={this.sortHandler}
              type="list"
            />
          
            <Filter
              name="All templates" 
              options={filtersOptions}
              icon="icon-Filter"
              click={this.templateFilterHandler}
              type="checkbox"
              customClass="template_filter"
            />
          </Col>
        </Row>
        <Row className={[dashboardClasses.dash_row, dashboardClasses.filter_row, classes.filter_bar].join(' ')} style={showFiltered}>
          <Col xs="12">
            <div className={classes.filter_term} style={showSearchFilter}>
              {this.state.searchTerm}
              <span className="icon-X_CloseForm" onClick={this.removeSearchFilter}></span>
            </div>
            {filterTerms}
            <div className={classes.clear_all_filter} onClick={this.clearHandler}>Clear All</div>
            <div className={classes.total_results}>{this.state.resultsCount} results</div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentPharmacy: state.currentPharmacy,
    searchFilters: state.searchFilters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFilters: () => dispatch( actions.getFilters() )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Search);